import React from 'react';
import { ReactComponent as LogoSvg } from '../../../images/aligned-logo-path.svg';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';

const styles = theme => Styles(theme);

class Logo extends React.Component {
  render() {
    const { classes, className, width, height, theme } = this.props;

    return (
      <a className={classNames(classes.logoAnchor, className)} href="/">
        <LogoSvg
          viewBox={`0 0 ${width} ${height}`}
          fill={theme.palette.primary.dark}
        />
      </a>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Logo);
