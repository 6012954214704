import React from 'react';
import PropTypes from 'prop-types';
import { DialogConstants } from './constants';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class DialogOkCancel extends React.Component {
  handleClose = button => {
    const { handleOK, handleCancel } = this.props;

    switch (button) {
      case DialogConstants.OK_BUTTON:
        handleOK();
        break;
      case DialogConstants.CANCEL_BUTTON:
        handleCancel();
        break;
      default:
        break;
    }
  };

  render() {
    const { fullScreen, text, title, open } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose(DialogConstants.OK_BUTTON);
              }}
              color="primary"
            >
              OK
            </Button>
            <Button
              onClick={() => this.handleClose(DialogConstants.CANCEL_BUTTON)}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogOkCancel.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default DialogOkCancel;
