import { store } from '../../../stores/store';
import {
  getFieldInfoFromName,
  getQuestionIdFromAlias,
  getSectionResponses
} from './fields';
import { getRelativeSections } from '../../../helpers/sections';

const build = sectionId => {
  let relatives = [];
  const responses = store.getState().newQuestionnaire.responses;
  const sections = store.getState().newQuestionnaire.questionnaire.sections;
  const relativeSections = getRelativeSections(sections);

  //  Drop out if no relatives to build in this section
  if (!relativeSections.map(section => section.id).includes(sectionId)) {
    return relatives;
  }

  // First we need to get all responses for this section
  const sectionResponses = getSectionResponses(sectionId);

  // Loop through and grab each person (questionId 47 is our start)
  if (sectionResponses == null || sectionResponses.size === 0) {
    return relatives;
  }

  const sectionResponseKeys = sectionResponses.keySeq();

  sectionResponseKeys.forEach(fieldName => {
    const fieldInfo = getFieldInfoFromName(fieldName);
    const response = responses.get(fieldName, null);

    if (response != null && response.value !== '') {
      const relative = {
        name: responses.get(fieldName).value,
        depth: fieldInfo.sectionDepth,
        id: fieldName
      };

      const relativeNameQuestionId = getQuestionIdFromAlias('RelativeName');

      if (fieldInfo.questionId === relativeNameQuestionId) {
        relatives.push(relative);
      }
    }
  });

  return relatives;
};

export const relativeBuilder = {
  build
};
