export const ErrorConstants = {
  UnauthorisedToken: 'UNAUTHORISED_TOKEN',
  ValidationFailed: 'VALIDATION_FAILED',
  UserNotConfirmed: 'USER_NOT_CONFIRMED',
  DbConnectionFailed: 'DB_CONNECT_FAIL',
  InternalServerError: 'INTERNAL_SERVER_ERROR'
};

export const AdminActionConstants = {
  None: 0,
  Pending: 1,
  Complete: 2,
  Error: 4
};

export const DisplayTypeConstants = {
  NoAnswer: 0,
  FreeText: 1,
  FreeTextMultiline: 2,
  NumericText: 3,
  RegexText: 4,
  EmailText: 5,
  DropDownSelect: 6,
  DropDownTypeAhead: 7,
  Option: 8,
  CheckBox: 9,
  DateYearText: 10
};

export const SourceScopeConstants = {
  Global: 0,
  Questionnaire: 1,
  Section: 2,
  Page: 3,
  Question: 4,
  Diagnosis: 5
};

export const SectionStatusConstants = {
  NotStarted: 0,
  Started: 1,
  Complete: 2
};

export const QuestionnaireStatusConstants = {
  NotStarted: 0,
  Started: 1,
  Complete: 2,
  Submitted: 3,
  Processed: 4
};

export const ScreeningResult = {
  NoFurtherAction: 0,
  Inconclusive: 1,
  FullHistoryRequested: 2
};

export const SpecificAnswerTypes = {
  PreviouslyEnteredRelatives: 16,
  CancerType: 13,
  SpecificCancerType: 14,
  LoopCount: 24,
  DropDownGeneTest: 26
};

export const RelativeSectionNames = [
  'AboutYou',
  'YourChildren',
  'YourSiblings',
  'YourFathersSide',
  'YourMothersSide',
  'AnyOtherRelatives'
];

export const NavigationConstants = {
  CURRENT_QUESTION: 0,
  NEXT_QUESTION: -1,
  NEXT_SECTION: -50,
  NEXT_LOOP: -100,
  END_QUESTIONNAIRE: -1000
};

export const isNavConstant = (input: number | string): boolean => {
  return (
    input === NavigationConstants.CURRENT_QUESTION ||
    input === NavigationConstants.NEXT_QUESTION ||
    input === NavigationConstants.NEXT_SECTION ||
    input === NavigationConstants.NEXT_LOOP ||
    input === NavigationConstants.END_QUESTIONNAIRE
  );
};
