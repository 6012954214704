import {
  isEmail,
  isRequired,
  isUKDate,
  minLength,
  mustMatch,
  nhsChecksum,
  isSafeForRender
} from '../../../validation/rules';
import { ruleRunner } from '../../../validation/ruleRunner';

export const fieldValidations = [
  ruleRunner('firstName', 'First Name', true, isRequired, isSafeForRender),
  ruleRunner('lastName', 'Last Name', true, isRequired, isSafeForRender),
  ruleRunner('email', 'Email', true, isRequired, isEmail, isSafeForRender),
  ruleRunner(
    'confirmEmail',
    'Email Confirmation',
    true,
    isRequired,
    isEmail,
    isSafeForRender,
    mustMatch('email', 'Email')
  ),
  ruleRunner('phone', 'Phone Number', true, isRequired, isSafeForRender),
  ruleRunner(
    'dateOfBirth',
    'Date Of Birth',
    true,
    isRequired,
    isUKDate,
    isSafeForRender
  ),
  ruleRunner(
    'confirmDateOfBirth',
    'Date Of Birth Confirmation',
    true,
    isRequired,
    isUKDate,
    isSafeForRender,
    mustMatch('dateOfBirth', 'Date Of Birth')
  ),
  ruleRunner(
    'nhsNo',
    'NHS, CHI or H&C number',
    true,
    isRequired,
    minLength(10),
    nhsChecksum,
    isSafeForRender
  ),
  ruleRunner(
    'serviceCode',
    'ServiceCode',
    true,
    isRequired,
    minLength(6),
    isSafeForRender
  ),
  ruleRunner(
    'referringTrust',
    'Referring Trust',
    true,
    isRequired,
    isSafeForRender
  ),
  ruleRunner(
    'addressLine1',
    'Address Line 1',
    true,
    isRequired,
    isSafeForRender
  ),
  ruleRunner('town', 'Town', true, isRequired, isSafeForRender),
  ruleRunner('country', 'Country', true, isRequired, isSafeForRender),
  ruleRunner('postcode', 'Postcode', true, isRequired, isSafeForRender),
  ruleRunner('password', 'Password', true, isRequired, minLength(8)),
  ruleRunner(
    'confirmPassword',
    'Password Confirmation',
    true,
    mustMatch('password', 'Password')
  )
];
