const styles = theme => ({
  sectionInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 50
  },
  sectionInfo: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 0
  },
  sectionInfoDetail: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 38,
    justifyContent: 'center'
  },
  panelRoot: {
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  panelExpanded: {
    margin: '0 !important'
  },
  expansionPanelSummary: {
    boxShadow: 'none',
    padding: 0,
    minHeight: 28
  },
  summaryRoot: {
    padding: 0,
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  listItemsGutters: {
    paddingLeft: 20,
    paddingRight: 20
  },
  summaryRootHeight: {
    '&$summaryRoot': {
      minHeight: 50
    }
  },
  summaryContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    width: '100%',
    '&>:last-child': {
      paddingRight: 0
    }
  },
  summaryContentMargin: {
    '&$summaryContent': {
      margin: 0,
      padding: '0 0 0 32px'
    }
  },
  relativeSummary: {
    lineHeight: 1.4,
    paddingLeft: 23,
    paddingRight: 0,
    zIndex: 1
  },
  expansionPanelDetailRoot: {
    padding: '8px 0 8px 0',
    borderBottom: '1px solid rgba(0,0,0,.125)'
  },
  expansionPanelDetailEmptyRoot: {
    padding: 0
  },
  listItem: {
    padding: 0
  },
  boldSubtitle: {
    width: 161,
    padding: '0 10px 0 22px',
    flex: 'initial',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0 24px'
    },
    '& p': {
      color: 'rgba(0, 0, 0, 0.8)'
    }
  },
  readOnlySubtitle: {
    padding: '0 10px 0 22px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0 24px'
    },
    '& p': {
      color: 'rgba(0, 0, 0, 0.2)'
    }
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  badge: {
    // The border color match the background color.
    border: `2px solid ${theme.palette.grey[200]}`,
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    padding: '0 4px',
    height: 22,
    minWidth: 22
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 21.6
  },
  checkCircle: {
    color: theme.palette.green.main,
    fontSize: 18
  },
  badgeIcon: {
    fontSize: 1
  },
  badgeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  expandIcon: {
    top: 12,
    right: 37
  },
  relativeLevelOne: {
    paddingLeft: 56,
    color: 'rgba(0, 0, 0, 0.54)',
    textShadow: 'none',
    webkitTransition: 'all .5s',
    mozTransition: 'all .5s',
    oTransition: 'all .5s',
    transition: 'all .5s',

    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
      textShadow:
        '-0.2px -0.2px 0 #bdbdbd, ' +
        '0.2px -0.2px 0 #bdbdbd, ' +
        '-0.2px 0.2px 0 #bdbdbd, ' +
        '0.2px 0.2px 0 #bdbdbd ',

      webkitTransition: 'all .25s',
      mozTransition: 'all .25s',
      oTransition: 'all .25s',
      transition: 'all .25s'
    }
  },
  relativeLevelTwo: {
    paddingLeft: 76
  }
});

export default styles;
