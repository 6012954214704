import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import { config } from '../../../helpers/config';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import { compose } from 'redux';
import Markdown from '../../Markdown/Markdown';
import { connect } from 'react-redux';

const styles = theme => Styles(theme);

class Eula extends React.Component {
  renderMarkdownEULA(markdown) {
    return <Markdown>{markdown}</Markdown>;
  }

  renderSGHSWTEULA() {
    const { classes } = this.props;
    const contactEmail = this.props.service?.serviceConfiguration?.contactEmail;

    return (
      <div className={classes.container}>
        <Typography variant="subtitle1" paragraph>
          This licence agreement (Licence) is a legal agreement between you and
          St George’s University Hospitals NHS Foundation Trust of Blackshaw
          Road, Tooting, London, SW17 0QT which covers your use of the Family
          History Questionnaire Service (
          <span className={classes.boldSubtitle}>FHQS</span>) software (
          <span className={classes.boldSubtitle}>Software</span>).
        </Typography>
        <Typography variant="subtitle1" paragraph>
          We license use of the Software to you on the basis of this Licence and
          it is important that you read and understand these terms before using
          the Software. We do not sell the Software to you, and we remain the
          owners of the Software at all times.
        </Typography>
        <Typography className={classes.listHeader}>
          1.&nbsp;&nbsp;&nbsp;&nbsp;IMPORTANT INFORMATION
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>1.1</span>
            </ListItemIcon>
            <ListItemText primary="The Software is solely for the use of individuals who have been referred to the cancer genetic service through a General Practitioner (GP) referral or another healthcare professional." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>1.2</span>
            </ListItemIcon>
            <ListItemText primary="You should only register to use the Software if you have received a letter from us inviting you to use the Software and containing a link to the FHQS. You must use the Software and complete the FHQS within four weeks of the date of your letter or you will be discharged from the treatment pathway." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>1.3</span>
            </ListItemIcon>
            <ListItemText primary="We will only undertake an assessment of data for individuals who have been referred to the cancer genetic service as a patient. Any data submitted from a non-referral will not be reviewed or processed." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>1.4</span>
            </ListItemIcon>
            <ListItemText primary="All documentation we produce including a family tree will be based on the information you provide, which needs to be as accurate as possible. If you are not certain about dates, you can provide estimated dates." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>1.5</span>
            </ListItemIcon>
            <ListItemText primary="In consideration of you agreeing to comply with the terms of this Licence, we grant you a non-exclusive, non-transferable licence to use the Software for your personal purposes only and in order to complete the FHQS questionnaire." />
          </ListItem>
        </List>
        <Typography className={classes.listHeader}>
          2.&nbsp;&nbsp;&nbsp;&nbsp;THE SOFTWARE
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>2.1</span>
            </ListItemIcon>
            <ListItemText primary="The Software requires a modern internet browser and may not be supported by older browsers. Please ensure that you have the most up-to-date version of your internet browser installed. The Software may be upgraded and updated from time to time." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>2.2</span>
            </ListItemIcon>
            <ListItemText primary="The Software is purely a data collection tool which is designed to gather personal information about you and your family medical history via a questionnaire and limited free text boxes to enter descriptions. The information provided will be used by the Trust to prepare a risk assessment in advance of your consultation with a genetic clinician." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>2.3</span>
            </ListItemIcon>
            <ListItemText primary="Once submitted you will be unable to access or change the data you provided within the Software. If you need to amend or add further information, please email us at " />
          </ListItem>
          <Typography className={classes.listItemLink}>
            <a
              className={classes.noWrap}
              href={`mailto:${contactEmail ? contactEmail : config.email}`}
            >
              {contactEmail ? contactEmail : config.email}
            </a>
          </Typography>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>2.4</span>
            </ListItemIcon>
            <ListItemText primary="All data you submit via the Software will be pulled into your genetics electronic patient record held within “Geneworks”. Geneworks is owned and operated by St George’s University Hospitals NHS Foundation Trust." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>2.5</span>
            </ListItemIcon>
            <ListItemText primary="The Software and the information you input into it will be used to produce a two page data summary which is pulled into Geneworks. Geneworks generates an editable pedigree chart, which can be edited if new information is provided or new diagnoses are made." />
          </ListItem>
        </List>
        <Typography className={classes.listHeader}>
          3.&nbsp;&nbsp;&nbsp;&nbsp;RESTRICTIONS
        </Typography>
        <List className={classes.boldSubtitle}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemText primary="Except as expressly set out in this Licence or as permitted by any local law, you undertake:" />
          </ListItem>
        </List>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>(a)</span>
            </ListItemIcon>
            <ListItemText primary="not to use offensive language in any free text box within the Software;" />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>(b)</span>
            </ListItemIcon>
            <ListItemText primary="not to copy, sub-license, loan, translate, merge, adapt, vary, alter or modify the whole or any part of the Software nor permit the Software or any part of it to be combined with, or become incorporated in, any other programs; and" />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>(c)</span>
            </ListItemIcon>
            <ListItemText primary="not to disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the Software nor attempt to do any such things; and" />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>(d)</span>
            </ListItemIcon>
            <ListItemText primary="not to provide, or otherwise make available, the Software in any form, in whole or in part (including, but not limited to, object code and source code) to any person." />
          </ListItem>
        </List>
        <Typography className={classes.listHeader}>
          4.&nbsp;&nbsp;&nbsp;&nbsp;INTELLECTUAL PROPERTY RIGHTS
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>4.1</span>
            </ListItemIcon>
            <ListItemText primary="You acknowledge that all intellectual property rights in the Software throughout the world belong to us, that rights in the Software are licensed (not sold) to you, and that you have no intellectual property rights in, or to, the Software other than the right to use it as set out in the terms of this Licence." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>4.2</span>
            </ListItemIcon>
            <ListItemText primary="You acknowledge that you have no right to have access to the Software in source code form." />
          </ListItem>
        </List>
        <Typography className={classes.listHeader}>
          5.&nbsp;&nbsp;&nbsp;&nbsp;OUR RESPONSIBILITY FOR LOSS OR DAMAGE
          SUFFERED BY YOU
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>5.1</span>
            </ListItemIcon>
            <ListItemText primary="The Software will not provide bespoke medical advice. It is a data collection tool and is used for the purpose of patient care pathways." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>5.2</span>
            </ListItemIcon>
            <ListItemText primary="All disputes will be dealt with by our internal complaint-handling system. Information can be located on the link below." />
          </ListItem>
          <Typography className={classes.listItemLink}>
            <a
              href="https://www.stgeorges.nhs.uk/contact-and-find-us/compliments-and-complaints/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.stgeorges.nhs.uk/contact-and-find-us/compliments-and-complaints/
            </a>
          </Typography>
        </List>
        <Typography className={classes.listHeader}>
          6.&nbsp;&nbsp;&nbsp;&nbsp;TERMINATION
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>6.1</span>
            </ListItemIcon>
            <ListItemText primary="If you have not completed the FHQS questionnaire in the given timeframe you will be discharged from the streamlined treatment pathway. In the event that you are discharged, you will need to obtain a new referral to access the Software again." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>6.2</span>
            </ListItemIcon>
            <ListItemText primary="We may terminate this Licence immediately by written notice to you if you commit a material or persistent breach of this Licence which you fail to remedy (if remediable) within 14 days after the service of written notice requiring you to do so." />
          </ListItem>
        </List>
        <Typography className={classes.listHeader}>
          7.&nbsp;&nbsp;&nbsp;&nbsp;COMMUNICATIONS BETWEEN US
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>7.1</span>
            </ListItemIcon>
            <ListItemText primary="If you wish to contact us, you can email us at cancer.genetics@stgeorges.nhs.uk, write to us at South West Thames Regional Genetic Service, St George’s University Hospitals NHS Foundation Trust, Blackshaw Road, Tooting, London, SW17 0QT or call us at 02087255333." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>7.2</span>
            </ListItemIcon>
            <ListItemText primary="If we have to contact you or give you notice in writing, we will do so by email or by pre-paid post to the address held by your GP Practice." />
          </ListItem>
        </List>
        <Typography className={classes.listHeader}>
          8.&nbsp;&nbsp;&nbsp;&nbsp;HOW WE MAY USE YOUR PERSONAL INFORMATION
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>8.1</span>
            </ListItemIcon>
            <ListItemText primary="Under data protection legislation, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes and your rights in relation to your personal data and how to exercise them. This information is provided " />
          </ListItem>
          <Typography className={classes.listItemLink}>
            <a
              href={process.env.PUBLIC_URL + '/docs/consent-and-privacy.pdf'}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            and it is important that you read that information.
          </Typography>
        </List>
        <Typography className={classes.listHeader}>
          9.&nbsp;&nbsp;&nbsp;&nbsp;OTHER IMPORTANT TERMS
        </Typography>
        <List className={classes.bulletList}>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>9.1</span>
            </ListItemIcon>
            <ListItemText primary="This Licence does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Licence." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>9.2</span>
            </ListItemIcon>
            <ListItemText primary="Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect." />
          </ListItem>
          <ListItem alignItems={'flex-start'} className={classes.compactBullet}>
            <ListItemIcon>
              <span className={classes.boldSubtitle}>9.3</span>
            </ListItemIcon>
            <ListItemText primary="These terms are governed by English law and you can bring legal proceedings in the English courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in either the Northern Irish or the English courts." />
          </ListItem>
          <Typography className={classes.listHeader} paragraph>
            By clicking on the “Accept” button below, you agree to the terms of
            this Licence which will bind your use of the Software.
          </Typography>
        </List>
      </div>
    );
  }

  render() {
    const { onAccepted } = this.props;
    const eula = this.props.service?.serviceConfiguration?.eula;

    return (
      <>
        <TitleCard
          cardTitle="End User Licence Agreement"
          cardSubtitle="Please read these licence terms carefully and click accept if you wish to continue"
          content={
            eula ? this.renderMarkdownEULA(eula) : this.renderSGHSWTEULA()
          }
          footer={<Button onClick={onAccepted}>Accept</Button>}
        />
        <ScrollToTopOnMount />
      </>
    );
  }
}

const mapState = state => {
  const { organisation, service, error } = state.organisation;

  const { loggedIn } = state.authentication;

  return {
    loggedIn,
    organisation,
    service,
    error
  };
};

Eula.propTypes = {
  classes: PropTypes.object.isRequired,
  onAccepted: Boolean
};

const styledEula = compose(
  connect(mapState),
  withStyles(styles, { withTheme: true })
)(Eula);
export { styledEula as Eula };
