import React from 'react';
import loader from '../../Loader/Loader';
import TitleCard from '../../Cards/TitleCard';
import { isEmpty } from '../../../helpers/types';
import { authentication } from '../../../helpers/authentication';
import pedigreesIso from '../../../images/pedigrees-iso.png';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import Markdown from '../../Markdown/Markdown';
import { connect } from 'react-redux';
import { config } from '../../../helpers/config';

const styles = theme => Styles(theme);

class Introduction extends React.Component {
  renderMarkdownIntro(markdown) {
    return <Markdown>{markdown}</Markdown>;
  }

  renderSGHSWTIntro() {
    const { classes } = this.props;
    const contactEmail = this.props.service?.serviceConfiguration?.contactEmail;
    const email = contactEmail ? contactEmail : config.email;

    return (
      <div>
        <Typography variant="subtitle1">
          Please <Link to="/register">register</Link> and then{' '}
          <Link to="/signin">sign in</Link> to the questionnaire. You will need
          your NHS number for registration. Once you have completed the
          questionnaire you will be able to download a pdf of your family tree
          (pedigree) diagram.
        </Typography>
        <div className={classes.isoPedigree}>
          <img src={pedigreesIso} alt="Example pedigrees" />
        </div>
        <Typography variant="subtitle1" paragraph>
          This questionnaire will ask about:
        </Typography>
        <List className={classes.bulletList}>
          <ListItem className={classes.compactBullet}>
            <ListItemIcon>
              <DotIcon color={'inherit'} fontSize={'inherit'} />
            </ListItemIcon>
            <ListItemText primary="Your personal and family history of cancer, polyps, other tumours or high calcium levels" />
          </ListItem>
          <ListItem className={classes.compactBullet}>
            <ListItemIcon>
              <DotIcon color={'inherit'} fontSize={'inherit'} />
            </ListItemIcon>
            <ListItemText primary="Your ethnic origin" />
          </ListItem>
          <ListItem className={classes.compactBullet}>
            <ListItemIcon>
              <DotIcon color={'inherit'} fontSize={'inherit'} />
            </ListItemIcon>
            <ListItemText primary="Whether you or anyone in your family has ever been seen by a genetic service in the past" />
          </ListItem>
        </List>
        <Typography variant="subtitle1" paragraph>
          Your family history is information about your biological (blood)
          relatives. This information will be used to help us make a risk
          assessment for the purposes of advising you and/or your relatives on
          the risk of developing cancer, or certain other tumours. If you are
          uncertain about what{' '}
          <a
            href="/faq#cancer-or-tumours"
            target="_blank"
            rel="noopener noreferrer"
          >
            cancer
          </a>{' '}
          or{' '}
          <a
            href="/faq#cancer-or-tumours"
            target="_blank"
            rel="noopener noreferrer"
          >
            tumours
          </a>{' '}
          are please read the{' '}
          <a
            href="/faq#cancer-or-tumours"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </a>{' '}
          section before you start.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          The questionnaire will take between 30-60 minutes to fill out. You do
          not have to fill it out in one go. It will save your answers and
          return you to the same location, so you can come back to it if you
          need to. Please do not worry if you do not know all the information.
          Fill out what you can to the best of your knowledge. You may need to
          estimate dates of birth if you do not have the exact date or year for
          some of your relatives.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Please give as much information as you can about both affected and
          unaffected family members. The more information that you can provide
          the better and more accurate your risk assessment will be. Please only
          include your biological (blood) relatives, you do not need to provide
          information about step or adopted relatives.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          If you have any questions or issues with the questionnaire please
          email us on <a href={`mailto:${email}`}>{email}</a>
        </Typography>
      </div>
    );
  }

  render() {
    const NavigationLink = React.forwardRef((props, ref) => {
      return <Link {...props} ref={ref} />;
    });

    NavigationLink.displayName = 'NavigationLink';

    const intro = this.props.introduction;

    return (
      <TitleCard
        cardTitle="Introduction"
        cardSubtitle="Information about the service"
        content={
          intro ? this.renderMarkdownIntro(intro) : this.renderSGHSWTIntro()
        }
        footer={
          isEmpty(authentication()) ? (
            <div>
              <Button component={NavigationLink} to="/signin">
                Sign In
              </Button>
              <Button component={NavigationLink} to="/register">
                Register
              </Button>
            </div>
          ) : (
            <Button component={NavigationLink} to="/start">
              Start
            </Button>
          )
        }
      />
    );
  }
}

const mapState = state => {
  const { introduction, organisation, service, serviceConfiguration, error } =
    state.organisation;

  const { loggedIn } = state.authentication;

  return {
    loggedIn,
    introduction,
    organisation,
    service,
    serviceConfiguration,
    error
  };
};

const styledIntroduction = compose(
  loader,
  connect(mapState),
  withStyles(styles, { withTheme: true })
)(Introduction);

export { styledIntroduction as Introduction };
