import { createAsyncThunk } from '@reduxjs/toolkit';
import { navigation } from './unrefactored/navigation';
import { addAutoHidingAlert } from '../alerts-slice';
import { setCurrentFieldInfo } from './questionnaire-slice';
import { NavigationConstants } from '../../helpers/constants';

export const previousQuestion = createAsyncThunk(
  'newQuestionnaire/previousQuestion',
  /** @param currentFieldInfo {FieldInfo}
       @param thunkAPI {GetThunkAPI<{}>} */
  async (currentFieldInfo, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return navigation
      .getPrevQuestion(currentFieldInfo)
      .then(prevFieldInfo => {
        dispatch(setCurrentFieldInfo(prevFieldInfo));
        return prevFieldInfo;
      })
      .catch(error => {
        dispatch(
          addAutoHidingAlert({
            message: error,
            variant: 'error',
            autoDismiss: true
          })
        );
        return error;
      });
  }
);

export const nextQuestion = createAsyncThunk(
  'newQuestionnaire/nextQuestion',
  /** @param currentFieldInfo {FieldInfo}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (currentFieldInfo, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return navigation
      .getNextQuestion(currentFieldInfo)
      .then(nextFieldInfo => {
        // Check we're not at end of questionnaire
        if (nextFieldInfo === NavigationConstants.END_QUESTIONNAIRE) {
          return nextFieldInfo;
        }

        // Set current field
        dispatch(setCurrentFieldInfo(nextFieldInfo));
        return nextFieldInfo;
      })
      .catch(error => {
        dispatch(
          addAutoHidingAlert({
            message: error,
            variant: 'error',
            autoDismiss: true
          })
        );
        return error;
      });
  }
);
