import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../../types/stores';
import { config } from '../../helpers/config';
import { history } from '../../helpers/history';
import { addAutoHidingAlert } from '../../features/alerts-slice';
import { DataError, HttpResponse } from '../../types/system';
import { AnyAction } from 'redux';

export const baseUrl = config.apiUrl;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState)?.authentication?.user?.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('pragma', 'no-cache');
      headers.set('cache-control', 'no-cache');
    }
    return headers;
  }
});

export const baseQueryWithUnauthLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  const { dispatch } = api;

  if (result.error && result.error.status === 401) {
    history.push('/signout');

    dispatch(
      addAutoHidingAlert({
        message: 'Your session has expired. Please sign in again.',
        variant: 'error',
        autoDismiss: true
      })
    );
  }
  return result;
};

export const extractRTKQueryError = (
  action: AnyAction
): { error: string; status: number } => {
  if (action.error && action.error.name !== 'ConditionError') {
    const status = (action.payload as HttpResponse)?.status;

    if (action.error) {
      const dataErrors = (action.payload as DataError)?.data?.errors;

      if (dataErrors) {
        let errorMsg = '\n';

        for (const property in dataErrors) {
          const fieldName = property;
          const errorDesc = dataErrors[property];

          if (fieldName !== 'model') {
            errorMsg = `${fieldName}: ${errorDesc}\n${errorMsg}`;
          }
        }

        return { error: errorMsg, status };
      } else {
        const error = action?.payload?.data as string;

        if (error) {
          return { error, status };
        }
      }
    }
  }

  return { error: 'Unknown server error', status: 500 };
};
