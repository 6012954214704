import { createAsyncThunk } from '@reduxjs/toolkit';
import { FieldInfo } from '../../types/questionnaire';
import { AppDispatch, RootState } from '../../types/stores';
import { fieldValidations } from '../../components/Questionnaire/validations';
import { run } from '../../validation/ruleRunner';
import { setQuestionText, setValidation } from './questionnaire-slice';
import { parseQuestion } from './question-parser-thunk';
import { defaultPayload } from '../../helpers/redux';
import { aliasedResponse } from './aliased-response-thunk';
import { removeWhiteSpaceAndTitleCase } from '../../helpers/strings';
import { markdownListFromArray } from '../../helpers/markdown';

export const setParsedQuestionText = createAsyncThunk<
  void,
  FieldInfo,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newQuestionnaire/setQuestionText',
  async (fieldInfo: FieldInfo, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const parsedQuestion = defaultPayload(
      await dispatch(parseQuestion(fieldInfo))
    );

    parsedQuestion && dispatch(setQuestionText(parsedQuestion));
  }
);

export const validate = createAsyncThunk<
  void,
  { fieldInfo: FieldInfo; draftResponse: string },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newQuestionnaire/validate',
  async ({ fieldInfo, draftResponse }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState?.questionnaire;
    const question = questionnaire?.questions?.find(
      question => question.id === fieldInfo.questionId
    );
    const newState = {};
    newState[fieldInfo.fieldName] = draftResponse;

    if (question) {
      const validations = fieldValidations(
        fieldInfo.fieldName,
        question.answerPlaceholder,
        question.answerType,
        question.requiredAnswer
      );
      const results = run(newState, validations);

      if (results) {
        dispatch(
          setValidation({
            field: fieldInfo.fieldName,
            validationResults: results
          })
        );
      }
    }
  }
);

export const relatedCancersTo = createAsyncThunk<
  string,
  { command: string; currentFieldInfo: FieldInfo },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newQuestionnaire/relatedCancersTo',
  async ({ command, currentFieldInfo }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const questionnaireState = getState().newQuestionnaire;

    const regExp = /\(([^)(]*)\)/;
    const matches = regExp.exec(command);
    const subject = matches && matches[1];

    if (!subject) return command;

    const subjectValue = defaultPayload(
      await dispatch(
        aliasedResponse({ alias: subject, fieldInfo: currentFieldInfo })
      )
    );

    if (!subjectValue) return command;

    const subjectCancer = removeWhiteSpaceAndTitleCase(subjectValue);
    const relatedLookupKey = `ListOfRelatedCancersFor${subjectCancer}`;

    const lookupList = questionnaireState?.contexts?.get(
      relatedLookupKey
    ) as string[];

    if (!lookupList || lookupList.length === 0) return command;

    const mdLinebreakList = markdownListFromArray(lookupList);

    return mdLinebreakList || command;
  }
);
