import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { config } from '../../../helpers/config';
import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import loader from '../../Loader/Loader';
import EmailIcon from '@material-ui/icons/Email';
import AtIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
const styles = theme => Styles(theme);
import { connect } from 'react-redux';

class Contact extends React.Component {
  render() {
    const { classes, service, loggedIn, user } = this.props;
    const address = service?.serviceConfiguration?.address;
    const phoneNumber = service?.serviceConfiguration?.phoneNumber;
    const contactEmail = service?.serviceConfiguration?.contactEmail;
    const jiraLink = service?.serviceConfiguration?.jiraServiceLink;

    return (
      <TitleCard
        cardTitle="Contact Us"
        cardSubtitle="Please post to the address below, or phone or email us"
        content={
          <div>
            <List>
              <ListItem className={classes.alignTop}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.withBreaks}
                  primary={
                    address
                      ? address
                      : `South West Thames Regional Genetic Service
                                        St George’s University Hospitals NHS Foundation Trust
                                        Blackshaw Road
                                        Tooting
                                        London
                                        SW17 0QT`
                  }
                />
              </ListItem>
              <ListItem className={classes.alignTop}>
                <ListItemIcon>
                  <AtIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.withBreaks}
                  primary={
                    <a
                      href={`mailto:${
                        contactEmail ? contactEmail : config.email
                      }`}
                      className={classes.noDecorationLink}
                    >
                      {contactEmail ? contactEmail : config.email}
                    </a>
                  }
                />
              </ListItem>
              <ListItem className={classes.alignTop}>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.withBreaks}
                  primary={phoneNumber ? phoneNumber : config.phoneNumber}
                />
              </ListItem>
              {loggedIn && user && user.isAdmin && jiraLink ? (
                <ListItem className={classes.alignTop}>
                  <ListItemIcon>
                    <HelpOutlineIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.withBreaks}
                    primary={
                      <a
                        href={jiraLink}
                        className={classes.noDecorationLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Report an issue"
                      >
                        FHQS Service Desk
                      </a>
                    }
                  />
                </ListItem>
              ) : null}
            </List>
          </div>
        }
      />
    );
  }
}

const mapState = state => {
  const { organisation, service, error } = state.organisation;

  const { loggedIn, user } = state.authentication;

  return {
    loggedIn,
    user,
    organisation,
    service,
    error
  };
};

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

const styledContact = compose(
  loader,
  connect(mapState),
  withStyles(styles, { withTheme: true })
)(Contact);

export { styledContact as Contact };
