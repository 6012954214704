import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core';
import Styles from './styles';

const styles = theme => Styles(theme);

// eslint-disable-next-line react/display-name
const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink exact {...props} innerRef={ref} />
));

class ListItemLink extends React.Component {
  render() {
    const { to, activeClassName, onClick, icon, primary, disabled, classes } =
      this.props;

    return (
      <ListItem
        exact
        to={to}
        activeClassName={activeClassName}
        onClick={onClick}
        button
        disabled={disabled}
        component={ForwardNavLink}
        classes={{ gutters: classes.listItemsGutters }}
      >
        {icon != null ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    );
  }
}

ListItemLink.propTypes = {
  icon: PropTypes.node.isRequired,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

ListItemLink.defaultProps = {
  disabled: false
};

export default withStyles(styles, { withTheme: true })(ListItemLink);
