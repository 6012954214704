const styles = theme => ({
  container: {
    flex: 1
  },
  textField: {
    width: '100%'
  },
  desktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});

export default styles;
