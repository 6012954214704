import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../services/api/dataApi';
import { ActionStatuses, SetAction } from '../types/system';
import {
  ServiceCode,
  SubmissionDetail,
  WebPedigreeRequest,
  WebSummaryRequest,
  WebCanRiskRequest
} from '../types/organisation';
import { ErrorConstants } from '../helpers/constants';
import { history } from '../helpers/history';
import { addAutoHidingAlert } from './alerts-slice';
import { AppDispatch, RootState } from '../types/stores';
import {
  getPedigree,
  getSummary,
  getCanRisk,
  getAuthenticatorSetupPdfLetter,
  getResetPasswordLinkPdf
} from '../services/api/fileDownloadApi';
import { extractRTKQueryError } from '../services/api/apiConfig';
import {
  ApplicationUser,
  ResetPasswordRequest,
  SetupAuthenticatorRequest
} from '../types/users';

interface AdminSubmissionsState {
  selectedServiceCode?: ServiceCode;
  submissionsByServiceCode?: Array<SubmissionDetail>;
  submissions?: Array<SubmissionDetail>;
  serviceCodeActionStatus: ActionStatuses;
  actionStatuses: ActionStatuses;
  isSubmissionsLoading: boolean;
  isSubmissionsByServiceCodeLoading: boolean;
  submissionErrors?: string | undefined;
  submissionErrorsByServiceCode?: string | undefined;
  adminUsers?: Array<ApplicationUser>;
  isAdminUsersLoading: boolean;
  adminUserErrors?: string | undefined;
  userRoleErrors?: string | undefined;
  isAddingRole: boolean;
}

const initialState: AdminSubmissionsState = {
  isSubmissionsLoading: false,
  isSubmissionsByServiceCodeLoading: false,
  actionStatuses: {},
  serviceCodeActionStatus: {},
  isAdminUsersLoading: false,
  isAddingRole: false
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    adminLogout() {
      return initialState;
    },
    setSelectedServiceCode(state, action: PayloadAction<ServiceCode>) {
      state.selectedServiceCode = action.payload;
    },
    setActionStatus(state, action: PayloadAction<SetAction>) {
      state.actionStatuses[action.payload.key] = action.payload.status;
    },
    setServiceCodeActionStatus(state, action: PayloadAction<SetAction>) {
      state.serviceCodeActionStatus[action.payload.key] = action.payload.status;
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        api.endpoints.submissionDetails.matchFulfilled,
        (state, { payload }) => {
          state.submissions = payload as Array<SubmissionDetail>;
          state.submissionErrors = undefined;
          state.isSubmissionsLoading = false;
        }
      )
      .addMatcher(api.endpoints.submissionDetails.matchPending, state => {
        state.submissions = undefined;
        state.submissionErrors = undefined;
        state.isSubmissionsLoading = true;
      })
      .addMatcher(
        api.endpoints.submissionDetails.matchRejected,
        (state, action) => {
          if (action.error && action.error.name !== 'ConditionError') {
            const { error, status } = extractRTKQueryError(action);
            state.submissionErrors = error;
            state.submissions = undefined;
            state.isSubmissionsLoading = false;
            console.log(`submissionDetails failed with: ${status}`);
          }
        }
      )
      .addMatcher(
        api.endpoints.submissionDetailsByServiceCode.matchFulfilled,
        (state, { payload }) => {
          state.submissionsByServiceCode = payload as Array<SubmissionDetail>;
          state.submissionErrorsByServiceCode = undefined;
          state.isSubmissionsByServiceCodeLoading = false;
        }
      )
      .addMatcher(
        api.endpoints.submissionDetailsByServiceCode.matchPending,
        state => {
          state.submissionsByServiceCode = undefined;
          state.submissionErrorsByServiceCode = undefined;
          state.isSubmissionsByServiceCodeLoading = true;
        }
      )
      .addMatcher(
        api.endpoints.submissionDetailsByServiceCode.matchRejected,
        (state, action) => {
          if (action.error && action.error.name !== 'ConditionError') {
            const { error, status } = extractRTKQueryError(action);
            state.submissionErrors = error;
            state.submissionsByServiceCode = undefined;
            state.isSubmissionsByServiceCodeLoading = false;
            console.log(
              `submissionDetailsByServiceCode failed with: ${status}`
            );
          }
        }
      )
      .addMatcher(
        api.endpoints.getAdminUsers.matchFulfilled,
        (state, { payload }) => {
          state.adminUsers = payload as Array<ApplicationUser>;
          state.adminUserErrors = undefined;
          state.isAdminUsersLoading = false;
        }
      )
      .addMatcher(api.endpoints.getAdminUsers.matchPending, state => {
        state.adminUsers = undefined;
        state.adminUserErrors = undefined;
        state.isAdminUsersLoading = true;
      })
      .addMatcher(
        api.endpoints.getAdminUsers.matchRejected,
        (state, action) => {
          if (action.error && action.error.name !== 'ConditionError') {
            const { error, status } = extractRTKQueryError(action);
            state.adminUserErrors = error;
            state.adminUsers = undefined;
            state.isAdminUsersLoading = false;
            console.log(`getAdminUsers failed with: ${status}`);
          }
        }
      )
      .addMatcher(api.endpoints.addUserToRole.matchFulfilled, state => {
        state.userRoleErrors = undefined;
        state.isAddingRole = false;
      })
      .addMatcher(api.endpoints.addUserToRole.matchPending, state => {
        state.userRoleErrors = undefined;
        state.isAddingRole = true;
      })
      .addMatcher(
        api.endpoints.addUserToRole.matchRejected,
        (state, action) => {
          if (action.error && action.error.name !== 'ConditionError') {
            const { error, status } = extractRTKQueryError(action);
            state.userRoleErrors = error;
            state.isAddingRole = false;
            console.log(`addUserToRole failed with: ${status}`);
          }
        }
      );
  }
});

export const getWebPedigree = createAsyncThunk<
  Record<string, unknown> | undefined,
  WebPedigreeRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newAdmin/getWebPedigree',
  async ({ questionnaireId, userId, fileType, downloadType }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const pedigree = await getPedigree(
        questionnaireId,
        userId,
        fileType,
        downloadType
      );

      return pedigree;
    } catch (error) {
      let msg = 'Server error';

      if (error === ErrorConstants.UnauthorisedToken) {
        history.push('/signout');
        msg = 'Your session has expired. Please sign in again.';
      } else if (error === '') {
        msg = 'Server error';
      }
      dispatch(
        addAutoHidingAlert({
          message: msg,
          variant: 'error',
          autoDismiss: true
        })
      );
    }
  }
);

export const getWebSummary = createAsyncThunk<
  Record<string, unknown> | undefined,
  WebSummaryRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newAdmin/getWebSummary',
  async (
    {
      questionnaireId,
      nhsNo,
      fileType,
      downloadType,
      serviceCode,
      markAsDownloaded
    },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;

    try {
      const summary = await getSummary(
        questionnaireId,
        nhsNo,
        fileType,
        downloadType,
        serviceCode,
        markAsDownloaded
      );

      return summary;
    } catch (error) {
      let msg = 'Server error';

      if (error === ErrorConstants.UnauthorisedToken) {
        history.push('/signout');
        msg = 'Your session has expired. Please sign in again.';
      } else if (error === '') {
        msg = 'Server error';
      }
      dispatch(
        addAutoHidingAlert({
          message: msg,
          variant: 'error',
          autoDismiss: true
        })
      );
    }
  }
);

export const getWebCanRisk = createAsyncThunk<
  Record<string, unknown> | undefined,
  WebCanRiskRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newAdmin/getCanRisk',
  async ({ questionnaireId, nhsNo, serviceCode }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const canRisk = await getCanRisk(questionnaireId, nhsNo, serviceCode);

      return canRisk;
    } catch (error) {
      let msg = 'Server error';

      if (error === ErrorConstants.UnauthorisedToken) {
        history.push('/signout');
        msg = 'Your session has expired. Please sign in again.';
      } else if (error === '') {
        msg = 'Server error';
      }
      dispatch(
        addAutoHidingAlert({
          message: msg,
          variant: 'error',
          autoDismiss: true
        })
      );
    }
  }
);

export const getWebAuthenticatorSetup = createAsyncThunk<
  Record<string, unknown> | undefined,
  SetupAuthenticatorRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('newAdmin/getWebAuthenticatorSetup', async ({ email }, thunkAPI) => {
  const { dispatch } = thunkAPI;

  try {
    const setupPdf = await getAuthenticatorSetupPdfLetter(email);

    return setupPdf;
  } catch (error) {
    let msg = 'Server error';

    if (error === ErrorConstants.UnauthorisedToken) {
      history.push('/signout');
      msg = 'Your session has expired. Please sign in again.';
    } else if (error === '') {
      msg = 'Server error';
    }
    dispatch(
      addAutoHidingAlert({
        message: msg,
        variant: 'error',
        autoDismiss: true
      })
    );
  }
});

export const getResetPasswordLink = createAsyncThunk<
  Record<string, unknown> | undefined,
  ResetPasswordRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newAdmin/getResetPasswordLink',
  async ({ email, newPasswordUrl }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const passwordLinkPdf = await getResetPasswordLinkPdf(
        email,
        newPasswordUrl
      );

      return passwordLinkPdf;
    } catch (error) {
      let msg = 'Server error';

      if (error === ErrorConstants.UnauthorisedToken) {
        history.push('/signout');
        msg = 'Your session has expired. Please sign in again.';
      } else if (error === '') {
        msg = 'Server error';
      }
      dispatch(
        addAutoHidingAlert({
          message: msg,
          variant: 'error',
          autoDismiss: true
        })
      );
    }
  }
);

export const {
  setActionStatus,
  setServiceCodeActionStatus,
  setSelectedServiceCode,
  adminLogout
} = adminSlice.actions;

export default adminSlice.reducer;
