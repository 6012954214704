import { createSlice } from '@reduxjs/toolkit';
import { User } from '../types/users';
import { api } from '../services/api/dataApi';
import { extractRTKQueryError } from '../services/api/apiConfig';

const storedUser = localStorage.getItem('user');
const user = storedUser ? JSON.parse(storedUser) : null;

interface RegistrationState {
  user?: User | undefined;
  registerErrors?: string | undefined;
  registering: boolean;
  registered: boolean;
}

const initialState: RegistrationState = {
  user,
  registerErrors: undefined,
  registering: false,
  registered: false
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        api.endpoints.register.matchFulfilled,
        (state, { payload }) => {
          state.user = payload as User;
          state.registered = true;
          state.registering = false;
          state.registerErrors = undefined;
        }
      )
      .addMatcher(api.endpoints.register.matchPending, state => {
        state.user = undefined;
        state.registered = false;
        state.registering = true;
        state.registerErrors = undefined;
      })
      .addMatcher(api.endpoints.register.matchRejected, (state, action) => {
        if (action.error && action.error.name !== 'ConditionError') {
          const { error, status } = extractRTKQueryError(action);
          state.registerErrors = error;
          state.registered = false;
          state.registering = false;
          console.log(`Register failed with: ${status}`);
        }
      });
  }
});

export default registrationSlice.reducer;
