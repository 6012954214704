import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { SideBar } from './SideBar/SideBar';
import TopBar from './TopBar';
import Main from './Main/Main';
import Styles from './styles';
import { history } from '../../helpers/history';
import CookieBanner from 'react-cookie-banner';

const styles = theme => Styles(theme);

class Layout extends React.Component {
  state = {
    drawerOpen: false
  };

  handleDrawerToggle = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <Router history={history}>
        <div className={classes.root}>
          <TopBar onDrawerToggle={this.handleDrawerToggle} />
          <SideBar
            onDrawerToggle={this.handleDrawerToggle}
            drawerOpen={this.state.drawerOpen}
          />
          <Main />
          <CookieBanner
            className={classes.cookieBanner}
            buttonMessage="Accept"
            link={
              <a href={theme.privacyNotice.link}>
                {theme.privacyNotice.linkTitle}
              </a>
            }
            message={theme.privacyNotice.message}
            cookie={theme.privacyNotice.cookie}
          />
        </div>
      </Router>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Layout);
