const styles = theme => ({
  container: {
    padding: 24,
    [theme.breakpoints.down('xs')]: {
      padding: 16
    }
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  heading: {
    fontWeight: 500,
    flexBasis: '80%',
    flexShrink: 0
  },
  expansionPanel: {
    width: '100%',
    border: 0,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'white'
    }
  },
  crashContainer: {
    maxWidth: 200,
    maxHeight: 200,
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  crash: {
    width: '100%',
    height: '100%'
  },
  noDecorationLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none'
    }
  },
  summaryContainer: {
    width: '100%'
  },
  panelDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  stackDetails: {
    overflowX: 'auto'
  },
  centeredText: {
    textAlign: 'center'
  }
});

export default styles;
