import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Styles from './styles';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  questionNavigate,
  sectionNavigate
} from '../../../../features/questionnaire/questionnaire-thunks';
import { getRelativeSections } from '../../../../helpers/sections';

const styles = theme => Styles(theme);

class SectionLink extends React.Component {
  handleSectionClick = async () => {
    const { sectionId, onDrawerToggle, sectionNavigate } = this.props;
    await sectionNavigate(sectionId);
    onDrawerToggle();
  };

  handleExpand = (e, sectionId, relativeSections) => {
    e.stopPropagation();
    const { relatives, onSectionToggle } = this.props;

    if (
      !relativeSections.includes(sectionId) ||
      relatives == null ||
      relatives.length === 0
    ) {
      return;
    }

    onSectionToggle(e, sectionId);
  };

  handleRelativeClicked = async (e, fieldName) => {
    const { onDrawerToggle, questionNavigate } = this.props;
    e.stopPropagation();
    await questionNavigate(fieldName);
    onDrawerToggle();
  };

  render() {
    const {
      classes,
      sectionId,
      sections,
      sequence,
      relatives,
      text,
      key,
      completed,
      started,
      selected,
      expanded
    } = this.props;

    const relativeSections = getRelativeSections(sections);

    const hasRelatives =
      relativeSections.map(s => s.id).includes(sectionId) &&
      relatives.length > 0;

    const listItemClasses = classNames(
      classes.listItem,
      selected ? classes.selected : ''
    );

    return (
      <ListItem
        button
        disabled={!started}
        className={listItemClasses}
        key={key}
        onClick={this.handleSectionClick}
      >
        <ExpansionPanel
          classes={{ root: classes.panelRoot, expanded: classes.panelExpanded }}
          expanded={expanded != null ? expanded : false}
        >
          <ExpansionPanelSummary
            classes={{
              content: classNames(
                classes.summaryContent,
                classes.summaryContentMargin
              ),
              root: classNames(classes.summaryRoot, classes.summaryRootHeight),
              expandIcon: classes.expandIcon
            }}
            expandIcon={hasRelatives ? <ExpandMoreIcon /> : null}
          >
            <div className={classes.sectionInfoContainer}>
              <div className={classes.badgeContainer}>
                <Badge
                  badgeContent={sequence}
                  color="primary"
                  classes={{ badge: classes.badge }}
                >
                  <div className={classes.badgeIcon}>
                    <LabelOutlinedIcon fontSize={'inherit'} />
                  </div>
                </Badge>
              </div>
              <div className={classes.sectionInfoDetail}>
                <div className={classes.sectionInfo}>
                  <Typography
                    variant="subtitle2"
                    className={classes.boldSubtitle}
                  >
                    {text}
                  </Typography>
                  <div className={classes.checkContainer}>
                    {completed ? (
                      <CheckCircleIcon className={classes.checkCircle} />
                    ) : null}
                  </div>
                </div>
                {hasRelatives ? (
                  <Typography
                    variant="overline"
                    className={classes.relativeSummary}
                    onClick={e =>
                      this.handleExpand(e, sectionId, relativeSections)
                    }
                  >
                    Relatives: {relatives.length}
                  </Typography>
                ) : null}
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: hasRelatives
                ? classes.expansionPanelDetailRoot
                : classes.expansionPanelDetailEmptyRoot
            }}
          >
            <div className={classes.sectionContainer}>
              {relatives != null ? (
                relatives.map(relative => {
                  return relative.depth === 1 ? (
                    <div
                      key={relative.id}
                      className={classes.relativeLevelOne}
                      onClick={e => this.handleRelativeClicked(e, relative.id)}
                    >
                      <ListItemText secondary={relative.name} />
                    </div>
                  ) : (
                    <div
                      key={relative.id}
                      className={classNames(
                        classes.relativeLevelOne,
                        classes.relativeLevelTwo
                      )}
                      onClick={e => this.handleRelativeClicked(e, relative.id)}
                    >
                      <ListItemText secondary={relative.name} />
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </ListItem>
    );
  }
}

const mapStateToProps = state => {
  const {
    questionnaire: { sections }
  } = state.newQuestionnaire;

  return {
    sections
  };
};

const mapDispatch = {
  sectionNavigate,
  questionNavigate
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatch)
)(SectionLink);
