import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('user') ? (
        rest.Component ? (
          rest.Component
        ) : (
          rest.render()
        )
      ) : (
        <Redirect
          to={{ pathname: '/signin', state: { from: props.location } }}
        />
      )
    }
  />
);
