const styles = theme => ({
  container: {
    marginTop: 16
  },
  boldSubtitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500
  },
  withBreaks: {
    whiteSpace: 'pre-line'
  },
  compactBullet: {
    fontSize: '12px',
    paddingTop: 0,
    paddingBottom: 0
  },
  bulletList: {
    marginBottom: 15
  },
  listHeader: {
    marginTop: 20,
    fontWeight: 500
  },
  listItemLink: {
    marginLeft: 70
  },
  noDecorationLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none'
    }
  }
});

export default styles;
