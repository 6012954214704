import { colourAdjust } from '../../helpers/colours';

const styles = theme => {
  const lightenLight = colourAdjust(theme.palette.primary.main, 200);
  const darkenDark = colourAdjust(theme.palette.primary.dark, -100);

  return {
    textContainer: {
      marginTop: 53,
      [theme.breakpoints.down('sm')]: {
        height: '100%'
      }
    },
    container: {
      marginTop: theme.spacing(2),
      display: 'flex',
      height: '100%',
      width: '100%',
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    },
    dna: {
      marginTop: theme.spacing(4),
      display: 'inline-block',
      position: 'relative',
      transform: 'scale(0.45)'
    },
    nucleobase: {
      display: 'inline-block',
      position: 'relative',
      verticalAlign: 'middle',
      willChange: 'transform',
      '&:not(:last-child)': {
        marginRight: 48.6,
        [theme.breakpoints.down('sm')]: {
          marginRight: 28
        }
      },
      '&:before,&:after': {
        content: "''",
        display: 'inline-block',
        width: 30,
        height: 30,
        borderRadius: '50%',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
          width: 24,
          height: 24
        }
      },
      '&:nth-child(1)': {
        animationDelay: '-1.869s'
      },
      '&:nth-child(1):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-1.869s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(1):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-1.869s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(2)': {
        animationDelay: '-3.738s'
      },
      '&:nth-child(2):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-3.738s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(2):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-3.738s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(3)': {
        animationDelay: '-5.607s'
      },
      '&:nth-child(3):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-5.607s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(3):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-5.607s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(4)': {
        webkitAnimationDelay: '-7.476s',
        animationDelay: '-7.476s'
      },
      '&:nth-child(4):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-7.476s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(4):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-7.476s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(5)': {
        animationDelay: '-9.345s'
      },
      '&:nth-child(5):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-9.345s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(5):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-9.345s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(6)': {
        animationDelay: '-11.214s'
      },
      '&:nth-child(6):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-11.214s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(6):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-11.214s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(7)': {
        animationDelay: '-13.083s'
      },
      '&:nth-child(7):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-13.083s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(7):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-13.083s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(8)': {
        animationDelay: '-14.952s'
      },
      '&:nth-child(8):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-14.952s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(8):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-14.952s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(9)': {
        animationDelay: '-16.821s'
      },
      '&:nth-child(9):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-16.821s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(9):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-16.821s',
        backgroundColor: theme.palette.primary.dark
      },
      '&:nth-child(10)': {
        animationDelay: '-18.69s'
      },
      '&:nth-child(10):before': {
        animation: '$animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-18.69s',
        backgroundColor: theme.palette.primary.main
      },
      '&:nth-child(10):after': {
        animation: '$animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
        animationDelay: '-18.69s',
        backgroundColor: theme.palette.primary.dark
      }
    },
    '@keyframes animBefore': {
      '0%': {
        top: -60,
        zIndex: 1,
        rotationZ: '0.01deg'
      },
      '25%': {
        transform: 'scale(1.2)',
        zIndex: 1,
        rotationZ: '0.01deg'
      },
      '50%': {
        top: 60,
        zIndex: -1,
        rotationZ: '0.01deg'
      },
      '75%': {
        backgroundColor: lightenLight,
        transform: 'scale(0.8)',
        zIndex: -1,
        rotationZ: '0.01deg'
      },
      '100%': {
        top: -60,
        zIndex: -1,
        rotationZ: '0.01deg'
      }
    },
    '@keyframes animAfter': {
      '0%': {
        top: 60,
        zIndex: -1,
        rotationZ: '0.01deg'
      },
      '25%': {
        backgroundColor: darkenDark,
        transform: 'scale(0.8)',
        zIndex: -1,
        rotationZ: '0.01deg'
      },
      '50%': {
        top: -60,
        zIndex: 1,
        rotationZ: '0.01deg'
      },
      '75%': {
        transform: 'scale(1.2)',
        zIndex: 1,
        rotationZ: '0.01deg'
      },
      '100%': {
        top: 60,
        zIndex: 1,
        rotationZ: '0.01deg'
      }
    },
    [theme.breakpoints.up('md')]: {
      '@keyframes animBefore': {
        '0%': {
          top: -60,
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '25%': {
          transform: 'scale(1.2)',
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '50%': {
          top: 60,
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '75%': {
          backgroundColor: lightenLight,
          transform: 'scale(0.8)',
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '100%': {
          top: -60,
          zIndex: -1,
          rotationZ: '0.01deg'
        }
      },
      '@keyframes animAfter': {
        '0%': {
          top: 60,
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '25%': {
          backgroundColor: darkenDark,
          transform: 'scale(0.8)',
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '50%': {
          top: -60,
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '75%': {
          transform: 'scale(1.2)',
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '100%': {
          top: 60,
          zIndex: 1,
          rotationZ: '0.01deg'
        }
      },
      loadingText: {
        fontWeight: 400,
        fontSize: '1.2em',
        textAlign: 'center'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '@keyframes animBefore': {
        '0%': {
          top: -30,
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '25%': {
          transform: 'scale(1.2)',
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '50%': {
          top: 30,
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '75%': {
          backgroundColor: lightenLight,
          transform: 'scale(0.8)',
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '100%': {
          top: -30,
          zIndex: -1,
          rotationZ: '0.01deg'
        }
      },
      '@keyframes animAfter': {
        '0%': {
          top: 30,
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '25%': {
          backgroundColor: darkenDark,
          transform: 'scale(0.8)',
          zIndex: -1,
          rotationZ: '0.01deg'
        },
        '50%': {
          top: -30,
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '75%': {
          transform: 'scale(1.2)',
          zIndex: 1,
          rotationZ: '0.01deg'
        },
        '100%': {
          top: 30,
          zIndex: 1,
          rotationZ: '0.01deg'
        }
      },
      loadingText: {
        fontWeight: 400,
        fontSize: 'inherit',
        textAlign: 'center'
      }
    },
    span: {
      "&[class^='dot-']": {
        opacity: 0
      }
    },
    dotone: {
      animation: '$dot-one 2s infinite linear'
    },
    dottwo: {
      animation: '$dot-two 2s infinite linear'
    },
    dotthree: {
      animation: '$dot-three 2s infinite linear'
    },
    '@keyframes dot-one': {
      '0%': {
        opacity: 0
      },
      '15%': {
        opacity: 0
      },
      '25%': {
        opacity: 1
      },
      '100%': {
        opacity: 1
      }
    },
    '@keyframes dot-two': {
      '0%': {
        opacity: 0
      },
      '25%': {
        opacity: 0
      },
      '50%': {
        opacity: 1
      },
      '100%': {
        opacity: 1
      }
    },
    '@keyframes dot-three': {
      '0%': {
        opacity: 0
      },
      '50%': {
        opacity: 0
      },
      '75%': {
        opacity: 1
      },
      '100%': {
        opacity: 1
      }
    }
  };
};

export default styles;
