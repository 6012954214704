import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { FAQDetail } from '../../../types/organisation';
import { makeStyles } from '@material-ui/core/styles';
import Styles from './FAQItem.styles';
import Markdown from '../../Markdown/Markdown';

const useStyles = makeStyles(Styles);

const renderMarkdown = (markdown: string) => <Markdown>{markdown}</Markdown>;

const FAQItem: React.FC<{
  index: number;
  faqItem: FAQDetail;
  expanded: boolean;
  onChange: (any, string) => any;
}> = ({ index, faqItem, expanded, onChange }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={e => onChange(e, !expanded)}
      key={index}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="subtitle1"
          className={expanded ? classes.heading : undefined}
        >
          <div id={faqItem.faqTag}>{renderMarkdown(faqItem.question)}</div>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography variant="subtitle1">
          {renderMarkdown(faqItem.answer)}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default FAQItem;
