import React, { Fragment } from 'react';
import loader from '../../Loader/Loader';
import TitleCard from '../../Cards/TitleCard/index';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import TextField from '../../StyledTextField';
import { connect } from 'react-redux';
import { run } from '../../../validation/ruleRunner';
import { fieldValidations } from './validation';
import { api } from '../../../services/api/dataApi';

const styles = theme => Styles(theme);

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      resetClicked: false,
      newPasswordUrl: '',
      showErrors: false,
      validationErrors: []
    };
  }

  handleInputChange = field => {
    return e => {
      this.setState({ [field]: e.target.value }, () => {
        const validationErrors = run(this.state, fieldValidations);
        this.setState({ validationErrors });
      });
    };
  };

  handleReset = e => {
    const { resetPassword } = this.props;
    const { email, newPasswordUrl } = this.state;

    e.preventDefault();
    this.setState({ showErrors: true });
    if (Object.getOwnPropertyNames(this.state.validationErrors).length !== 0)
      return null;

    resetPassword({ email, newPasswordUrl });
    this.setState({ resetClicked: true, showErrors: false });
  };

  errorFor = field => {
    return this.state.validationErrors[field] || '';
  };

  componentDidMount() {
    // Grab location info for new password
    if (typeof window !== 'undefined') {
      const newPasswordUrl =
        window.location.protocol + '//' + window.location.host + '/newpassword';
      this.setState({ newPasswordUrl });
    }

    // Run validations on initial state
    this.setState({ validationErrors: run(this.state, fieldValidations) });
  }

  renderStart() {
    const { classes } = this.props;

    return (
      <form name="resetForm">
        <TitleCard
          cardTitle="Reset Password"
          cardSubtitle="Use this page to reset your password"
          content={
            <Fragment>
              <Typography variant="subtitle1">
                To reset your password, please enter your email below and click
                the reset button.
              </Typography>
              <TextField
                className={classes.input}
                id="address"
                label="Email"
                placeholder="Enter your email"
                margin="dense"
                onChange={this.handleInputChange('email')}
                error={this.errorFor('email') !== '' && this.state.showErrors}
                helperText={
                  this.state.showErrors ? this.errorFor('email') : null
                }
              />
            </Fragment>
          }
          footer={
            <div>
              <Button type="submit" onClick={this.handleReset}>
                Reset
              </Button>
            </div>
          }
        />
      </form>
    );
  }

  renderFinish() {
    return (
      <TitleCard
        cardTitle="Reset Password Email Sent"
        cardSubtitle="An email has been sent"
        content={
          <Typography variant="subtitle1">
            To continue the password reset process, please click on the link
            that has been sent to your email.
          </Typography>
        }
      />
    );
  }

  render() {
    const { resetClicked } = this.state;

    return resetClicked ? this.renderFinish() : this.renderStart();
  }
}

const mapDispatch = {
  resetPassword: api.endpoints.resetPassword.initiate
};

const styledPasswordReset = compose(
  loader,
  withStyles(styles, { withTheme: true }),
  connect(null, mapDispatch)
)(PasswordReset);

export { styledPasswordReset as PasswordReset };
