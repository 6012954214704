import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fieldEnd,
  fieldStart,
  getContextKey,
  getOrdinal
} from './unrefactored/fields';
import { config } from '../../helpers/config';
import { aliasedResponse } from './aliased-response-thunk';
import Immutable, { List } from 'immutable';
import { defaultPayload } from '../../helpers/redux';
import { relatedCancersTo } from './questionnaire-typed-thunks';
import { markdownListFromArray } from '../../helpers/markdown';

export const parseQuestion = createAsyncThunk(
  'newQuestionnaire/parseQuestion',
  /**  @param fieldInfo {FieldInfo}
   @param thunkAPI {GetThunkAPI<{}>}
   @return {Promise<string | undefined>} */
  async (fieldInfo, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    const sectionIndexOrdinal = 'SectionIndexOrdinal';
    const siteEmail = 'SiteEmail';
    const relatedCancers = 'RelatedCancersTo';
    const questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;

    const contexts = questionnaireState.contexts;
    const currentQuestion = questionnaire.questions.find(
      x => x.id === fieldInfo.questionId
    );
    const sectionContext = currentQuestion.section.repeatable
      ? contexts.get(
          getContextKey(fieldInfo.sectionId, fieldInfo.sectionInstanceId),
          new Immutable.Map()
        )
      : contexts;

    let questionText = currentQuestion.questionText;

    if (questionText.indexOf(fieldStart) === -1) {
      return questionText;
    }

    let remaining = questionText;
    let replaceValue = '',
      lookupValue = '',
      startIndex = 0,
      endIndex = -1;
    let aliasName = '',
      context = null;

    while (remaining.indexOf(fieldStart) !== -1) {
      startIndex = questionText.indexOf(fieldStart, startIndex);
      endIndex = questionText.indexOf(fieldEnd, startIndex);
      aliasName = questionText.substring(startIndex + 2, endIndex);
      replaceValue = `${fieldStart}${aliasName}${fieldEnd}`;
      context = sectionContext.get(aliasName, null);

      if (aliasName === sectionIndexOrdinal) {
        // If our loop count is one and our section index is one
        // Don't add in the ordinal
        const loopCount = sectionContext.get('LoopCount', 0);
        if (loopCount === 1 && fieldInfo.sectionIndex === 1) {
          lookupValue = '';
        } else {
          lookupValue = getOrdinal(fieldInfo.sectionIndex);
        }
      } else if (aliasName === siteEmail) {
        lookupValue = `<a href="mailto:${config.email}">${config.email}</a>`;
      } else if (aliasName.startsWith(relatedCancers)) {
        lookupValue = defaultPayload(
          await dispatch(
            relatedCancersTo({
              command: aliasName,
              currentFieldInfo: fieldInfo
            })
          )
        );
      } else if (context != null) {
        // Check for context collection
        const contextValue = context;
        lookupValue = List.isList(contextValue)
          ? markdownListFromArray(contextValue)
          : contextValue;
      } else {
        lookupValue = defaultPayload(
          await dispatch(aliasedResponse({ alias: aliasName, fieldInfo }))
        );
      }

      questionText = questionText.replace(replaceValue, lookupValue);
      endIndex = startIndex + replaceValue.length + 2;
      remaining = questionText.substring(
        startIndex + lookupValue.length,
        questionText.length
      );
    }

    // Finally replace any s's (doubled up s with apostrophes)
    // and doubled spaces which come from 1st ordinal index
    questionText = questionText.replace("s's", "s'").replace('  ', ' ');

    return questionText;
  }
);
