import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../services/api/dataApi';
import { HttpResponse } from '../../types/system';
import {
  Diagnosis,
  DraftResponse,
  FieldInfo,
  GlobalSupport,
  QuestionItem,
  Questionnaire,
  QuestionResponse,
  Section
} from '../../types/questionnaire';
import { getFieldInfo } from './unrefactored/fields';
import {
  QuestionnaireStatusConstants,
  SectionStatusConstants
} from '../../helpers/constants';
import Immutable from 'immutable';
import { parseContext } from './supporting-data-thunks';

const initialState: QuestionnaireState = {
  isLoading: false,
  isLoaded: false,
  showErrors: false,
  showConfirmDialog: false,
  consentAccepted: false,
  eulaAccepted: false,
  questionnaireStatus: QuestionnaireStatusConstants.NotStarted,
  questionnaireSubmitted: false,
  responses: Immutable.OrderedMap<string, QuestionResponse>(),
  draftResponses: Immutable.OrderedMap(),
  sectionStatuses: Immutable.Map(),
  validationResults: Immutable.Map(),
  contexts: Immutable.Map(),
  questionItems: []
};

export interface QuestionnaireState {
  questionnaire?: Questionnaire;
  globalSupport?: GlobalSupport[];
  userDob?: string;
  showErrors: boolean;
  showConfirmDialog: boolean;
  consentAccepted: boolean;
  eulaAccepted: boolean;
  questionnaireStatus: number;
  questionnaireSubmitted: boolean;
  currentFieldInfo?: FieldInfo;
  currentQuestionText?: string;
  currentDisplaySection?: Section;
  responses?: Immutable.OrderedMap<string, QuestionResponse>; // OrderedMap
  draftResponses?: Immutable.OrderedMap<string, DraftResponse>; // OrderedMap
  sectionStatuses?: Immutable.Map<string, unknown>; // Map
  validationResults?: Immutable.Map<string, unknown>; // Map
  contexts?: Immutable.Map<string, unknown>; // Map
  diagnoses?: Diagnosis[];
  isLoading: boolean;
  isLoaded: boolean;
  questionnaireLoadErrors?: string;
  questionItems?: Array<QuestionItem>;
}

const questionnaireSlice = createSlice({
  name: 'newQuestionnaire',
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    acceptConsent(state, action: PayloadAction<boolean>) {
      state.consentAccepted = action.payload;
    },
    acceptEula(state, action: PayloadAction<boolean>) {
      state.eulaAccepted = action.payload;
    },
    setQuestionText(state, action: PayloadAction<string>) {
      state.currentQuestionText = action.payload;
    },
    setQuestionItems(state, action: PayloadAction<Array<QuestionItem>>) {
      state.questionItems = action.payload;
    },
    setShowErrors(state, action: PayloadAction<boolean>) {
      state.showErrors = action.payload;
    },
    setCurrentFieldInfo(state, action: PayloadAction<FieldInfo>) {
      state.currentFieldInfo = action.payload;
    },
    setCurrentDisplaySection(state, action: PayloadAction<Section>) {
      state.currentDisplaySection = action.payload;
    },
    setQuestionnaireSubmitted(state, action: PayloadAction<boolean>) {
      state.questionnaireSubmitted = action.payload;
    },
    setDraftResponse(
      state,
      action: PayloadAction<{ fieldName: string; draftResponse: DraftResponse }>
    ) {
      state.draftResponses = state?.draftResponses?.set(
        action.payload.fieldName,
        action.payload.draftResponse
      );
    },
    setValidation(
      state,
      action: PayloadAction<{ field: string; validationResults: string[] }>
    ) {
      state.validationResults = state.validationResults?.set(
        action.payload.field,
        action.payload.validationResults
      );
    },
    setSectionStatus(
      state,
      action: PayloadAction<{ sectionId: string; status: number }>
    ) {
      state.sectionStatuses = state.sectionStatuses?.set(
        action.payload.sectionId,
        action.payload.status
      );
    },
    deleteSectionStatus(state, action: PayloadAction<string>) {
      state.sectionStatuses = state.sectionStatuses?.delete(action.payload);
    },
    setQuestionnaireStatus(state, action: PayloadAction<number>) {
      state.questionnaireStatus = action.payload;
    },
    setShowConfirmDialog(state, action: PayloadAction<boolean>) {
      state.showConfirmDialog = action.payload;
    },
    deleteResponse(state, action: PayloadAction<string>) {
      state.responses = state.responses?.delete(action.payload);
    },
    deleteDraftResponse(state, action: PayloadAction<string>) {
      state.draftResponses = state.draftResponses?.delete(action.payload);
    },

    setResponse(
      state,
      action: PayloadAction<{ fieldName: string; response: QuestionResponse }>
    ) {
      state.responses = state.responses?.set(
        action.payload.fieldName,
        action.payload.response
      );
    },
    setResponses(
      state,
      action: PayloadAction<Immutable.OrderedMap<string, QuestionResponse>>
    ) {
      state.responses = action.payload;
    },
    setContext(
      state,
      action: PayloadAction<{
        key: string | null;
        value: Record<string, unknown> | Immutable.Map<string, unknown>;
      }>
    ) {
      if (action.payload.key == null) {
        state.contexts = state.contexts?.merge(action.payload.value);
      } else {
        state.contexts = state.contexts?.mergeDeepIn(
          [action.payload.key],
          action.payload.value
        );
      }
    },
    restart(state) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        api.endpoints.getQuestionnaire.matchFulfilled,
        (state: QuestionnaireState, { payload }) => {
          const questionnaire = payload.questionnaire as Questionnaire;

          state.isLoading = true;
          state.isLoaded = false;
          state.questionnaire = payload.questionnaire;
          state.userDob = payload.dob;
          state.globalSupport = payload.globalSupport;
          state.diagnoses = payload.diagnoses;

          // Set section statuses to not started
          const defaultSectionStatuses = {};
          questionnaire.sections
            .filter(section => !section.repeatable)
            .forEach(
              x =>
                (defaultSectionStatuses[x.id.toString()] =
                  SectionStatusConstants.NotStarted)
            );

          const sectionStatusMap = Immutable.Map(defaultSectionStatuses);
          state.sectionStatuses = sectionStatusMap;

          const response = payload.responses;

          if (!response && questionnaire?.questions) {
            const firstQuestion = questionnaire.questions[0];
            const firstFieldInfo = getFieldInfo(
              firstQuestion.id,
              firstQuestion.section.id
            );
            state.currentFieldInfo = firstFieldInfo;

            const firstDisplaySection = questionnaire?.sections?.find(
              section => section.id === firstFieldInfo.sectionId
            );

            state.currentDisplaySection = firstDisplaySection;
          }

          if (response) {
            let parsedCurrentFieldInfo: FieldInfo | undefined;

            if (response.currentFieldInfo) {
              parsedCurrentFieldInfo = JSON.parse(response.currentFieldInfo);
              state.currentFieldInfo = parsedCurrentFieldInfo;
            }

            if (response?.responses) {
              const parsedResponses = JSON.parse(response?.responses);
              const responsesMap = Immutable.OrderedMap<
                string,
                QuestionResponse
              >(parsedResponses);

              state.responses = responsesMap;

              // Set up drafts responses map
              const draftResponsesJS = Immutable.fromJS(
                JSON.parse(response?.responses)
              );
              const draftResponses = draftResponsesJS.map(data => {
                return {
                  value: data.get('value', ''),
                  key: data.get('key', '')
                };
              });
              if (draftResponses != null) {
                state.draftResponses = draftResponses;
              }

              if (response?.responses && parsedCurrentFieldInfo) {
                const currentResponse = responsesMap.get(
                  parsedCurrentFieldInfo.fieldName
                ) as QuestionResponse;

                if (
                  currentResponse != null &&
                  currentResponse.displaySectionId != null
                ) {
                  const currentDisplaySection = questionnaire?.sections?.find(
                    section => section.id === currentResponse.displaySectionId
                  );
                  state.currentDisplaySection = currentDisplaySection;
                } else {
                  const lastResponseKey = responsesMap.keySeq().last();
                  const lastResponse = responsesMap.get(
                    lastResponseKey,
                    undefined
                  );
                  const lastSection = questionnaire.sections.find(
                    y => y.id === lastResponse.displaySectionId
                  );

                  if (lastResponse != null && lastSection != null) {
                    state.currentDisplaySection = lastSection;
                  }
                }
              }

              if (response?.contexts) {
                const contextsMap = Immutable.fromJS(
                  JSON.parse(response.contexts)
                );
                state.contexts = contextsMap;
              }

              if (response?.sectionStatuses) {
                const sectionStatusesMap = Immutable.fromJS(
                  JSON.parse(response.sectionStatuses)
                );
                state.sectionStatuses = sectionStatusesMap;
              }

              state.eulaAccepted = response.eulaAccepted;
              state.consentAccepted = response.consentAccepted;

              const questionnaireStatus =
                response.status || QuestionnaireStatusConstants.NotStarted;
              state.questionnaireStatus = questionnaireStatus;

              if (
                questionnaireStatus ===
                  QuestionnaireStatusConstants.Submitted ||
                questionnaireStatus === QuestionnaireStatusConstants.Processed
              ) {
                state.questionnaireSubmitted = true;
              }
            }
          }

          const filteredContext =
            payload.questionnaire?.questionnaireSupport?.filter(
              x => x.groupId == null
            );

          if (filteredContext && filteredContext.length > 0) {
            const questionnaireContext = Immutable.fromJS(
              parseContext(filteredContext)
            );
            state.contexts = state.contexts?.merge(questionnaireContext);
          }

          state.isLoading = false;
          state.isLoaded = true;
        }
      )
      .addMatcher(api.endpoints.getQuestionnaire.matchPending, state => {
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addMatcher(
        api.endpoints.getQuestionnaire.matchRejected,
        (state: QuestionnaireState, action) => {
          if (action.error && action.error.name !== 'ConditionError') {
            if (action.error) {
              const status = (action.payload as HttpResponse)?.status;
              console.log(`getQuestionnaire failed with: ${status}`);
            }
          }
        }
      );
  }
});

export const {
  logout,
  acceptEula,
  acceptConsent,
  setValidation,
  setQuestionText,
  setDraftResponse,
  setShowConfirmDialog,
  deleteResponse,
  deleteDraftResponse,
  deleteSectionStatus,
  setContext,
  setShowErrors,
  setCurrentFieldInfo,
  setCurrentDisplaySection,
  setResponse,
  setResponses,
  setSectionStatus,
  setQuestionnaireStatus,
  setQuestionItems,
  setQuestionnaireSubmitted,
  restart
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
