import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import Markdown from '../../Markdown/Markdown';
import loader from '../../Loader/Loader';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import ListItemText from '@material-ui/core/ListItemText';
import { List } from '@material-ui/core';

const styles = theme => Styles(theme);

class Documents extends React.Component {
  renderMarkdownDocumentText(markdown) {
    return <Markdown>{markdown}</Markdown>;
  }

  renderSGHSWTDocumentText() {
    const { classes } = this.props;
    const serviceCode = this.props.serviceCode?.toString().toLowerCase();

    return (
      <div className={classes.container}>
        <Typography variant="subtitle1" paragraph>
          1. How to order a death certificate for a relative –
          <List className={classes.bulletList}>
            <ListItem className={classes.compactBullet}>
              <ListItemIcon>
                <DotIcon color={'inherit'} fontSize={'inherit'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <a
                    href="https://www.gov.uk/order-copy-birth-death-marriage-certificate"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.gov.uk/order-copy-birth-death-marriage-certificate
                  </a>
                }
                className={classes.boldSubtitle}
              />
            </ListItem>
          </List>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          2. Consent forms –
          <List className={classes.bulletList}>
            <ListItem className={classes.compactBullet}>
              <ListItemIcon>
                <DotIcon color={'inherit'} fontSize={'inherit'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <a
                    href={
                      serviceCode
                        ? process.env.PUBLIC_URL +
                          `/docs/${serviceCode}/self-consent.pdf`
                        : process.env.PUBLIC_URL + `/docs/self-consent.pdf`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Consent form – to obtain your own healthcare information
                  </a>
                }
                className={classes.boldSubtitle}
              />
            </ListItem>
            <ListItem className={classes.compactBullet}>
              <ListItemIcon>
                <DotIcon color={'inherit'} fontSize={'inherit'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <a
                    href={
                      serviceCode
                        ? process.env.PUBLIC_URL +
                          `/docs/${serviceCode}/consent-deceased-relative.pdf`
                        : process.env.PUBLIC_URL +
                          `/docs/consent-deceased-relative.pdf`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Consent form – to obtain a deceased relatives healthcare
                    information
                  </a>
                }
                className={classes.boldSubtitle}
              />
            </ListItem>
            <ListItem className={classes.compactBullet}>
              <ListItemIcon>
                <DotIcon color={'inherit'} fontSize={'inherit'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <a
                    href={
                      serviceCode
                        ? process.env.PUBLIC_URL +
                          `/docs/${serviceCode}/self-consent-relative.pdf`
                        : process.env.PUBLIC_URL +
                          `/docs/self-consent-relative.pdf`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Consent form – to obtain a relatives healthcare information
                    (requires their signature)
                  </a>
                }
                className={classes.boldSubtitle}
              />
            </ListItem>
          </List>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          <a
            href={process.env.PUBLIC_URL + '/docs/consent-and-privacy.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            3. FHQS consent and privacy document
          </a>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          <a
            href={
              process.env.PUBLIC_URL +
              '/docs/Population-Risk-Patient-Information-Leaflet.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            4. Population risk leaflet
          </a>
        </Typography>
      </div>
    );
  }

  render() {
    const NavigationLink = React.forwardRef((props, ref) => {
      return <Link {...props} ref={ref} />;
    });

    NavigationLink.displayName = 'NavigationLink';

    const documentText = this.props.service?.serviceConfiguration?.documentText;

    return (
      <TitleCard
        cardTitle="Documents"
        cardSubtitle="Please read the following information"
        content={
          documentText
            ? this.renderMarkdownDocumentText(documentText)
            : this.renderSGHSWTDocumentText()
        }
      />
    );
  }
}

const mapState = state => {
  const {
    organisation,
    service,
    code: serviceCode,
    error
  } = state.organisation;

  const { loggedIn } = state.authentication;

  return {
    loggedIn,
    organisation,
    service,
    serviceCode,
    error
  };
};

Document.propTypes = {
  classes: PropTypes.object.isRequired
};

const styledSecurity = compose(
  loader,
  connect(mapState),
  withStyles(styles, { withTheme: true })
)(Documents);
export { styledSecurity as Documents };
