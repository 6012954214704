const styles = () => ({
  selected: {
    backgroundColor: '#000'
  },
  listItemsGutters: {
    paddingLeft: 20,
    paddingRight: 20
  }
});

export default styles;
