import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import QuestionCard from '../Cards/QuestionCard';

const styles = theme => Styles(theme);

class QuestionnaireComponent extends React.Component {
  render() {
    const {
      questionComponent,
      answerComponent,
      onNextClicked,
      onBackClicked,
      displaySection
    } = this.props;

    return (
      <QuestionCard
        cardTitle={displaySection.displayName}
        cardSubtitle={displaySection.topText}
        question={questionComponent}
        answer={answerComponent}
        navButtons
        onNextClicked={onNextClicked}
        onBackClicked={onBackClicked}
      />
    );
  }
}

const styledQuestionnaireComponent = withStyles(styles, { withTheme: true })(
  QuestionnaireComponent
);
export { styledQuestionnaireComponent as QuestionnaireComponent };
