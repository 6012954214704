import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import ListItemText from '@material-ui/core/ListItemText';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import { compose } from 'redux';
import Markdown from '../../Markdown/Markdown';
import { connect } from 'react-redux';

const styles = theme => Styles(theme);

class Consent extends React.Component {
  renderMarkdownConsent(markdown) {
    return <Markdown>{markdown}</Markdown>;
  }

  renderSGHSWTConsent() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography
          variant="subtitle1"
          paragraph
          className={classes.boldSubtitle}
        >
          Why do we need this data?
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Up to 1 in 2 people will get cancer in their lifetime. Most cancers
          happen when people get older and are not due to anything running in
          the family. However, sometimes we see more cancer in a family than we
          would expect by chance. Conditions which run in a family are usually
          caused by genetic changes. Our genetic code is the instruction manual
          for how to make a person and is present in every cell in our body.
          Genetic changes make us who we are. For example, genetic changes will
          decide whether our eyes will be blue or green or brown. Genetic
          changes tell our body how tall to grow.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          In some cases, a person can carry genetic changes which increase the
          chance of them getting cancer. For these people we can offer extra
          screening or other ways to make it less likely that they will get an
          untreatable cancer.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          To help us decide whether you have an increased chance of developing
          cancer in your lifetime, we use information about you and your
          relatives (family history) as a guide. We look at:
        </Typography>
        <List className={classes.bulletList}>
          <ListItem className={classes.compactBullet}>
            <ListItemIcon>
              <DotIcon color={'inherit'} fontSize={'inherit'} />
            </ListItemIcon>
            <ListItemText primary="The number of people who have developed cancer in your family" />
          </ListItem>
          <ListItem className={classes.compactBullet}>
            <ListItemIcon>
              <DotIcon color={'inherit'} fontSize={'inherit'} />
            </ListItemIcon>
            <ListItemText primary="The ages the cancers developed" />
          </ListItem>
          <ListItem className={classes.compactBullet}>
            <ListItemIcon>
              <DotIcon color={'inherit'} fontSize={'inherit'} />
            </ListItemIcon>
            <ListItemText primary="The types of cancer in the family" />
          </ListItem>
          <ListItem className={classes.compactBullet}>
            <ListItemIcon>
              <DotIcon color={'inherit'} fontSize={'inherit'} />
            </ListItemIcon>
            <ListItemText primary="How closely related the relatives with cancer are" />
          </ListItem>
        </List>
        <Typography variant="subtitle1" paragraph>
          This information helps us decide whether any genetic testing is
          needed, or, more commonly, whether any extra screening is needed for
          you and your relatives.
        </Typography>
        <Typography
          variant="subtitle1"
          paragraph
          className={classes.boldSubtitle}
        >
          How will your data be used?
        </Typography>
        <Typography variant="subtitle1" paragraph>
          A trained genetic counsellor, nurse, or doctor will review the family
          history information submitted to the FHQS application, which has been
          downloaded from the FHQS application and is held within their local
          clinical information system.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          They will use this information to decide if you or your relatives need
          any extra screening. This is known as a “risk assessment”. In most
          cases, you will then receive a letter telling you if you need any
          extra screening. If you do need additional screening the letter will
          tell you what this screening will be. In some instances, you may be
          offered an appointment in your local genetics clinic. You might be
          offered an appointment if more information is needed, or if you or a
          relative may be offered a genetic test.
        </Typography>
        <Typography
          variant="subtitle1"
          paragraph
          className={classes.boldSubtitle}
        >
          What information about my family will be stored?
        </Typography>
        <Typography variant="subtitle1" paragraph>
          The information provided will be stored temporarily in the FHQS
          application and longer term, as part of your health record, in your
          local clinical service information system. Information that could
          identify you will only be used to give you and/or your relatives
          advice on cancer risk. It will not be shared with anyone outside of
          the NHS. Information that does not identify you personally
          (anonymised), may be used for reporting or research for wider medical
          benefit now and in the future. Please ask us if you would like to know
          more. The data is stored so that if your information or your family
          information changes in the future, we can look at your information
          again to help us make a new risk assessment. The local clinical
          service systems are only accessed by trained health care workers.
        </Typography>
        <Typography
          variant="subtitle1"
          paragraph
          className={classes.boldSubtitle}
        >
          How long will your data be kept for?
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Your data will be held securely for up to 12 months in the FHQS
          application and longer term in your local clinical service systems
          which form part of your health record. This may be separate from your
          main health record and will only be accessible to trained health care
          workers.
        </Typography>
        <Typography
          variant="subtitle1"
          paragraph
          className={classes.boldSubtitle}
        >
          How can I ask for my data to be removed?
        </Typography>
        <Typography variant="subtitle1" paragraph>
          This data forms part of your health record and therefore is not
          subject to the right to erasure. You can apply to view your health
          record at any time by contacting the medical records department.
        </Typography>
        <Typography
          variant="subtitle1"
          paragraph
          className={classes.boldSubtitle}
        >
          Why do you need information about my relatives?
        </Typography>
        <Typography variant="subtitle1" paragraph>
          We need information about your relatives and their cancer diagnoses to
          make the risk assessment. You should tell your relatives that you are
          providing this information about them, but you can provide it
          anonymously if you wish. We will never contact your relatives, without
          seeking your consent first. If your relatives have died, knowing their
          names and date of birth can help us confirm their cancer diagnosis
          through cancer registries. If your relatives are alive, we may ask
          your relatives to sign a consent form to allow us to access their
          medical records. We will ask you to pass the consent forms on if this
          is the case. We will tell you the information we are gathering and
          why. Your information will only be used to give you and/or your
          relatives advice on cancer risk.
        </Typography>
      </div>
    );
  }

  render() {
    const { onAccepted } = this.props;
    const consent = this.props.service?.serviceConfiguration?.consent;

    return (
      <>
        <TitleCard
          cardTitle="Consent, Data protection and Data Privacy"
          cardSubtitle="Please read the following and click accept if you wish to continue"
          content={
            consent
              ? this.renderMarkdownConsent(consent)
              : this.renderSGHSWTConsent()
          }
          footer={<Button onClick={onAccepted}>Accept</Button>}
        />
        <ScrollToTopOnMount />
      </>
    );
  }
}

const mapState = state => {
  const { organisation, service, error } = state.organisation;

  const { loggedIn } = state.authentication;

  return {
    loggedIn,
    organisation,
    service,
    error
  };
};

Consent.propTypes = {
  classes: PropTypes.object.isRequired,
  onAccepted: Boolean
};

const styledConsent = compose(
  connect(mapState),
  withStyles(styles, { withTheme: true })
)(Consent);
export { styledConsent as Consent };
