import React, { Fragment } from 'react';
import loader from '../../Loader/Loader';
import TitleCard from '../../Cards/TitleCard/index';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import { Link, withRouter } from 'react-router-dom';
import qs from 'query-string';
import TextField from '../../StyledTextField';
import { connect } from 'react-redux';
import { run } from '../../../validation/ruleRunner';
import { fieldValidations } from './validation';
import { api } from '../../../services/api/dataApi';

const styles = theme => Styles(theme);

class ConfirmAccount extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const parsed = qs.parse(location.search);
    const { code, email } = parsed;

    this.state = {
      email: email || '',
      code: code || '',
      confirmClicked: false,
      confirmedUrl: '',
      showErrors: false,
      validationErrors: []
    };
  }

  handleInputChange = field => {
    return e => {
      this.setState({ [field]: e.target.value }, () => {
        const validationErrors = run(this.state, fieldValidations);
        this.setState({ validationErrors });
      });
    };
  };

  handleReset = e => {
    const { confirmAccount } = this.props;
    const { email, code, confirmedUrl } = this.state;

    e.preventDefault();
    this.setState({ showErrors: true });
    if (Object.getOwnPropertyNames(this.state.validationErrors).length !== 0)
      return null;

    confirmAccount({ email, code, confirmedUrl });
    this.setState({ confirmClicked: true, showErrors: false });
  };

  errorFor = field => {
    return this.state.validationErrors[field] || '';
  };

  componentDidMount() {
    const { location } = this.props;
    const parsed = qs.parse(location.search);
    const { code, email } = parsed;

    // If we don't have code and email in query string - we can't confirm email so push em back to root
    if (code == null || email == null) {
      history.push('/');
    }

    // Grab location info for new password
    if (typeof window !== 'undefined') {
      const confirmedUrl =
        window.location.protocol + '//' + window.location.host + '/confirmed';
      this.setState({ confirmedUrl });
    }

    // Run validations on initial state
    this.setState({ validationErrors: run(this.state, fieldValidations) });
  }

  renderStart() {
    const { classes } = this.props;

    return (
      <form name="activateForm">
        <TitleCard
          cardTitle="Activate Account"
          cardSubtitle="Activate your account"
          content={
            <Fragment>
              <Typography variant="subtitle1">
                Please click the button below to confirm your email and activate
                your account.
              </Typography>
              <TextField
                className={classes.input}
                id="email"
                label="Email"
                placeholder="Your account email"
                disabled
                value={
                  this.state.email
                    ? this.state.email.trim().replace(/\s/g, '')
                    : ''
                }
                margin="dense"
                onChange={this.handleInputChange('email')}
                error={this.errorFor('email') !== '' && this.state.showErrors}
                helperText={
                  this.state.showErrors ? this.errorFor('email') : null
                }
              />
            </Fragment>
          }
          footer={
            <div>
              <Button type="submit" onClick={this.handleReset}>
                Activate
              </Button>
            </div>
          }
        />
      </form>
    );
  }

  renderFinish() {
    return (
      <TitleCard
        cardTitle="Account Activated"
        cardSubtitle="Your account has been activated"
        content={
          <Typography variant="subtitle1">
            Your account has been activated. You can now sign in by clicking the
            button below and entering the email address and password you
            specified.
          </Typography>
        }
        footer={
          <Button
            component={({ ...props }) => <Link to="/signin" {...props} />}
          >
            Sign In
          </Button>
        }
      />
    );
  }

  render() {
    const { confirmClicked } = this.state;

    return confirmClicked ? this.renderFinish() : this.renderStart();
  }
}

const mapDispatch = {
  confirmAccount: api.endpoints.confirmAccount.initiate
};

const styledConfirmAccount = compose(
  loader,
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(null, mapDispatch)
)(ConfirmAccount);

export { styledConfirmAccount as ConfirmAccount };
