export const isString = obj => {
  return Object.prototype.toString.call(obj) === '[object String]';
};

export const isEmpty = obj => {
  return obj === undefined || obj === null
    ? true
    : typeof obj === 'object'
    ? Object.keys(obj).length === 0
    : false;
};

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const isObject = obj => {
  return (
    obj === Object(obj) &&
    Object.prototype.toString.call(obj) !== '[object Array]'
  );
};

export const isBoolean = value => {
  if (typeof value === 'string') {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case 'true':
    case false:
    case 'false':
      return true;
    default:
      return false;
  }
};

export const ukDateToISO = value => {
  const parts = value.split('/');
  let day = -1,
    month = -1,
    year = -1;

  if (parts.length === 3) {
    day = parseInt(parts[0], 10);
    month = parseInt(parts[1], 10);
    year = parseInt(parts[2], 10);
  }

  const dateString = `${year}-${padInt(month)}-${padInt(day)}T00:00:00Z`;
  const isoDate = new Date(dateString).toISOString();

  return isoDate;
};

export const ukYearOrDateToIso = value => {
  if (value == null) {
    return null;
  } else {
    value = value.toString();
  }

  const sep = '/';
  let day = 1,
    month = 1,
    year = -1;
  let isoDate = null;
  let dateString = null;

  if (value.indexOf(sep) !== -1) {
    const parts = value.split(sep);

    if (parts.length === 3) {
      day = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10);
      year = parseInt(parts[2], 10);
      dateString = `${year}-${padInt(month)}-${padInt(day)}T00:00:00Z`;
      isoDate = new Date(dateString).toISOString();
    }
  } else if (value.indexOf('T') !== -1) {
    isoDate = new Date(value).toISOString();
  } else if (value.indexOf('Z') !== -1) {
    isoDate = new Date(value);
  } else {
    year = parseInt(value, 10);
    dateString = `${year}-${padInt(month)}-${padInt(day)}T00:00:00Z`;
    isoDate = new Date(dateString).toISOString();
  }

  return isoDate;
};

export const padInt = (input, maxLength = 2) => {
  return input.toString().padStart(maxLength, '0');
};

export const coalesce = (...args) => {
  const len = args.length;
  for (let i = 0; i < len; i++) {
    if (args[i] !== null && args[i] !== undefined) {
      return args[i];
    }
  }
  return null;
};

export const thisYear = () => {
  return new Date().getFullYear();
};
