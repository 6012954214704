import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import publishSubscribe from 'publish-subscribe-js';
import Layout from '../components/Layout/Layout';
import { ErrorBoundary } from '../components/ErrorBoundary';
import autoLogout from '../components/AutoLogout/AutoLogout';
import { clearAlerts } from '../features/alerts-slice';

class App extends React.Component {
  constructor(props) {
    super(props);
    // history.listen(() => {
    //   // clear alert on location change
    //   props.clearAlerts();
    // });
  }

  componentDidMount() {
    //Add a single global document key up listener
    document.addEventListener('DOMContentLoaded', () => {
      document.onkeyup = e => {
        //publish a notification when pressed
        publishSubscribe.publish('DocumentKeyUp', e);
      };
    });
  }

  render() {
    return (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    );
  }
}

const mapDispatch = {
  clearAlerts: clearAlerts
};

const mapState = state => {
  const { alert } = state;
  return {
    alert
  };
};

const connectedApp = compose(autoLogout, connect(mapState, mapDispatch))(App);

export { connectedApp as App };
