import React from 'react';
import PropTypes from 'prop-types';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { compose } from 'redux';
import { connect } from 'react-redux';

// All the following keys are optional.
// We try our best to provide a great default value.
export const defaultTheme = {
  palette: {
    primary: {
      main: '#03a9f4',
      light: '#67daff',
      dark: '#007ac1',
      contrastText: '#fff'
    },
    secondary: {
      main: '#4caf50',
      light: '#80e27e',
      dark: '#087f23',
      contrastText: '#fff'
    },
    error: {
      main: '#f44336'
    },
    green: {
      main: '#4caf50'
    },
    titleCardHeaderLeft:{
      topRight: '#0000ff'
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  },
  typography: {
    useNextVariants: true,
    allVariants: {
      letterSpacing: 0
    }
  },
  privacyNotice:{
    link: '',
    linkTitle: '',
    message: 'This site uses cookies only to enhance user experience.',
    cookie: 'cookie-fhqs'
  },
  trustLogo: {
    imagedata: 'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAWMAAAAzBAMAAACwFXSRAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAADUExURf///6fEG8gAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAgSURBVGje7cExAQAAAMKg9U9tCj8gAAAAAAAAAADgrQYjqQABUkhsDAAAAABJRU5ErkJggg=='
  }
};

class Theme extends React.Component {
  render() {
    const { theme: customTheme } = this.props;

    const muiTheme = customTheme
      ? {
          ...defaultTheme,
          ...customTheme
        }
      : defaultTheme;

    const builtTheme = createMuiTheme(muiTheme);

    return (
      <MuiThemeProvider theme={builtTheme}>
        <CssBaseline />
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  const theme = state?.organisation?.theme;

  return {
    theme
  };
};

Theme.propTypes = {
  children: PropTypes.node
};

const composedTheme = compose(connect(mapStateToProps))(Theme);

export default composedTheme;
