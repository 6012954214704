import React from 'react';
import { connect } from 'react-redux';
import publishSubscribe from 'publish-subscribe-js';
import {
  DisplayTypeConstants,
  NavigationConstants
} from '../../helpers/constants';
import { isEmpty } from '../../helpers/types';
import {
  coerceTypes,
  emptyPair,
  getNextResponseFieldInfo
} from '../../features/questionnaire/unrefactored/fields';
import { QuestionnaireComponent } from './component';
import Question from './Question/Question';
import Answer from './Answer/Answer';
import Helix from '../Helix';
import TitleCard from '../Cards/TitleCard';
import DialogOkCancel from '../DialogOkCancel';
import { Consent } from '../Pages/Consent';
import { Eula } from '../Pages/EULA';
import { Redirect } from 'react-router-dom';
import { addAutoHidingAlert } from '../../features/alerts-slice';
import { api } from '../../services/api/dataApi';
import {
  acceptConsent,
  acceptEula,
  setDraftResponse,
  setShowConfirmDialog,
  setShowErrors
} from '../../features/questionnaire/questionnaire-slice';
import {
  setParsedQuestionText,
  validate as validateResponse
} from '../../features/questionnaire/questionnaire-typed-thunks';
import {
  checkBranchChanges,
  checkLoopSections,
  removeOrphanedResponses,
  removeResponsesBetween,
  synchroniseMappingOrder
} from '../../features/questionnaire/route-building-thunks';
import {
  saveResponsesToServer,
  setCoercedResponse,
  setDisplaySection,
  setQuestionAnswerItems,
  setQuestionContext,
  setQuestionnaireCompletionStatus,
  setSectionCompletionStatus
} from '../../features/questionnaire/questionnaire-thunks';
import { defaultPayload } from '../../helpers/redux';
import {
  nextQuestion,
  previousQuestion
} from '../../features/questionnaire/navigation-thunks';
import { history } from '../../helpers/history';
import { compose } from 'redux';

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  async componentDidMount() {
    const {
      loggedIn,
      user,
      serviceCode,
      getServiceCode,
      questionnaireLoaded,
      questionnaireLoading,
      getQuestionnaire
    } = this.props;
    const { isLoading } = this.state;

    if (!loggedIn) {
      history.push('/signout');
    }

    if (!serviceCode && user?.serviceCode) {
      await getServiceCode(user.serviceCode);
    }

    if (!questionnaireLoading && !questionnaireLoaded && serviceCode) {
      console.log('Loading questionnaire from questionnaire componentDidMount');
      await getQuestionnaire(serviceCode.questionnaireId, {
        subscribe: false,
        forceRefetch: true
      });
    }

    if (questionnaireLoaded && isLoading) {
      this.setState({ isLoading: false });
    }

    publishSubscribe.subscribe('DocumentKeyUp', e =>
      this.handleDocumentKeyUp(e)
    );
  }

  componentWillUnmount() {
    publishSubscribe.unsubscribe('DocumentKeyUp');
  }

  async componentDidUpdate(prevProps) {
    const {
      draftResponses,
      currentFieldInfo,
      questionnaireLoaded,
      questionnaireLoading,
      questionnaireLoadErrors,
      setParsedQuestionText,
      setQuestionAnswerItems,
      serviceCode,
      getQuestionnaire,
      addAutoHidingAlert,
      validateResponse,
      setShowErrors
    } = this.props;

    const { isLoading } = this.state;

    if (!questionnaireLoaded && !serviceCode) return;

    if (!questionnaireLoading && !questionnaireLoaded && serviceCode) {
      console.log(
        'Loading questionnaire from questionnaire componentDidUpdate'
      );
      await getQuestionnaire(serviceCode.questionnaireId, {
        subscribe: false,
        forceRefetch: true
      });
    }

    if (questionnaireLoaded && isLoading) {
      this.setState({ isLoading: false });
    }

    if (questionnaireLoadErrors) {
      addAutoHidingAlert({
        message: 'There was a problem loading your responses.',
        variant: 'error',
        autoDismiss: true
      });
      this.setState({ isLoading: false });
      return;
    }

    const prevFieldInfo = prevProps.currentFieldInfo;

    if (prevFieldInfo == null && currentFieldInfo != null) {
      await setParsedQuestionText(currentFieldInfo);
      await setQuestionAnswerItems(currentFieldInfo);

      const draftResponse = draftResponses.get(
        currentFieldInfo.fieldName,
        emptyPair
      );

      await validateResponse({
        fieldInfo: currentFieldInfo,
        draftResponse: draftResponse?.value
      });
    }

    if (
      currentFieldInfo?.fieldName &&
      prevProps?.currentFieldInfo?.fieldName &&
      currentFieldInfo?.fieldName !== prevProps?.currentFieldInfo?.fieldName
    ) {
      setShowErrors(false);

      const draftResponse = draftResponses.get(
        currentFieldInfo?.fieldName,
        emptyPair
      );

      await validateResponse({
        fieldInfo: currentFieldInfo,
        draftResponse: draftResponse?.value
      });
    }

    // Save location if question has changed
    if (
      currentFieldInfo != null &&
      prevFieldInfo != null &&
      (currentFieldInfo.questionId !== prevFieldInfo.questionId ||
        currentFieldInfo.sectionId !== prevFieldInfo.sectionId ||
        currentFieldInfo.sectionInstanceId !==
          prevFieldInfo.sectionInstanceId ||
        currentFieldInfo.sectionIndex !== prevFieldInfo.sectionIndex)
    ) {
      this.saveLocation();
    }
  }

  handleConsentAccepted = () => {
    const { acceptConsent } = this.props;
    acceptConsent(true);
  };

  handleEulaAccepted = () => {
    const { acceptEula } = this.props;
    acceptEula(true);
  };

  handleFieldChanged = (value, key) => {
    const { currentFieldInfo, setDraftResponse, validateResponse } = this.props;

    const draftResponse = { value: coerceTypes(value), key: coerceTypes(key) };
    setDraftResponse({ fieldName: currentFieldInfo.fieldName, draftResponse });
    validateResponse({ fieldInfo: currentFieldInfo, draftResponse: value });
  };

  handleKeyPressed = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const {
      currentFieldInfo,
      questionnaire,
      draftResponses,
      setDraftResponse,
      validateResponse
    } = this.props;

    const currentQuestion = questionnaire.questions.find(
      x => x.id === currentFieldInfo.questionId
    );
    const draftResponse = draftResponses.get(
      currentFieldInfo.fieldName,
      emptyPair
    );
    const keyCode = e.keyCode || e.charCode;
    const enterCode = 13;
    const deleteCode = 8;
    const backspaceCode = 46;

    if (
      keyCode === enterCode &&
      e.ctrlKey === true &&
      currentQuestion.answerType.displayType ===
        DisplayTypeConstants.FreeTextMultiline
    ) {
      draftResponse.value = `${draftResponse.value}\r\n`;
      setDraftResponse({
        fieldName: currentFieldInfo.fieldName,
        draftResponse
      });
      validateResponse({
        fieldInfo: currentFieldInfo,
        draftResponse: draftResponse?.value
      });
    } else {
      if (
        keyCode === enterCode &&
        (currentQuestion.answerType.displayType ===
          DisplayTypeConstants.FreeText ||
          currentQuestion.answerType.displayType ===
            DisplayTypeConstants.EmailText ||
          currentQuestion.answerType.displayType ===
            DisplayTypeConstants.DateYearText ||
          currentQuestion.answerType.displayType ===
            DisplayTypeConstants.NumericText ||
          currentQuestion.answerType.displayType ===
            DisplayTypeConstants.DropDownTypeAhead ||
          currentQuestion.answerType.displayType ===
            DisplayTypeConstants.FreeTextMultiline)
      ) {
        if (
          currentQuestion.answerType.displayType ===
          DisplayTypeConstants.FreeTextMultiline
        ) {
          e.preventDefault();
        }
        validateResponse({
          fieldInfo: currentFieldInfo,
          draftResponse: draftResponse.value
        });
        this.handleNavigate(true);
      } else if (
        (keyCode === deleteCode || keyCode === backspaceCode) &&
        currentQuestion.answerType.displayType ===
          DisplayTypeConstants.DropDownTypeAhead
      ) {
        setDraftResponse({
          fieldName: currentFieldInfo.fieldName,
          draftResponse: emptyPair
        });
        validateResponse({ fieldInfo: currentFieldInfo, draftResponse: '' });
      }
    }
  };

  handleDocumentKeyUp = async e => {
    const {
      currentFieldInfo,
      questionnaire,
      draftResponses,
      validateResponse
    } = this.props;

    if (questionnaire == null || questionnaire.questions == null) return;

    const currentQuestion = questionnaire.questions.find(
      x => x.id === currentFieldInfo.questionId
    );

    if (e.key === 'Enter') {
      if (
        currentQuestion.answerType.displayType ===
        DisplayTypeConstants.DropDownSelect
      ) {
        const draftResponse = draftResponses.get(
          currentFieldInfo.fieldName,
          emptyPair
        );

        await validateResponse({
          fieldInfo: currentFieldInfo,
          draftResponse: draftResponse.value
        });

        this.handleNavigate(true);
      } else if (
        currentQuestion.answerType.displayType === DisplayTypeConstants.NoAnswer
      ) {
        this.handleNavigate(true);
      }
    }
  };

  handleDialogOK = async () => {
    const {
      currentFieldInfo,
      draftResponses,
      setShowConfirmDialog,
      checkLoopSections
    } = this.props;

    const draftResponse = draftResponses.get(
      currentFieldInfo.fieldName,
      emptyPair
    );

    setShowConfirmDialog(false);
    await checkLoopSections({ fieldInfo: currentFieldInfo, draftResponse });

    this.saveAndContinue(true);
  };

  handleDialogCancel = () => {
    const { setShowConfirmDialog } = this.props;
    setShowConfirmDialog(false);
  };

  handleNavigate = async (forwards = true) => {
    const {
      currentFieldInfo,
      validationResults,
      draftResponses,
      setQuestionContext,
      checkBranchChanges,
      setShowConfirmDialog,
      setShowErrors,
      previousQuestion,
      setParsedQuestionText,
      setQuestionAnswerItems,
      setDisplaySection,
      validateResponse
    } = this.props;

    const draftResponse = draftResponses.get(
      currentFieldInfo.fieldName,
      emptyPair
    );

    const validationResult = validationResults.get(
      currentFieldInfo.fieldName,
      {}
    );

    if (forwards) {
      if (isEmpty(validationResult)) {
        await setQuestionContext(currentFieldInfo);

        const confirmationRequired = defaultPayload(
          await checkBranchChanges({
            currentFieldInfo,
            response: draftResponse
          })
        );

        if (confirmationRequired) {
          // Ask for confirmation to wipe out branch changes
          setShowConfirmDialog(true);
        } else {
          this.saveAndContinue();
        }
      } else {
        setShowErrors(true);
      }
    } else {
      const prevFieldInfo = defaultPayload(
        await previousQuestion(currentFieldInfo)
      );

      const prevResponse = draftResponses.get(
        prevFieldInfo.fieldName,
        emptyPair
      );

      await setParsedQuestionText(prevFieldInfo);

      await setQuestionAnswerItems(prevFieldInfo);

      await setDisplaySection({
        currentFieldInfo: prevFieldInfo,
        previousFieldInfo: currentFieldInfo
      });

      await validateResponse({
        fieldInfo: prevFieldInfo,
        draftResponse: prevResponse.value
      });

      setShowErrors(false);
    }
  };

  saveAndContinue = async () => {
    const {
      currentFieldInfo,
      currentDisplaySection,
      currentQuestionText,
      setQuestionAnswerItems,
      draftResponses,
      responses,
      setCoercedResponse,
      nextQuestion,
      setDisplaySection,
      removeOrphanedResponses,
      removeResponsesBetween,
      setParsedQuestionText,
      synchroniseMappingOrder,
      setSectionCompletionStatus,
      setQuestionnaireCompletionStatus,
      validateResponse,
      setShowErrors,
      saveResponsesToServer
    } = this.props;

    const draftResponse = draftResponses.get(
      currentFieldInfo.fieldName,
      emptyPair
    );

    const currentResponse = responses.get(currentFieldInfo.fieldName, null);

    const responseChanged = draftResponse.value !== currentResponse?.value;

    const oldNextFieldInfo = getNextResponseFieldInfo(
      currentFieldInfo,
      responses
    );
    const { value, key } = draftResponse;

    await setCoercedResponse({
      fieldInfo: currentFieldInfo,
      value,
      key,
      questionText: currentQuestionText,
      displaySectionId: currentDisplaySection.id
    });

    const nextFieldInfo = defaultPayload(await nextQuestion(currentFieldInfo));

    let fieldWasInserted = false;

    if (nextFieldInfo !== NavigationConstants.END_QUESTIONNAIRE) {
      await removeResponsesBetween({
        startFieldInfo: currentFieldInfo,
        newNextFieldInfo: nextFieldInfo,
        oldNextFieldInfo
      });

      fieldWasInserted = defaultPayload(
        await synchroniseMappingOrder({ currentFieldInfo, nextFieldInfo })
      );

      await removeOrphanedResponses({ nextFieldInfo });
      await setParsedQuestionText(nextFieldInfo);
      await setQuestionAnswerItems(nextFieldInfo);
      await setDisplaySection({
        currentFieldInfo: nextFieldInfo,
        previousFieldInfo: currentFieldInfo
      });
    } else {
      await removeOrphanedResponses({ nextFieldInfo, currentFieldInfo });
    }

    await setSectionCompletionStatus({
      currentFieldInfo,
      nextFieldInfo,
      fieldWasInserted
    });

    await setQuestionnaireCompletionStatus();

    try {
      responseChanged && (await saveResponsesToServer());
    } catch (ex) {
      console.log('Save responses error');
    }

    if (nextFieldInfo !== NavigationConstants.END_QUESTIONNAIRE) {
      const nextDraftResponse = draftResponses.get(
        nextFieldInfo.fieldName,
        emptyPair
      );

      await validateResponse({
        fieldInfo: nextFieldInfo,
        draftResponse: nextDraftResponse.value
      });

      setShowErrors(false);
    }
  };

  async saveLocation() {
    const { saveLocation, currentFieldInfo, questionnaireId } = this.props;

    const request = {
      questionnaireId,
      currentFieldInfo: JSON.stringify(currentFieldInfo)
    };

    await saveLocation(request);
  }

  renderQuestion() {
    const {
      showErrors,
      showConfirmDialog,
      currentFieldInfo,
      currentQuestionText,
      currentDisplaySection,
      validationResults,
      draftResponses,
      questionnaire,
      questionItems
    } = this.props;

    const currentQuestion =
      currentFieldInfo != null
        ? questionnaire.questions.find(
            x => x.id === currentFieldInfo.questionId
          )
        : null;

    const questionComponent = (
      <Question
        questionText={currentQuestionText}
        hasMarkdown={currentQuestion?.hasMarkdown || false}
      />
    );

    const draftResponse = draftResponses.get(
      currentFieldInfo.fieldName,
      emptyPair
    );
    const validationResult = validationResults.get(
      currentFieldInfo.fieldName,
      {}
    );

    const answerComponent = (
      <Answer
        currentFieldInfo={currentFieldInfo}
        currentQuestion={currentQuestion}
        showErrors={showErrors}
        currentValue={draftResponse}
        onFieldChanged={(e, key) => {
          this.handleFieldChanged(e, key);
        }}
        onKeyPress={e => {
          this.handleKeyPressed(e);
        }}
        validationResults={validationResult}
        items={questionItems}
      />
    );

    const questionnaireComponent = (
      <div>
        <QuestionnaireComponent
          question={currentQuestion}
          showErrors={showErrors}
          questionComponent={questionComponent}
          answerComponent={answerComponent}
          displaySection={currentDisplaySection}
          onNextClicked={() => this.handleNavigate(true)}
          onBackClicked={() => this.handleNavigate(false)}
        />
        <DialogOkCancel
          text="This change may result in the loss of responses already entered in this section. Are you sure you wish to continue?"
          title="Confirmation Required"
          handleOK={this.handleDialogOK}
          handleCancel={this.handleDialogCancel}
          open={showConfirmDialog}
          fullScreen={false}
        />
      </div>
    );

    return questionnaireComponent;
  }

  static renderHelix() {
    return (
      <TitleCard
        cardTitle="Loading Questionnaire"
        cardSubtitle="Building questionnaire and response data set"
        content={<Helix />}
      />
    );
  }

  render() {
    const { consentAccepted, eulaAccepted, questionnaireSubmitted } =
      this.props;
    const { isLoading } = this.state;

    return isLoading ? (
      Questionnaire.renderHelix()
    ) : questionnaireSubmitted ? (
      <Redirect to="/thanks" />
    ) : !eulaAccepted ? (
      <Eula onAccepted={this.handleEulaAccepted} />
    ) : !consentAccepted ? (
      <Consent onAccepted={this.handleConsentAccepted} />
    ) : (
      this.renderQuestion()
    );
  }
}

const mapDispatch = {
  getQuestionnaire: api.endpoints.getQuestionnaire.initiate,
  getServiceCode: api.endpoints.getServiceCode.initiate,
  saveLocation: api.endpoints.saveLocation.initiate,
  acceptConsent,
  acceptEula,
  setDraftResponse,
  validateResponse,
  setShowConfirmDialog,
  setShowErrors,
  setParsedQuestionText,
  addAutoHidingAlert,
  saveResponsesToServer,
  checkLoopSections,
  setQuestionContext,
  checkBranchChanges,
  previousQuestion,
  nextQuestion,
  setDisplaySection,
  setCoercedResponse,
  removeResponsesBetween,
  synchroniseMappingOrder,
  removeOrphanedResponses,
  setSectionCompletionStatus,
  setQuestionnaireCompletionStatus,
  setQuestionAnswerItems
};

const mapState = state => {
  const { user, loggedIn } = state.authentication;
  const { serviceCode } = state.organisation;

  const {
    isLoading: questionnaireLoading,
    isLoaded: questionnaireLoaded,
    questionnaireLoadErrors,
    questionnaire,
    responses,
    contexts,
    sectionStatuses,
    consentAccepted,
    eulaAccepted,
    currentFieldInfo,
    currentDisplaySection,
    currentQuestionText,
    globalSupport,
    showErrors,
    showConfirmDialog,
    draftResponses,
    validationResults,
    questionnaireSubmitted,
    questionnaireStatus,
    questionItems
  } = state.newQuestionnaire;

  return {
    user,
    loggedIn,
    serviceCode,
    questionnaireLoading,
    questionnaireLoaded,
    questionnaireLoadErrors,
    questionnaire,
    responses,
    contexts,
    sectionStatuses,
    consentAccepted,
    eulaAccepted,
    currentFieldInfo,
    currentDisplaySection,
    currentQuestionText,
    globalSupport,
    showErrors,
    showConfirmDialog,
    draftResponses,
    validationResults,
    questionnaireSubmitted,
    questionnaireStatus,
    questionItems
  };
};

const composedQuestionnaire = compose(connect(mapState, mapDispatch))(
  Questionnaire
);

export { composedQuestionnaire as Questionnaire };
