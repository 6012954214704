import React from 'react';
import loader from '../../Loader/Loader';
import TitleCard from '../../Cards/TitleCard';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import TextField from '../../StyledTextField';
import { Link, withRouter } from 'react-router-dom';
import qs from 'query-string';
import { run } from '../../../validation/ruleRunner';
import { fieldValidations } from './validation';
import { connect } from 'react-redux';
import Helix from '../../Helix';
import { history } from '../../../helpers/history';
import { api } from '../../../services/api/dataApi';
import { addAutoHidingAlert } from '../../../features/alerts-slice';

const styles = theme => Styles(theme);

class NewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      showErrors: false,
      validationErrors: []
    };
  }

  handleInputChange = field => {
    return e => {
      this.setState({ [field]: e.target.value }, () => {
        const validationErrors = run(this.state, fieldValidations);
        this.setState({ validationErrors });
      });
    };
  };

  handleSubmit = e => {
    const { newPassword: newPasswordValue } = this.state;
    const { location, newPassword } = this.props;
    const parsed = qs.parse(location.search);
    const { code, email } = parsed;

    e.preventDefault();

    this.setState(
      { validationErrors: run(this.state, fieldValidations) },
      () => {
        this.setState({ showErrors: true });
        if (
          Object.getOwnPropertyNames(this.state.validationErrors).length !== 0
        )
          return;

        newPassword({ email, code, newPassword: newPasswordValue });
      }
    );
  };

  errorFor = field => {
    return this.state.validationErrors[field] || '';
  };

  componentDidMount() {
    const { location } = this.props;
    const parsed = qs.parse(location.search);
    const { code, email } = parsed;

    // If we don't have code and email in query string - we can't create new password so push em back to root
    if (code == null || email == null) {
      history.push('/');
    }

    // Run validations on initial state
    this.setState({ validationErrors: run(this.state, fieldValidations) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { passwordChangeErrors, alert } = this.props;

      if (passwordChangeErrors) {
        alert({
          message: passwordChangeErrors?.errors?.title || 'Server error',
          variant: 'error',
          autoDismiss: true
        });
        alert({
          message: 'Please try again',
          variant: 'info',
          autoDismiss: true
        });
      }
    }
  }

  renderForm = () => {
    const { classes } = this.props;

    return (
      <form name="newPassword">
        <TitleCard
          cardTitle="New Password"
          cardSubtitle="Create your password here"
          content={
            <div className={classes.inputContainer}>
              <Typography variant="subtitle1">
                Please provide new password below
              </Typography>
              <TextField
                className={classes.input}
                id="newPassword"
                label="New Password"
                placeholder="Enter your new password"
                margin="dense"
                onChange={this.handleInputChange('newPassword')}
                type="password"
                error={
                  this.errorFor('newPassword') !== '' && this.state.showErrors
                }
                helperText={
                  this.state.showErrors ? this.errorFor('newPassword') : null
                }
              />
              <TextField
                className={classes.input}
                id="confirmPassword"
                label="Confirm Password"
                placeholder="Enter your new password again"
                margin="dense"
                onChange={this.handleInputChange('confirmPassword')}
                type="password"
                error={
                  this.errorFor('confirmPassword') !== '' &&
                  this.state.showErrors
                }
                helperText={
                  this.state.showErrors
                    ? this.errorFor('confirmPassword')
                    : null
                }
              />
            </div>
          }
          footer={
            <Button onClick={this.handleSubmit} type="submit">
              Submit
            </Button>
          }
        />
      </form>
    );
  };

  renderFinish = () => {
    return (
      <TitleCard
        cardTitle="Password Changed"
        cardSubtitle="Your password has been changed"
        content={
          <Typography variant="subtitle1">
            Your password has changed. Please sign in to continue
          </Typography>
        }
        footer={
          <Button
            component={({ ...props }) => <Link to="/signin" {...props} />}
          >
            Sign In
          </Button>
        }
      />
    );
  };

  static renderHelix() {
    return (
      <TitleCard
        cardTitle="Changing Password"
        cardSubtitle="Password change in progress"
        content={<Helix />}
      />
    );
  }

  render() {
    const { passwordChanged, passwordChanging } = this.props;

    return passwordChanged
      ? this.renderFinish()
      : passwordChanging
      ? NewPassword.renderHelix()
      : this.renderForm();
  }
}

const mapDispatch = {
  newPassword: api.endpoints.newPassword.initiate,
  alert: addAutoHidingAlert
};

const mapState = state => {
  const { passwordChanged, passwordChanging, passwordChangeErrors } =
    state.newPassword;

  return {
    passwordChanged,
    passwordChanging,
    passwordChangeErrors
  };
};

const styledNewPassword = compose(
  loader,
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapState, mapDispatch)
)(NewPassword);

export { styledNewPassword as NewPassword };
