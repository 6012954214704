const dev = {
  api: '/api',
  root: ''
};

const prod = {
  api: '/api',
  root: window.location.protocol + '//' + window.location.host
};

// eslint-disable-next-line no-undef
const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  SITE_EMAIL: 'cancer.genetics@stgeorges.nhs.uk',
  SITE_SURVEY_LINK: 'https://forms.office.com/r/sSpUDNRK5u',
  SITE_PHONE: '07787 843070',
  ...config
};
