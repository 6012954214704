import Immutable from 'immutable';

export const insertAfter = (map, keyAfter, key, value) => {
  return Immutable.OrderedMap().withMutations(r => {
    if (keyAfter == null) {
      r.set(key, value);
    }

    for (let [k, v] of map.entries()) {
      if (k !== key) {
        r.set(k, v);
      }

      if (keyAfter != null && keyAfter === k) {
        r.set(key, value);
      }
    }
  });
};
