import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import PropTypes from 'prop-types';
import NotificationContent from '../NotificationContent/NotificationContent';

const styles = theme => Styles(theme);

class Notification extends React.Component {
  render() {
    const { open, key, handleClose, handleExited, message, variant } =
      this.props;

    return (
      <Snackbar
        key={key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        onExited={handleExited}
      >
        <NotificationContent
          onClose={handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    );
  }
}

Notification.propTypes = {
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  key: PropTypes.any,
  message: PropTypes.string,
  handleClose: PropTypes.func,
  handleExited: PropTypes.func
};

export default withStyles(styles)(Notification);
