const styles = () => ({
  root: {
    width: '100%'
  },
  heading: {
    fontWeight: 500,
    flexBasis: '90%',
    flexShrink: 0
  },
  withBreaks: {
    whiteSpace: 'pre-line'
  },
  panelRows: {
    display: 'flex',
    flexDirection: 'column'
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  boldSubtitle: {
    fontWeight: 500
  },
  compactBullet: {
    fontSize: '12px',
    paddingTop: 0,
    paddingBottom: 0
  },
  bulletList: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16
  }
});

export default styles;
