import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import SectionLink from './SectionLink/SectionLink';
import {
  QuestionnaireStatusConstants,
  SectionStatusConstants
} from '../../../helpers/constants';
import LinkListItem from '../../Layout/SideBar/ListItemLink';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { relativeBuilder } from '../../../features/questionnaire/unrefactored/relativeBuilder';
import { getRelativeSections } from '../../../helpers/sections';

const styles = theme => Styles(theme);

class SectionMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null
    };
  }

  handleDrawerToggle = () => {
    const { onDrawerToggle } = this.props;

    if (onDrawerToggle != null) {
      onDrawerToggle();
    }
  };

  handleSectionToggle = (e, sectionId) => {
    let { expanded } = this.state;

    if (expanded === sectionId) {
      expanded = null;
    } else {
      expanded = sectionId;
    }

    this.setState({ expanded: expanded });
  };

  renderMenu = () => {
    const {
      sectionStatuses,
      questionnaire,
      classes,
      responses,
      currentDisplaySection
    } = this.props;

    if (questionnaire == null) {
      return null;
    }

    const relativeSections = getRelativeSections(questionnaire.sections);

    const isScreening = currentDisplaySection.isScreening;

    const shownSections = questionnaire.sections.filter(
      section =>
        section.repeatable === false && section.isScreening === isScreening
    );

    return shownSections.map(section => {
      const sectionCompletedStatus = sectionStatuses.get(
        section.id.toString(),
        SectionStatusConstants.NotStarted
      );
      const sectionComplete =
        sectionCompletedStatus === SectionStatusConstants.Complete;
      const sectionResponses = responses.filter(
        x => x.sectionId === section.id
      );
      const sectionStarted =
        sectionResponses != null && sectionResponses.size > 0;

      const relatives = relativeSections.map(s => s.id).includes(section.id)
        ? relativeBuilder.build(section.id)
        : [];

      const expanded =
        section.id === this.state.expanded ||
        currentDisplaySection.id === section.id;

      return (
        <SectionLink
          expanded={expanded}
          text={section.displayName}
          key={section.id}
          sequence={section.sequence}
          sectionId={section.id}
          relatives={relatives}
          activeClassName={classes.selectedLight}
          onDrawerToggle={this.handleDrawerToggle}
          onSectionToggle={e => {
            this.handleSectionToggle(e, section.id);
          }}
          started={sectionStarted}
          completed={sectionComplete}
          selected={
            currentDisplaySection != null
              ? currentDisplaySection.id === section.id
              : false
          }
        />
      );
    });
  };

  render() {
    const {
      questionnaireStatus,
      sectionStatuses,
      classes,
      isLoading,
      isLoaded
    } = this.props;

    const canSubmit =
      sectionStatuses != null &&
      sectionStatuses.size > 0 &&
      sectionStatuses
        .valueSeq()
        .filter(x => x !== SectionStatusConstants.Complete)
        .count() === 0;
    const submitted =
      questionnaireStatus === QuestionnaireStatusConstants.Submitted ||
      questionnaireStatus === QuestionnaireStatusConstants.Processed;

    return (
      <Fragment>
        <Divider />
        {!submitted && !isLoading ? (
          <Fragment>
            <LinkListItem
              icon={<PlayCircleOutlineIcon />}
              primary="Start"
              to="/start"
              activeClassName={classes.selected}
              onClick={this.handleDrawerToggle}
              private
            />
            <Divider />
          </Fragment>
        ) : null}
        {isLoaded && !submitted ? (
          <Fragment>
            <div className={classes.sectionWrapper}>{this.renderMenu()}</div>
            <LinkListItem
              icon={<CloudDoneIcon />}
              primary="Submit"
              to="/completed"
              disabled={!canSubmit}
              activeClassName={classes.selected}
              onClick={this.handleDrawerToggle}
              private
            />
          </Fragment>
        ) : null}
        {submitted ? (
          <LinkListItem
            icon={<PlayCircleOutlineIcon />}
            primary="Thanks"
            to="/thanks"
            activeClassName={classes.selected}
            onClick={this.handleDrawerToggle}
            private
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    questionnaire,
    isLoading,
    isLoaded,
    questionnaireStatus,
    sectionStatuses,
    responses,
    currentDisplaySection
  } = state.newQuestionnaire;

  const { loggedIn } = state.authentication;

  return {
    questionnaire,
    isLoading,
    isLoaded,
    questionnaireStatus,
    sectionStatuses,
    responses,
    currentDisplaySection,
    loggedIn
  };
};

const connectedSectionMenu = compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(SectionMenu);

export { connectedSectionMenu as SectionMenu };
