import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import loader from '../../Loader/Loader';
import { history } from '../../../helpers/history';
import Typography from '@material-ui/core/Typography/Typography';

const styles = theme => Styles(theme);

class Confirmed extends React.Component {
  onButtonClick = () => {
    history.push('/signin');
  };

  render() {
    const { classes } = this.props;

    return (
      <TitleCard
        cardTitle="Email Confirmed"
        cardSubtitle="Your email address has been confirmed"
        content={
          <div className={classes.container}>
            <Typography variant="subtitle1">
              {
                'Thank you for confirming your email address. You can now sign in by clicking the button below and entering the email address and password you specified.'
              }
            </Typography>
          </div>
        }
        footer={<Button onClick={this.onButtonClick}>Sign In</Button>}
      />
    );
  }
}

const styledConfirmed = compose(
  loader,
  withStyles(styles, { withTheme: true })
)(Confirmed);

export { styledConfirmed as Confirmed };
