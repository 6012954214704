import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  listItem: {
    marginTop: theme.spacing(1)
  },
  compactBullet: {
    fontSize: '12px',
    paddingTop: 0,
    paddingBottom: 0
  },
  bulletList: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16
  },
  listItemIconRoot: {
    minWidth: 32
  },
  gentleBold: {
    fontWeight: 500
  }
});

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5'
      }
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'subtitle1' }
    },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true }
    },
    p: {
      component: Typography,
      props: { paragraph: true, gutterBottom: true, variant: 'subtitle1' }
    },
    strong: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <Typography
          variant={'subtitle1'}
          component="span"
          classes={{ subtitle1: classes.gentleBold }}
          {...props}
        />
      ))
    },
    a: { component: Link },
    ul: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <List className={classes.bulletList}>
          <Typography component="span" {...props} />
        </List>
      ))
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <ListItem className={classes.compactBullet}>
          <ListItemIcon
            classes={{
              root: classes.listItemIconRoot
            }}
          >
            <DotIcon color={'inherit'} fontSize={'inherit'} />
          </ListItemIcon>
          <ListItemText primary={props.children} />
        </ListItem>
      ))
    }
  }
};

export const Markdown = props => <ReactMarkdown options={options} {...props} />;

export default Markdown;
