import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TitleCard from '../../Cards/TitleCard';
import Helix from '../../Helix';
import { run } from '../../../validation/ruleRunner';
import { fieldValidations } from './validation';
import TextField from '../../StyledTextField';
import DropDownSelect from '../../DropDownSelect/DropDownSelect';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import Typography from '@material-ui/core/Typography/Typography';
import { compose } from 'redux';
import { api } from '../../../services/api/dataApi';
import moment from 'moment';
import { config } from '../../../helpers/config';
import Markdown from '../../Markdown/Markdown';

const styles = theme => Styles(theme);

class Register extends React.Component {
  constructor(props) {
    super(props);
    const { service } = this.props;
    const referringTrusts = service?.referringTrusts;

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      contactPreference: 'Email',
      dateOfBirth: '',
      nhsNo: '',
      serviceCode: props.serviceCode.toUpperCase() || '',
      referringTrust:
        referringTrusts && referringTrusts.length === 1
          ? referringTrusts[0].referringTrustName
          : '',
      addressLine1: '',
      addressLine2: '',
      town: '',
      country: '',
      postcode: '',
      password: '',
      confirmPassword: '',
      confirmedUrl: '',
      submitted: false,
      showErrors: false,
      validationErrors: []
    };
  }

  componentDidMount() {
    // Run validations on initial state
    this.setState({ validationErrors: run(this.state, fieldValidations) });
    // Grab location info for confirmation url
    if (typeof window !== 'undefined') {
      const confirmedUrl =
        window.location.protocol + '//' + window.location.host + '/confirmed';
      this.setState({ confirmedUrl, serviceCode: this.props.serviceCode });
    }
  }

  errorFor = field => {
    return this.state.validationErrors[field] || '';
  };

  handleCutCopyPaste = () => {
    return e => {
      e.preventDefault();
    };
  };

  handleFieldChanged = field => {
    return e => {
      this.setState({ [field]: e.target.value }, () => {
        const validationErrors = run(this.state, fieldValidations);
        this.setState({ validationErrors });
      });
    };
  };

  handleSelectFieldChanged = field => {
    return e => {
      this.setState({ [field]: e }, () => {
        const validationErrors = run(this.state, fieldValidations);
        this.setState({ validationErrors });
      });
    };
  };

  handleSubmitClicked = e => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (Object.getOwnPropertyNames(this.state.validationErrors).length !== 0)
      return null;

    this.setState({ submitted: true });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { submitted, showErrors, validationErrors, ...user } = this.state;
    const { register, service } = this.props;
    const { serviceCode } = this.state;

    if (
      user.email &&
      (user.email.toLowerCase().endsWith('@btinternet.com') ||
        user.email.toLowerCase().endsWith('@btopenworld.com') ||
        user.email.toLowerCase().endsWith('@talk21.com'))
    ) {
      const activateUrl = user.confirmedUrl.replace('/confirmed', '/activate');
      this.setState({ confirmedUrl: activateUrl });
      user.confirmedUrl = activateUrl;
    }

    const dateOfBirth = moment.utc(user.dateOfBirth, 'DD-MM-YYYY').format();

    register({
      ...user,
      serviceCode: serviceCode.toUpperCase(),
      organisationId: service.organisation.id,
      dateOfBirth
    });
  };

  renderDeactivatedServiceCode(message) {
    const { classes } = this.props;

    return (
      <div>
        <TitleCard
          cardTitle="Registration Ended"
          cardSubtitle="FHQS pathway with your practice has now ended"
          content={
            <div className={classes.textContainer}>
              <Typography variant="subtitle1">
                {message ? (
                  <Markdown>{message}</Markdown>
                ) : (
                  'The Family History Questionnaire Service (FHQS) pathway with your practice has now ended. You can no longer use this service to enter your personal and family history of cancer for assessment. If you are still concerned that you need an assessment to look into any possible inherited chance of developing cancer, then you would need to contact your practice directly for an appointment.'
                )}
              </Typography>
            </div>
          }
        />
      </div>
    );
  }

  renderRegistered() {
    const { classes } = this.props;
    const contactEmail = this.props.service?.serviceConfiguration?.contactEmail;

    return (
      <div>
        <TitleCard
          cardTitle="Registration Successful"
          cardSubtitle="Your registration was successful"
          content={
            <div className={classes.textContainer}>
              <Typography variant="subtitle1">
                Thank you for completing the registration. Before you can sign
                in you will need to confirm your email address by clicking on
                the link in the confirmation email that should arrive shortly.
                <br />
                <br />
                Please do check your junk mail folder if you have not received
                the email in the next few minutes. If the email still does not
                arrive, please email us and let us know on:{' '}
                <a
                  href={`mailto:${contactEmail ? contactEmail : config.email}`}
                >
                  {contactEmail ? contactEmail : config.email}
                </a>
              </Typography>
            </div>
          }
        />
      </div>
    );
  }

  renderRegister() {
    const { classes, registerErrors } = this.props;
    const { service, serviceCodeDetails } = this.props;
    const referringTrusts = service?.referringTrusts;
    const idTypeName = serviceCodeDetails?.idType
      ? serviceCodeDetails.idType
      : 'NHS Number';

    const content = (
      <div className={classes.contentContainer}>
        <div className={classes.rowContainer}>
          {referringTrusts ? (
            <div className={classes.answerContainer}>
              <div className={classes.dropDownContainer}>
                <DropDownSelect
                  id="referringTrust"
                  inputProps={{ 'data-lpignore': 'true', autoComplete: 'off' }}
                  currentPlaceholder={'Referring Trust'}
                  currentValue={this.state.referringTrust}
                  currentKey={this.state.referringTrust}
                  currentFieldName="referringTrust"
                  items={referringTrusts.map(x => {
                    return {
                      value: x.referringTrustName,
                      key: x.referringTrustName
                    };
                  })}
                  onFieldChanged={this.handleSelectFieldChanged(
                    'referringTrust'
                  )}
                  // onKeyPress={onKeyPress}
                  margin="dense"
                  fullWidth
                  error={
                    this.errorFor('referringTrust') !== '' &&
                    this.state.showErrors
                  }
                  helperText={
                    this.state.showErrors
                      ? this.errorFor('referringTrust')
                      : null
                  }
                />
              </div>
            </div>
          ) : null}
          <div className={classes.questionContainer}>
            <TextField
              id="serviceCode"
              label="Service Code"
              placeholder="Enter your service code"
              disabled
              value={
                this.state.serviceCode
                  ? this.state.serviceCode
                      .trim()
                      .toUpperCase()
                      .replace(/\s/g, '')
                  : ''
              }
              onChange={this.handleFieldChanged('serviceCode')}
              margin="dense"
              fullWidth
              error={
                this.errorFor('serviceCode') !== '' && this.state.showErrors
              }
              helperText={
                this.state.showErrors ? this.errorFor('serviceCode') : null
              }
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.questionContainer}>
            <TextField
              id="firstName"
              label="First Names"
              placeholder="Enter your first (given) names"
              value={this.state.firstName}
              onChange={this.handleFieldChanged('firstName')}
              margin="dense"
              fullWidth
              error={this.errorFor('firstName') !== '' && this.state.showErrors}
              helperText={
                this.state.showErrors ? this.errorFor('firstName') : null
              }
            />
          </div>
          <div className={classes.answerContainer}>
            <TextField
              id="lastName"
              label="Last Name"
              placeholder="Enter your last name"
              value={this.state.lastName}
              onChange={this.handleFieldChanged('lastName')}
              margin="dense"
              fullWidth
              error={this.errorFor('lastName') !== '' && this.state.showErrors}
              helperText={
                this.state.showErrors ? this.errorFor('lastName') : null
              }
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.answerContainer}>
            <TextField
              id="dateOfBirth"
              label="Date of Birth (dd/mm/yyyy)"
              placeholder="Enter your date of birth"
              value={this.state.dateOfBirth}
              onCut={this.handleCutCopyPaste()}
              onCopy={this.handleCutCopyPaste()}
              onPaste={this.handleCutCopyPaste()}
              onChange={this.handleFieldChanged('dateOfBirth')}
              margin="dense"
              fullWidth
              error={
                this.errorFor('dateOfBirth') !== '' && this.state.showErrors
              }
              helperText={
                this.state.showErrors ? this.errorFor('dateOfBirth') : null
              }
            />
          </div>
          <div className={classes.answerContainer}>
            <TextField
              id="confirmDateOfBirth"
              label="Confirm Date of Birth"
              placeholder="Enter your date of birth confirmation"
              value={this.state.confirmDateOfBirth}
              onCut={this.handleCutCopyPaste()}
              onCopy={this.handleCutCopyPaste()}
              onPaste={this.handleCutCopyPaste()}
              onChange={this.handleFieldChanged('confirmDateOfBirth')}
              margin="dense"
              fullWidth
              error={
                this.errorFor('confirmDateOfBirth') !== '' &&
                this.state.showErrors
              }
              helperText={
                this.state.showErrors
                  ? this.errorFor('confirmDateOfBirth')
                  : null
              }
            />
          </div>
          <div className={classes.answerContainer}>
            <TextField
              id="nhsNo"
              label={idTypeName}
              placeholder={`Enter your ${idTypeName}`}
              value={this.state.nhsNo.trim().replace(/\s/g, '')}
              onChange={this.handleFieldChanged('nhsNo')}
              margin="dense"
              fullWidth
              type="number"
              error={this.errorFor('nhsNo') !== '' && this.state.showErrors}
              helperText={this.state.showErrors ? this.errorFor('nhsNo') : null}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.questionContainer}>
            <TextField
              id="addressLine1"
              label="Address Line 1"
              placeholder="Enter the 1st line of your address"
              value={this.state.addressLine1}
              onChange={this.handleFieldChanged('addressLine1')}
              margin="dense"
              fullWidth
              error={
                this.errorFor('addressLine1') !== '' && this.state.showErrors
              }
              helperText={
                this.state.showErrors ? this.errorFor('addressLine1') : null
              }
            />
          </div>
          <div className={classes.answerContainer}>
            <TextField
              id="addressLine2"
              label="Address Line 2"
              placeholder="Enter the 2nd line of you address"
              value={this.state.addressLine2}
              onChange={this.handleFieldChanged('addressLine2')}
              margin="dense"
              fullWidth
              error={
                this.errorFor('addressLine2') !== '' && this.state.showErrors
              }
              helperText={
                this.state.showErrors ? this.errorFor('addressLine2') : null
              }
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.questionContainer}>
            <TextField
              id="town"
              label="Town"
              placeholder="Enter your address town/city"
              value={this.state.town}
              onChange={this.handleFieldChanged('town')}
              margin="dense"
              fullWidth
              error={this.errorFor('town') !== '' && this.state.showErrors}
              helperText={this.state.showErrors ? this.errorFor('town') : null}
            />
          </div>
          <div className={classes.answerContainer}>
            <TextField
              id="country"
              label="Country"
              placeholder="Enter your address country"
              value={this.state.country}
              onChange={this.handleFieldChanged('country')}
              margin="dense"
              fullWidth
              error={this.errorFor('country') !== '' && this.state.showErrors}
              helperText={
                this.state.showErrors ? this.errorFor('country') : null
              }
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.questionContainer}>
            <TextField
              id="postcode"
              label="Postcode"
              placeholder="Enter your address postcode"
              value={this.state.postcode}
              onChange={this.handleFieldChanged('postcode')}
              margin="dense"
              fullWidth
              error={this.errorFor('postcode') !== '' && this.state.showErrors}
              helperText={
                this.state.showErrors ? this.errorFor('postcode') : null
              }
            />
          </div>
          <div className={classes.questionContainer}>
            <TextField
              id="phone"
              label="Mobile Number"
              placeholder="Enter your contact phone number"
              value={this.state.phone.trim().replace(/\s/g, '')}
              onChange={this.handleFieldChanged('phone')}
              fullWidth
              error={this.errorFor('phone') !== '' && this.state.showErrors}
              helperText={this.state.showErrors ? this.errorFor('phone') : null}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.questionContainer}>
            <TextField
              id="email"
              label="Email"
              placeholder="Enter your email address"
              value={this.state.email}
              onChange={this.handleFieldChanged('email')}
              fullWidth
              error={this.errorFor('email') !== '' && this.state.showErrors}
              helperText={this.state.showErrors ? this.errorFor('email') : null}
            />
          </div>
          <div className={classes.answerContainer}>
            <TextField
              id="confirmEmail"
              label="Confirm Email"
              placeholder="Enter your email address confirmation"
              value={this.state.confirmEmail}
              onCut={this.handleCutCopyPaste()}
              onCopy={this.handleCutCopyPaste()}
              onPaste={this.handleCutCopyPaste()}
              onChange={this.handleFieldChanged('confirmEmail')}
              fullWidth
              error={
                this.errorFor('confirmEmail') !== '' && this.state.showErrors
              }
              helperText={
                this.state.showErrors ? this.errorFor('confirmEmail') : null
              }
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.questionContainer}>
            <TextField
              id="password"
              label="Password (minimum 8 chars)"
              placeholder="Enter a new password"
              value={this.state.password}
              onChange={this.handleFieldChanged('password')}
              margin="dense"
              type="password"
              fullWidth
              error={this.errorFor('password') !== '' && this.state.showErrors}
              helperText={
                this.state.showErrors ? this.errorFor('password') : null
              }
            />
          </div>
          <div className={classes.answerContainer}>
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              placeholder="Enter a password confirmation"
              value={this.state.confirmPassword}
              onChange={this.handleFieldChanged('confirmPassword')}
              margin="dense"
              type="password"
              fullWidth
              error={
                this.errorFor('confirmPassword') !== '' && this.state.showErrors
              }
              helperText={
                this.state.showErrors ? this.errorFor('confirmPassword') : null
              }
            />
          </div>
        </div>
        {registerErrors && (
          <Typography variant="subtitle1" className={classes.submitError}>
            {`There was a problem submitting the form.`}
            <br />
            {registerErrors.split('/n').map(item => (
              <>
                <Markdown>{item}</Markdown>
                <br />
              </>
            ))}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          className={classes.passwordMsgContainer}
        >
          {
            'Please note you are setting this password for the protection of healthcare information. Password guidance is available from the NHS '
          }
          <a
            href="https://digital.nhs.uk/about-nhs-digital/corporate-information-and-documents/password-policy-guide---example-guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Typography>
      </div>
    );

    return serviceCodeDetails && !serviceCodeDetails?.isActive ? (
      this.renderDeactivatedServiceCode(serviceCodeDetails?.deactivatedMessage)
    ) : (
      <div>
        <form name="registerForm" noValidate>
          <TitleCard
            cardTitle="Register"
            cardSubtitle="Fill in the form below to get started"
            content={content}
            footer={
              <Button type="submit" onClick={this.handleSubmitClicked}>
                Register
              </Button>
            }
          />
        </form>
      </div>
    );
  }

  static renderHelix() {
    return (
      <TitleCard
        cardTitle="Registering"
        cardSubtitle="Registration in progress"
        content={<Helix />}
      />
    );
  }

  render() {
    const { submitted } = this.state;
    const { registerErrors, registering, registered } = this.props;
    return submitted && registering && !registerErrors
      ? Register.renderHelix()
      : registered
      ? this.renderRegistered()
      : this.renderRegister();
  }
}

Register.propTypes = {
  onCreateAccount: PropTypes.func
};

const mapState = state => {
  const { registered, registering, registerErrors } = state.registration;
  const {
    service,
    code: serviceCode,
    serviceCode: serviceCodeDetails
  } = state.organisation;

  return {
    registered,
    registering,
    registerErrors,
    service,
    serviceCode,
    serviceCodeDetails
  };
};

const mapDispatch = {
  register: api.endpoints.register.initiate
};

const connectedRegister = compose(
  connect(mapState, mapDispatch),
  withStyles(styles, { withTheme: true })
)(Register);

export { connectedRegister as Register };
