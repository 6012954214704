import {
  isEmail,
  isRequired,
  isNumeric,
  isPositive,
  isWholeNumber,
  isUKDateOrYear,
  isBetween,
  isOnlyDateChars,
  ageValidation
} from '../../validation/rules';
import { ruleRunner } from '../../validation/ruleRunner';
import {
  DisplayTypeConstants,
  SpecificAnswerTypes
} from '../../helpers/constants';
import {
  getFieldInfoFromName,
  getQuestionIdFromAlias
} from '../../features/questionnaire/unrefactored/fields';

export const fieldValidations = (id, name, answerType, requiredAnswer) => {
  let result = [];

  if (requiredAnswer) {
    result.push(ruleRunner(id, name, requiredAnswer, isRequired));
  }

  switch (answerType.displayType) {
    case DisplayTypeConstants.EmailText:
      result.push(ruleRunner(id, name, requiredAnswer, isEmail));
      break;
    case DisplayTypeConstants.NumericText:
      result.push(
        ruleRunner(
          id,
          name,
          requiredAnswer,
          isNumeric,
          isPositive,
          isWholeNumber
        )
      );

      if (answerType.id === SpecificAnswerTypes.LoopCount) {
        result.push(
          ruleRunner(id, name, requiredAnswer, fieldName =>
            isBetween(fieldName, 0, 20)
          )
        );
      }
      break;
    case DisplayTypeConstants.DateYearText:
      result.push(
        ruleRunner(id, name, requiredAnswer, isOnlyDateChars, isUKDateOrYear)
      );
      break;
    default:
      break;
  }

  const currentField = getFieldInfoFromName(id);

  const questionId = currentField.questionId;

  const yearOfDeathQuestionId = getQuestionIdFromAlias('YearOfDeath');
  const ageOfDiagnosesQuestionId = getQuestionIdFromAlias('AgeOfDiagnosis');

  const ageValidationQuestions = [
    yearOfDeathQuestionId,
    ageOfDiagnosesQuestionId
  ];

  if (ageValidationQuestions.includes(questionId)) {
    result.push(
      ruleRunner(id, name, requiredAnswer, (value, state, fieldName) =>
        ageValidation(value, state, fieldName)
      )
    );
  }

  return result;
};
