import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import { history } from '../../../helpers/history';
import InterestingByServiceCode from './InterestingByServiceCode';
import ServiceCodes from './ServiceCodes';
import { api } from '../../../services/api/dataApi';
import SubmissionsByServiceCode from './SubmissionsByServiceCode';

const styles = theme => Styles(theme);

class SuperAdmin extends React.Component {
  componentDidMount() {
    const { loggedIn, getAllServiceCodes, allServiceCodes } = this.props;

    if (!loggedIn) history.push('/signin');

    if (!allServiceCodes) {
      getAllServiceCodes();
    }
  }

  renderSubmissions() {
    return (
      <>
        <InterestingByServiceCode />
        <SubmissionsByServiceCode />
      </>
    );
  }

  renderServiceCodes() {
    return (
      <>
        <ServiceCodes />
      </>
    );
  }

  renderContent = () => {
    const { selectedServiceCode, submissionsByServiceCode } = this.props;

    const title = selectedServiceCode
      ? selectedServiceCode.service.name
      : 'Submission List';

    return (
      <>
        <TitleCard
          cardTitle="Super Admin"
          cardSubtitle="View Submissions by Registered Organisations"
          content={this.renderServiceCodes()}
          footer={null}
          fullWidth
        />
        {submissionsByServiceCode != null &&
          submissionsByServiceCode.length > 0 && (
            <TitleCard
              cardTitle={title}
              cardSubtitle={`Current submissions as of ${moment().format(
                'DD/MM/YYYY'
              )}`}
              content={this.renderSubmissions()}
              footer={null}
              fullWidth
            />
          )}
      </>
    );
  };

  render() {
    return this.renderContent();
  }
}

SuperAdmin.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatch = {
  submissionDetailsByServiceCode:
    api.endpoints.submissionDetailsByServiceCode.initiate,
  getAllServiceCodes: api.endpoints.getAllServiceCodes.initiate
};

const mapState = state => {
  const {
    selectedServiceCode,
    submissionsByServiceCode,
    actionStatus,
    isSubmissionsByServiceCodeLoading
  } = state.admin;

  const { allServiceCodes } = state.organisation;

  const { loggedIn, user } = state.authentication;

  return {
    user,
    loggedIn,
    submissionsByServiceCode,
    selectedServiceCode,
    actionStatus,
    isSubmissionsByServiceCodeLoading,
    allServiceCodes
  };
};

const composedSuperAdmin = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(SuperAdmin);

export { composedSuperAdmin as SuperAdmin };
