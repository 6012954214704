import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import TitleCard from '../../Cards/TitleCard';
import Helix from '../../Helix';
import { fieldValidations } from './validation';
import { run } from '../../../validation/ruleRunner';
import { history } from '../../../helpers/history';
import { ErrorConstants } from '../../../helpers/constants';
import Button from '@material-ui/core/Button';
import TextField from '../../StyledTextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import { Link } from 'react-router-dom';
import { api } from '../../../services/api/dataApi';
import { compose } from 'redux';
import { setUnconfirmed } from '../../../features/authentication-slice';
import { addAutoHidingAlert } from '../../../features/alerts-slice';
import { config } from '../../../helpers/config';

const styles = theme => Styles(theme);

const initialState = {
  email: '',
  password: '',
  twoFactorCode: '',
  twoFactorErrors: [],
  submitted: false,
  showErrors: false,
  confirmationSent: false,
  validationErrors: []
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    // Run validations on initial state
    this.setState({ validationErrors: run(this.state, fieldValidations) });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const {
        loginErrors,
        alert,
        setUnconfirmed,
        loggedIn,
        user,
        serviceCode,
        getServiceCode,
        serviceCodeLoading,
        serviceCodeLoaded
      } = this.props;

      if (loginErrors) {
        switch (loginErrors) {
          case ErrorConstants.UserNotConfirmed:
            setUnconfirmed(true);
            return;
          default:
            await alert({
              message: loginErrors,
              variant: 'error',
              autoDismiss: true
            });
            await alert({
              message: 'Please try again',
              variant: 'info',
              autoDismiss: true
            });
            return;
        }
      }

      if (loggedIn) {
        if (
          (!serviceCode && !serviceCodeLoaded && !serviceCodeLoading) ||
          serviceCode?.code !== user?.serviceCode
        ) {
          await getServiceCode(user.serviceCode, {
            subscribe: false,
            forceRefetch: true
          });
        }

        localStorage.setItem('user', JSON.stringify(user));
        history.push('/start');
      }
    }
  }

  errorFor = field => {
    return this.state.validationErrors[field] || '';
  };

  handleFieldChanged = (e, field) => {
    this.setState({ [field]: e.target.value }, () => {
      const validationErrors = run(this.state, fieldValidations);
      this.setState({ validationErrors });
    });
  };

  handleKeyPressed = () => {
    return e => {
      if (e.key === 'Enter') {
        e.preventDefault();

        this.props.unconfirmed
          ? this.resendConfirmationEmail()
          : this.state.confirmationSent
          ? this.handleSignIn()
          : this.props.awaitingTwoFactor
          ? this.verifyTwoFactor()
          : this.handleSubmitClicked();
      }
    };
  };

  handleSubmitClicked = async e => {
    e && e.preventDefault();
    this.setState({ showErrors: true });

    if (Object.getOwnPropertyNames(this.state.validationErrors).length !== 0)
      return null;

    this.setState({ submitted: true });
    const { email, password } = this.state;

    const { login } = this.props;

    if (email && password) {
      await login({ email, password });
    }
  };

  handleForgottenPasswordClicked = () => {
    history.push('forgottenpassword');
  };

  handleSignIn = () => {
    this.setState(initialState);
  };

  resendConfirmationEmail = () => {
    const { resendConfirmation, setUnconfirmed } = this.props;
    const { email } = this.state;
    const confirmedUrl =
      window.location.protocol + '//' + window.location.host + '/confirmed';

    this.setState({ submitted: false, confirmationSent: true });

    setUnconfirmed(false);
    resendConfirmation({ email, confirmedUrl });
  };

  renderLogin() {
    return (
      <form name="loginForm">
        <TitleCard
          cardTitle="Sign in"
          cardSubtitle="To continue the questionnaire"
          content={this.renderContent()}
          footer={
            <Button type="submit" onClick={this.handleSubmitClicked}>
              Sign in
            </Button>
          }
        />
      </form>
    );
  }

  renderResendConfirmation() {
    return (
      <TitleCard
        cardTitle="Confirmation required"
        cardSubtitle="Your email address has not yet been confirmed."
        content={
          <Typography variant="subtitle1">
            Would you like to resend the confirmation email?
          </Typography>
        }
        footer={
          <Button onClick={this.resendConfirmationEmail}>Resend Email</Button>
        }
      />
    );
  }

  verifyTwoFactor() {
    const { twoFactorCode } = this.state;
    const { user, verifyTwoFactorCode } = this.props;

    verifyTwoFactorCode({
      userid: user.id,
      code: twoFactorCode
    });
  }

  requestAuthenticatorSetup() {
    const { user, sendAuthenticatorSetupEmail } = this.props;
    sendAuthenticatorSetupEmail({
      email: user.email
    });
  }

  renderTwoFactorConfirmation() {
    const { classes, twoFactorErrors } = this.props;
    return (
      <TitleCard
        cardTitle="Verify Multi-factor Authentication"
        cardSubtitle="Enter one-time password code."
        content={
          <>
            <Typography variant="subtitle1">
              Please enter OTP code from your authenticator app.
            </Typography>
            <TextField
              className={classes.input}
              id="twoFactorCode"
              label="Code"
              placeholder="Enter your OTP code"
              margin="dense"
              value={this.state.twoFactorCode}
              onKeyUp={this.handleKeyPressed(event)}
              onChange={event =>
                this.handleFieldChanged(event, 'twoFactorCode')
              }
              error={
                this.errorFor('twoFactorCode') !== '' && this.state.showErrors
              }
              helperText={
                this.state.showErrors ? this.errorFor('twoFactorCode') : null
              }
            />
            {twoFactorErrors && (
              <Typography
                variant="subtitle1"
                paragraph
                className={classes.errorText}
              >
                Verification code was incorrect. Please enter one-time password code from
                your authenticator app.
              </Typography>
            )}
          </>
        }
        footer={
          <>
            {/* disable as to manually send by System Admin
            {twoFactorErrors && (
              <Button onClick={() => this.requestAuthenticatorSetup()}>
                Request Authenticator Setup
              </Button>
            )} 
            */}
            <Button onClick={() => this.verifyTwoFactor()}>Verify</Button>
          </>
        }
      />
    );
  }

  renderConfirmationSent() {
    const contactEmail = this.props.service?.serviceConfiguration?.contactEmail;

    return (
      <TitleCard
        cardTitle="Confirmation sent"
        cardSubtitle="An email has been sent"
        content={
          <Typography variant="subtitle1">
            Click on the link on the email we have sent you to confirm your
            account. Please do check your junk mail folder if you have not
            received the email in the next few minutes.
            <br />
            <br />
            If the email still does not arrive, please email us and let us know
            on:{' '}
            <a href={`mailto:${contactEmail ? contactEmail : config.email}`}>
              {contactEmail ? contactEmail : config.email}
            </a>
          </Typography>
        }
        footer={<Button onClick={this.handleSignIn}>Sign in</Button>}
      />
    );
  }

  renderContent() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.contentContainer}>
          <div className={classes.leftContainer}>{this.renderEmail()}</div>
          <div className={classes.rightContainer}>{this.renderPassword()}</div>
        </div>
        <div className={classes.forgottenContainer}>
          <Link to="/password" className={classes.forgottenLink}>
            <Typography
              variant="overline"
              onClick={this.handleForgottenPasswordClicked}
            >
              FORGOTTEN PASSWORD?
            </Typography>
          </Link>
        </div>
      </Fragment>
    );
  }

  renderEmail() {
    return (
      <TextField
        id="email"
        label="Email"
        placeholder="Enter your email here"
        value={this.state.email}
        onChange={event => this.handleFieldChanged(event, 'email')}
        inputProps={{ autoComplete: 'email' }}
        margin="dense"
        fullWidth
        type="email"
        error={this.errorFor('email') !== '' && this.state.showErrors}
        helperText={this.state.showErrors ? this.errorFor('email') : null}
      />
    );
  }

  renderPassword() {
    return (
      <TextField
        id="password"
        label="Password"
        placeholder="Enter your password here"
        value={this.state.password}
        onChange={event => this.handleFieldChanged(event, 'password')}
        inputProps={{ autoComplete: 'current-password' }}
        margin="dense"
        type="password"
        fullWidth
        error={this.errorFor('password') !== '' && this.state.showErrors}
        helperText={this.state.showErrors ? this.errorFor('password') : null}
      />
    );
  }

  static renderHelix() {
    return (
      <TitleCard
        cardTitle="Signing in"
        cardSubtitle="Authentication in progress"
        content={<Helix />}
      />
    );
  }

  render() {
    const hasErrors =
      this.props.loginErrors && this.props.loginErrors.length > 0;

    return this.props.unconfirmed
      ? this.renderResendConfirmation()
      : this.state.confirmationSent
      ? this.renderConfirmationSent()
      : this.props.awaitingTwoFactor
      ? this.renderTwoFactorConfirmation()
      : this.state.submitted && !hasErrors
      ? Login.renderHelix()
      : this.renderLogin();
  }
}

const mapState = state => {
  const {
    loggedIn,
    loggingIn,
    loginErrors,
    unconfirmed,
    user,
    awaitingTwoFactor,
    twoFactorErrors
  } = state.authentication;

  const {
    service,
    serviceCode,
    isLoading: serviceCodeLoading,
    isLoaded: serviceCodeLoaded
  } = state.organisation;

  return {
    loggingIn,
    loggedIn,
    loginErrors,
    unconfirmed,
    user,
    service,
    serviceCode,
    serviceCodeLoading,
    serviceCodeLoaded,
    awaitingTwoFactor,
    twoFactorErrors
  };
};

const mapDispatch = {
  login: api.endpoints.login.initiate,
  getServiceCode: api.endpoints.getServiceCode.initiate,
  resendConfirmation: api.endpoints.resendConfirmation.initiate,
  verifyTwoFactorCode: api.endpoints.verifyTwoFactorCode.initiate,
  sendAuthenticatorSetupEmail: api.endpoints.sendAuthenticatorSetupEmail.initiate,
  setUnconfirmed,
  alert: addAutoHidingAlert
};

const connectedLogin = compose(
  connect(mapState, mapDispatch),
  withStyles(styles, { withTheme: true })
)(Login);

export { connectedLogin as Login };
