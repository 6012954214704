import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert } from '../types/system';
import { AppDispatch, RootState } from '../types/stores';

export interface AlertState {
  currentAlert?: Alert;
  open: boolean;
  alertQueue?: Array<Alert>;
}

const initialState: AlertState = {
  currentAlert: undefined,
  open: false,
  alertQueue: []
};

const firstQueueKey = (state: AlertState) => {
  return state.alertQueue && state.alertQueue.length > 0
    ? state.alertQueue[0].key
    : -1;
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    clearAlerts(state) {
      const firstKey = firstQueueKey(state);
      if (firstKey !== -1) {
        state.alertQueue = new Array<Alert>();
      }
    },
    processAlertQueue(state) {
      const firstKey = firstQueueKey(state);
      if (firstKey !== -1) {
        state?.alertQueue?.shift();
      }
    },
    setCurrentAlert(state) {
      const firstKey = firstQueueKey(state);
      if (firstKey !== -1) {
        const alert = state?.alertQueue?.find(alert => alert.key === firstKey);
        state.currentAlert = alert;
      }
    },
    updateAlertQueue(state, action: PayloadAction<Alert>) {
      const key = new Date().getTime();
      const alert = action.payload;
      alert.key = key;
      state?.alertQueue?.push(alert);
    },
    openAlerts(state) {
      state.open = true;
    },
    closeAlerts(state) {
      state.open = false;
    }
  }
});

export const addAutoHidingAlert = createAsyncThunk<
  void,
  Alert,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('alerts/addAutoHidingAlert', async (alert: Alert, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  dispatch(updateAlertQueue(alert));

  const { alerts } = getState();
  const { currentAlert, open } = alerts;

  if (open) {
    if (alert.autoDismiss) {
      const timeDiff = new Date().getTime() - (currentAlert?.key || 0);

      if (timeDiff < 2000) {
        setTimeout(() => {
          dispatch(closeAlerts());
        }, 2000);
      } else {
        dispatch(closeAlerts());
      }
    }
  } else {
    dispatch(setCurrentAlert());
    dispatch(openAlerts());
  }
});

export const {
  clearAlerts,
  setCurrentAlert,
  processAlertQueue,
  updateAlertQueue,
  openAlerts,
  closeAlerts
} = alertsSlice.actions;

export default alertsSlice.reducer;
