import Immutable from 'immutable';
import { store } from '../../../stores/store';
import { Parser } from 'expr-eval';
import { isNumeric, ukYearOrDateToIso, thisYear } from '../../../helpers/types';
import { getAliasedResponse } from './aliasedResponses';
import {
  emptyObject,
  getFieldName,
  getSectionInfoFromKey,
  getContextKey,
  fieldStart,
  coerceTypes,
  getNonRepeatableSectionId,
  getFieldInfoFromName,
  getNextResponseFieldInfo,
  emptyPair,
  getSectionIdFromName,
  getQuestionIdFromAlias
} from './fields';
import { NavigationConstants } from '../../../helpers/constants';
import moment from 'moment';
import { setContext } from '../questionnaire-slice';

let editedResponses = null;
let sharedFieldInfo = null;

const evaluateExpression = (
  navigationExpression,
  currentFieldInfo,
  testResponses = null
) => {
  editedResponses = testResponses;
  sharedFieldInfo = currentFieldInfo;

  const questionnaireState = store.getState().newQuestionnaire;
  const question = questionnaireState.questionnaire.questions.find(
    x => x.id === currentFieldInfo.questionId
  );
  const contextKey = question.section.repeatable
    ? getContextKey(
        currentFieldInfo.sectionId,
        currentFieldInfo.sectionInstanceId
      )
    : '';
  const sectionContext = question.section.repeatable
    ? questionnaireState.contexts.get(contextKey, new Immutable.Map())
    : new Immutable.Map();
  const globalContext = getGlobalContext(questionnaireState.contexts);

  const context = sectionContext.merge(globalContext).toJS();
  context.aliasResponse = aliasResponse;
  context.aliasQuestionId = aliasQuestionId;
  context.startRepeatableSection = startRepeatableSection;
  context.thisYear = thisYear;
  context.getYear = getYear;
  context.similarBirthDates = similarBirthDates;
  context.anyBreastOrOvarian = anyBreastOrOvarian;
  context.getRespondentGender = getRespondentGender;
  context.getServiceCode = getServiceCode;
  context.CurrentFieldInfo = currentFieldInfo;
  context.navConstants = NavigationConstants;

  // Evaluate navigation expression to get navResult
  const parser = new Parser({
    operators: {
      in: true
    }
  });

  const result = parser.evaluate(navigationExpression, context);
  return result;
};

const getServiceCode = () => {
  const result = store.getState().organisation.code;
  return result;
};

const aliasResponse = (alias, useKey = false) => {
  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const questions = questionnaire.questions;
  const aliasedQuestion = questions.find(x => x.alias === alias);
  const currentFieldInfo = sharedFieldInfo;
  const responses =
    editedResponses == null ? questionnaireState.responses : editedResponses;
  let sectionIndex = null,
    sectionInstanceId = null,
    sectionDepth = null;

  if (currentFieldInfo) {
    sectionIndex = currentFieldInfo.sectionIndex;
    sectionInstanceId = currentFieldInfo.sectionInstanceId;
    sectionDepth = currentFieldInfo.sectionDepth;
  }

  const aliasedFieldName = getFieldName(
    aliasedQuestion.id,
    aliasedQuestion.section.id,
    sectionInstanceId,
    sectionIndex,
    sectionDepth
  );
  const responseObj = responses.get(aliasedFieldName, emptyPair);
  const result = useKey ? responseObj.key : responseObj.value;

  return result;
};

const aliasQuestionId = alias => {
  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const questions = questionnaire.questions;
  return questions.find(x => x.alias === alias).id;
};

const getYear = text => {
  text = text.toString();

  if (text.indexOf('/') !== -1) {
    const textParts = text.split('/');
    return parseInt(textParts[2], 10);
  } else {
    return parseInt(text, 10);
  }
};

const anyBreastOrOvarian = () => {
  const questionnaireState = store.getState().newQuestionnaire;
  const responses = questionnaireState.responses;
  const typeOfCancerQuestionId = getQuestionIdFromAlias('TypeOfCancer');

  const responseKeys = responses.keySeq();
  let result = false;

  responseKeys.forEach(fieldName => {
    const response = responses.get(fieldName, null);

    if (
      response.questionId === typeOfCancerQuestionId &&
      (response.value === 'Breast cancer' ||
        response.value === 'Ovarian cancer')
    ) {
      result = true;
    }
  });

  return result;
};

const getRespondentGender = () => {
  const questionnaireState = store.getState().newQuestionnaire;
  const responses = questionnaireState.responses;
  const questionnaire = questionnaireState.questionnaire;
  const questions = questionnaire.questions;
  const personalGenderQuestionId = getQuestionIdFromAlias('PersonalGender');
  const personalBirthSexAssignmentQuestionId = getQuestionIdFromAlias(
    'PersonalBirthSexAssignment'
  );

  const genderQuestionId = questions.filter(
    question => question.id === personalGenderQuestionId
  )[0].id;
  const birthGenderQuestionId = questions.filter(
    question => question.id === personalBirthSexAssignmentQuestionId
  )[0].id;

  let gender = '',
    birthGender = '';

  const responseKeys = responses.keySeq();

  responseKeys.forEach(fieldName => {
    const response = responses.get(fieldName, null);

    switch (response.questionId) {
      case genderQuestionId:
        gender = response.value;
        break;
      case birthGenderQuestionId:
        birthGender = response.value;
        break;
      default:
    }
  });

  if (gender === 'Male' || gender === 'Female') {
    return gender;
  }

  return birthGender;
};

const similarBirthDates = () => {
  const currentFieldInfo = sharedFieldInfo;
  const currentSectionId = getNonRepeatableSectionId(currentFieldInfo);

  // Drop out if in any other relatives section
  if (currentSectionId === getSectionIdFromName('AnyOtherRelatives'))
    return false;

  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const contexts = questionnaireState.contexts;
  const responses = questionnaireState.responses;
  const questions = questionnaire.questions;
  let similarDates = false;

  const dobQuestionId = questions.find(x => x.alias === 'RelativeDoB').id;
  const currentDobFieldName = getFieldName(
    dobQuestionId,
    currentFieldInfo.sectionId,
    currentFieldInfo.sectionInstanceId,
    currentFieldInfo.sectionIndex,
    currentFieldInfo.sectionDepth
  );
  const currentDob = moment(
    ukYearOrDateToIso(responses.get(currentDobFieldName).value)
  );

  const currentSectionIndex = currentFieldInfo.sectionIndex;
  const contextKey = getContextKey(
    currentFieldInfo.sectionId,
    currentFieldInfo.sectionInstanceId
  );
  const context = contexts.get(contextKey);
  const sourceFieldName = context.get('SectionSourceField', null);
  const siblingCount = context.get('LoopCount');

  // Check we're not doing grand parents (no siblings to match)
  if (sourceFieldName != null) {
    const sourceQuestionId = getFieldInfoFromName(sourceFieldName).questionId;
    const grandParentQuestionIds = [
      aliasQuestionId('PaternalGrandfatherStart'),
      aliasQuestionId('PaternalGrandmotherStart'),
      aliasQuestionId('MaternalGrandfatherStart'),
      aliasQuestionId('MaternalGrandmotherStart')
    ];

    if (grandParentQuestionIds.includes(sourceQuestionId)) {
      return;
    }
  }

  let compareDob = null;
  let compareIsYear = false;
  let startFieldName;
  let compareDobFieldName;

  const yourChildrenSectionId = getSectionIdFromName('YourChildren');
  const yourSiblingsSectionId = getSectionIdFromName('YourSiblings');
  const yourFathersSideSectionId = getSectionIdFromName('YourFathersSide');
  const yourMotherSideSectionId = getSectionIdFromName('YourMothersSide');
  const relationShipSpecificSectionId = getSectionIdFromName(
    'RelationshipSpecificsLoop'
  );

  // Also need to check for twinnage between respondent and siblings,
  // father and their siblings and mother and their siblings
  // Don't check these if we're looking at relations kids (sectionDepth > 1)
  // Or respondent kids (currentSectionId ===
  if (
    currentFieldInfo.sectionDepth === 1 &&
    currentSectionId !== yourChildrenSectionId
  ) {
    if (currentSectionId === yourSiblingsSectionId) {
      compareDob = moment(ukYearOrDateToIso(questionnaireState.userDob));
      compareIsYear = false;
    } else {
      switch (currentSectionId) {
        case yourFathersSideSectionId:
          startFieldName = getFieldName(
            aliasQuestionId('FatherStart'),
            currentSectionId
          );
          break;
        case yourMotherSideSectionId:
          startFieldName = getFieldName(
            aliasQuestionId('MotherStart'),
            currentSectionId
          );
          break;
        default:
          break;
      }

      const nextResponse = getNextResponseFieldInfo(
        getFieldInfoFromName(startFieldName),
        responses
      );
      compareDobFieldName = getFieldName(
        aliasQuestionId('RelativeDoB'),
        relationShipSpecificSectionId,
        nextResponse.sectionInstanceId,
        1,
        1
      );
      const response = responses.get(compareDobFieldName).value;

      compareIsYear =
        response != null ? response.toString().length === 4 : false;
      compareDob = moment(ukYearOrDateToIso(response));
    }

    //Check other relation
    if (compareDob != null && compareDobFieldName !== currentDobFieldName) {
      similarDates = compareIsYear
        ? currentDob.year() === compareDob.year()
        : Math.abs(currentDob.diff(compareDob, 'days')) === 0;

      if (similarDates) return similarDates;
    }
  }

  //Loop through potential responses and check if any birth dates match
  for (let index = 1; index <= siblingCount; index++) {
    // Jump over our own one
    if (index === currentSectionIndex) {
      continue;
    }

    //Have we got a response for this sibling DoB question
    const lookupFieldName = getFieldName(
      dobQuestionId,
      currentFieldInfo.sectionId,
      currentFieldInfo.sectionInstanceId,
      index,
      currentFieldInfo.sectionDepth
    );
    const lookupVal = responses.get(lookupFieldName, null);
    const lookupIsYear =
      lookupVal != null ? lookupVal.toString().length === 4 : false;
    const lookupDob =
      lookupVal != null && lookupVal.value !== ''
        ? moment(ukYearOrDateToIso(responses.get(lookupFieldName, null).value))
        : null;

    //Check lookup
    if (lookupDob != null) {
      similarDates = lookupIsYear
        ? currentDob.year() === parseInt(lookupVal, 10)
        : Math.abs(currentDob.diff(lookupDob, 'days')) === 0;

      if (similarDates) break;
    }
  }

  return similarDates;
};

const startRepeatableSection = sectionName => {
  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const questions = questionnaire.questions;
  const section = questionnaire.sections.find(x => x.name === sectionName);
  const prevFieldInfo = sharedFieldInfo;
  const prevQuestion = questions.find(x => x.id === prevFieldInfo.questionId);
  const sectionQuestions = questions
    .filter(x => x.section.id === section.id)
    .sort((x, y) => {
      return x.sequence - y.sequence;
    });
  const nextQuestion = sectionQuestions[0];

  // Get next available sectionInstanceId
  const sectionInstanceId = getNextSectionInstanceId(
    nextQuestion.section.id,
    prevFieldInfo
  );

  let sectionDepth = 1;

  // Check whether we need to increment sectionDepth
  if (prevQuestion.section.id === nextQuestion.section.id) {
    sectionDepth = prevFieldInfo.sectionDepth + 1;
  }

  const nextFieldInfo = {
    fieldName: getFieldName(
      nextQuestion.id,
      nextQuestion.section.id,
      sectionInstanceId,
      1,
      sectionDepth
    ),
    questionId: nextQuestion.id,
    sectionId: nextQuestion.section.id,
    sectionInstanceId: sectionInstanceId,
    sectionIndex: 1,
    sectionDepth: sectionDepth
  };

  // Add our section source field (parent)
  setSectionSourceField(prevFieldInfo, nextFieldInfo);

  // Setup section context (if required)
  setSectionContext(prevFieldInfo, nextFieldInfo);

  return nextFieldInfo;
};

const setSectionSourceField = (prevFieldInfo, nextFieldInfo) => {
  const dispatch = store.dispatch;
  const nextContextKey = getContextKey(
    nextFieldInfo.sectionId,
    nextFieldInfo.sectionInstanceId
  );
  const sectionContext = { SectionSourceField: prevFieldInfo.fieldName };

  dispatch(
    setContext({
      key: nextContextKey,
      value: sectionContext
    })
  );
};

const setSectionContext = (prevFieldInfo, nextFieldInfo) => {
  const dispatch = store.dispatch;
  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const currentQuestion = questionnaire.questions.find(
    x => x.id === prevFieldInfo.questionId
  );
  const currentContextKey =
    prevFieldInfo && currentQuestion.section.repeatable
      ? getContextKey(prevFieldInfo.sectionId, prevFieldInfo.sectionInstanceId)
      : null;
  const nextContextKey = getContextKey(
    nextFieldInfo.sectionId,
    nextFieldInfo.sectionInstanceId
  );

  const filteredContext = currentQuestion.questionSupport.filter(
    x =>
      x.groupId == null &&
      x.forNextSection === true &&
      ((x.sectionDepth === 1 &&
        (nextFieldInfo == null || nextFieldInfo.sectionDepth == null)) ||
        x.sectionDepth === nextFieldInfo.sectionDepth)
  );

  if (filteredContext.length > 0 && currentContextKey !== nextContextKey) {
    const parsedContext = {};

    filteredContext.forEach(x => {
      let value = x.value == null ? '' : x.value;

      if (value.indexOf(fieldStart) !== -1) {
        const alias = x.value.substring(2, x.value.length - 2);
        value = getAliasedResponse(alias, prevFieldInfo);
      }

      if (isNumeric(value)) {
        value = !isNumeric(value) ? value : parseFloat(value);
      } else {
        value =
          value.indexOf('[') !== -1 && value.indexOf(']') !== -1
            ? JSON.parse(value)
            : value;
      }

      parsedContext[x.key] = coerceTypes(value);
    });

    dispatch(
      setContext({
        key: nextContextKey,
        value: parsedContext
      })
    );
  }
};

const getNextSectionInstanceId = (sectionId, prevFieldInfo) => {
  const questionnaireState = store.getState().newQuestionnaire;
  const contexts = questionnaireState.contexts;
  const responses =
    editedResponses == null ? questionnaireState.responses : editedResponses;

  let foundInstanceId = null;

  //Grab all our repeating section context
  const sectionContext = getSectionContext(sectionId, contexts);

  // Check if any have our prevFieldInfo as their SectionSourceField (parent field)
  // Meaning we have existing context for this section instance
  sectionContext.keySeq().forEach(x => {
    const sourceSectionField = sectionContext.getIn(
      [x, 'SectionSourceField'],
      emptyObject
    );

    if (
      sourceSectionField !== emptyObject &&
      sourceSectionField === prevFieldInfo.fieldName
    ) {
      const sectionInfo = getSectionInfoFromKey(x);
      foundInstanceId = sectionInfo.sectionInstanceId;
    }
  });

  // If we have one return it
  if (foundInstanceId != null) {
    return foundInstanceId;
  }

  // Otherwise find the next unused sectionInstanceId
  // The following filters on section id then produces a unique set of sectionInstanceIds
  const instanceIds = responses
    .filter(x => x.sectionId === sectionId && x.sectionInstanceId != null)
    .map(x => x.sectionInstanceId)
    .toSet();

  return instanceIds.size > 0 ? Math.max(...instanceIds) + 1 : 1;
};

const getSectionContext = (sectionId, contexts) => {
  let sectionContext = new Immutable.Map();

  for (
    let sectionInstanceId = 1;
    sectionInstanceId < 999;
    sectionInstanceId++
  ) {
    const instanceKey = getContextKey(sectionId, sectionInstanceId);
    const instanceContext = contexts.getIn([instanceKey], emptyObject);

    if (instanceContext !== emptyObject) {
      sectionContext = sectionContext.setIn([instanceKey], instanceContext);
    } else {
      break;
    }
  }

  return sectionContext;
};

const getGlobalContext = contexts => {
  let globalContext = new Immutable.Map();

  contexts
    .keySeq()
    .filter(x => {
      return x.substring(0, 7) !== 'SECTION';
    })
    .forEach(x => {
      globalContext = globalContext.set(x, contexts.get(x, ''));
    });

  return globalContext;
};

export const expressionParser = {
  evaluateExpression
};
