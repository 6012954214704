import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import { addAutoHidingAlert } from '../../features/alerts-slice';

const AutoLogout = ComposedClass => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        warningTime: 1000 * 60 * 5,
        signoutTime: 1000 * 60 * 6
      };
    }

    componentDidMount() {
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for (let i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      this.setTimeout();
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      const { loggedIn, user } = this.props;
      
      if(loggedIn && user && (user.isViewOnlyAdmin || user.isAdmin || user.isSuperAdmin)){
        this.setState({ warningTime: 1000 * 60 * 19 });
        this.setState({ signoutTime: 1000 * 60 * 20 });
      }
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      const { alert, loggedIn } = this.props;

      if (loggedIn) {
        const message = 'You will be logged out automatically in 1 minute';
        alert({ message, variant: 'warning', autoDismiss: true });
        console.log(message);
      }
    };

    logout = () => {
      const { loggedIn } = this.props;
      if (loggedIn) {
        // Send a logout request to the API
        const logoutMsg = 'You were logged out due to inactivity';
        console.log(logoutMsg);
        this.destroy();
      }
    };

    destroy = () => {
      //clear the session
      history.push('/signout');
    };

    render() {
      return (
        <div>
          <ComposedClass {...this.props} />
        </div>
      );
    }
  };
};

const mapDispatch = {
  alert: addAutoHidingAlert
};

const mapState = state => {
  const { loggedIn, user } = state.authentication;

  return {
    loggedIn,
    user
  };
};

export default ComposedClass =>
  connect(mapState, mapDispatch)(AutoLogout(ComposedClass));
