export const authentication = () => {
  // return authorization header with jwt token
  const storedUser = localStorage.getItem('user');
  let user = storedUser ? JSON.parse(localStorage.getItem('user')) : null;

  if (user && user.token) {
    return {
      Authorization: 'Bearer ' + user.token,
      pragma: 'no-cache',
      'cache-control': 'no-cache'
    };
  } else {
    return {};
  }
};

export const currentUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return user;
  } else return null;
};
