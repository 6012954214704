import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { isEmpty } from '../../../helpers/types';
import { authentication } from '../../../helpers/authentication';
import dnaBackground from '../../../images/dna2.png';
import Styles from './styles';
import Logo from '../Logo';

const styles = theme => Styles(theme);

class TopBar extends React.Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDrawerToggle = () => {
    this.props.onDrawerToggle();
  };

  render() {
    const { classes, theme } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <AppBar className={classes.appBar}>
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <div className={classes.toolbarBackground}>
            <img
              src={dnaBackground}
              alt="dna"
              className={classes.dnaBackground}
            />
          </div>
          <IconButton
            aria-label="open drawer"
            onClick={this.handleDrawerToggle}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.rightLogoContainer}>
            <div className={classes.rightLogo}>
              {theme?.customLogo?.imagedata != null ? (
                <a
                  href={theme?.customLogo?.link}
                  title={theme?.customLogo?.linkTitle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt={theme?.customLogo?.linkTitle}
                    src={theme.customLogo.imagedata}
                    className={classes.georgesLogo}
                  />
                </a>
              ) : null}
              {theme?.trustLogo?.link != null ? (
                <a
                  href={theme?.trustLogo?.link}
                  title={theme?.trustLogo?.linkTitle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="St Georges Logo"
                    src={theme.trustLogo.imagedata}
                    className={classes.georgesLogo}
                  />
                </a>
              ) : (
                <img
                  alt="St Georges Logo"
                  src={theme.trustLogo.imagedata}
                  className={classes.georgesLogo}
                />
              )}
              <Logo className={classes.fhqsLogo} width={200} height={30.487} />
            </div>
            <IconButton
              className={classes.accountIcon}
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={this.handleClose}
            >
              {!isEmpty(authentication()) ? (
                <div className={classes.noOutline}>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to="/signout"
                  >
                    Sign out
                  </MenuItem>
                </div>
              ) : (
                <div className={classes.noOutline}>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to="/register"
                  >
                    Register
                  </MenuItem>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to="/signin"
                  >
                    Sign in
                  </MenuItem>
                </div>
              )}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(TopBar);
