import React from 'react';
import sadCloud from './sad-cloud.svg';
import { withStyles } from '@material-ui/core';
import Styles from '../Pages/Admin/styles';

const styles = theme => Styles(theme);

const SadCloud = props => (
  <div className={props.classes.container}>
    <img alt="downloading" src={sadCloud} />
  </div>
);

export default withStyles(styles)(SadCloud);
