const styles = () => ({
  container: {
    marginTop: 25
  },
  boldSubtitle: {
    fontWeight: 500
  }
});

export default styles;
