import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { isEmpty } from '../../helpers/types';
import Notification from './Notification/Notification';
import {
  processAlertQueue,
  setCurrentAlert,
  openAlerts,
  closeAlerts
} from '../../features/alerts-slice';
import { RootState } from '../../types/stores';

type Props = PropsFromRedux;
type PropsFromRedux = ConnectedProps<typeof connector>;

const Alerts: React.FC<Props> = props => {
  const processQueue = () => {
    const { dispatch, alertQueue } = props;
    dispatch(processAlertQueue());

    if (alertQueue && alertQueue.length > 1) {
      dispatch(setCurrentAlert());
      dispatch(openAlerts());
    }
  };

  const handleClose = (event, reason) => {
    const { dispatch } = props;
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeAlerts());
  };

  const handleExited = () => {
    processQueue();
  };

  const { open, currentAlert } = props;
  let key = -1,
    message = '',
    variant = '';

  if (currentAlert && !isEmpty(currentAlert)) {
    key = currentAlert.key || -1;
    message = currentAlert.message;
    variant = currentAlert.variant;

    return key !== -1 ? (
      <Notification
        message={message}
        key={key}
        variant={variant}
        open={open}
        handleClose={handleClose}
        handleExited={handleExited}
      />
    ) : null;
  }

  return null;
};

const mapState = (state: RootState) => {
  const { currentAlert, open, alertQueue } = state.alerts;

  return {
    currentAlert,
    open,
    alertQueue
  };
};

const connector = connect(mapState);

export default connector(Alerts);
