export const ruleRunner = (field, name, isRequired, ...validations) => {
  return state => {
    //Drop out if !isRequired and answer blank
    if (
      !isRequired &&
      ((typeof state[field] === 'string' && state[field] === '') ||
        (typeof state[field] === 'number' && state[field] === 0))
    ) {
      return null;
    }

    for (let validation of validations) {
      let errorMessageFunc = validation(state[field], state, field);
      if (errorMessageFunc) {
        return { [field]: errorMessageFunc(name) };
      }
    }
    return null;
  };
};

export const run = (state, runners) => {
  if (runners !== undefined) {
    return runners.reduce((memo, runner) => {
      return Object.assign(memo, runner(state));
    }, {});
  } else return {};
};
