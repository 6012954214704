import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { DisplayTypeConstants } from '../../../helpers/constants';
import Styles from './styles';
import classNames from 'classnames';

const styles = theme => Styles(theme);

function QuestionCard(props) {
  const {
    classes,
    cardTitle,
    cardSubtitle,
    question,
    answer,
    footer,
    navButtons,
    onNextClicked,
    onBackClicked,
    titleColour
  } = props;
  const { cardHeaderLeft, cardHeaderPrimary, cardHeaderSecondary } = classes;

  const titleClass = classNames(cardHeaderLeft, {
    [cardHeaderPrimary]: titleColour === 'primary',
    [cardHeaderSecondary]: titleColour === 'secondary'
  });

  return (
    <div>
      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.cardHeaderContainer}>
            <div className={titleClass}>
              <Typography variant="h5" className={classes.headerTitle}>
                {cardTitle}
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.headerTitle}
                color="textSecondary"
              >
                {cardSubtitle}
              </Typography>
            </div>
            <div className={classes.cardHeaderRight}></div>
          </div>

          <CardContent className={classes.cardContent}>
            {answer.props.currentQuestion.answerType.displayType !==
            DisplayTypeConstants.NoAnswer ? (
              <div className={classes.contentContainer}>
                <div className={classes.questionContainer}>{question}</div>
                <div className={classes.answerContainer}>{answer}</div>
              </div>
            ) : (
              <div className={classes.questionContainerFullWidth}>
                {question}
              </div>
            )}
          </CardContent>
          {footer ? (
            <CardActions className={classes.cardActions}>{footer}</CardActions>
          ) : null}
        </Card>
      </div>
      {navButtons ? (
        <div className={classes.container}>
          <div className={classes.buttonStrip}>
            <div>
              <Button
                variant="contained"
                className={classes.prevButton}
                color="primary"
                onClick={onBackClicked}
              >
                Back
              </Button>
              <Button
                variant="contained"
                className={classes.nextButton}
                color="primary"
                onClick={onNextClicked}
                type="submit"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

QuestionCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  titleColour: PropTypes.oneOf(['primary', 'secondary']),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  question: PropTypes.node,
  answer: PropTypes.node,
  footer: PropTypes.node,
  navButtons: PropTypes.bool,
  onNextClicked: PropTypes.func,
  onBackClicked: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(QuestionCard);
