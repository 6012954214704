const styles = theme => ({
  formControl: {
    width: '100%',
    '& label.Mui-focused': { color: theme.palette.primary.dark },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.dark
    }
  },
  select: {
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  }
});
export default styles;
