import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import TitleCard from '../../Cards/TitleCard';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import { QuestionnaireStatusConstants } from '../../../helpers/constants';
import { history } from '../../../helpers/history';
import Styles from './styles';
import DialogOkCancel from '../../DialogOkCancel';
import loader from '../../Loader/Loader';
import Helix from '../../Helix';
import Markdown from '../../Markdown/Markdown';
import {
  setQuestionnaireSubmitted,
  setShowConfirmDialog
} from '../../../features/questionnaire/questionnaire-slice';
import {
  areAllSectionsComplete,
  saveResponsesToServer,
  setQuestionnaireCompletionStatus
} from '../../../features/questionnaire/questionnaire-thunks';

const styles = theme => Styles(theme);

class Completed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    };
  }

  onSubmitClicked = () => {
    const { setShowConfirmDialog } = this.props;
    setShowConfirmDialog(true);
  };

  onBackClicked = () => {
    history.push('/start');
  };

  handleDialogOK = async () => {
    const {
      setQuestionnaireSubmitted,
      setQuestionnaireCompletionStatus,
      setShowConfirmDialog,
      saveResponsesToServer
    } = this.props;

    this.setState({ submitting: true });

    setQuestionnaireSubmitted(true);
    await setQuestionnaireCompletionStatus();
    setShowConfirmDialog(false);
    await saveResponsesToServer();
  };

  handleDialogCancel = () => {
    const { setShowConfirmDialog } = this.props;
    setShowConfirmDialog(false);
  };

  async componentDidMount() {
    const { questionnaireStatus, areAllSectionsComplete } = this.props;

    const canSubmit = await areAllSectionsComplete();
    const submitted =
      questionnaireStatus === QuestionnaireStatusConstants.Submitted;

    if (!canSubmit || submitted) {
      history.push('/thanks');
    }
  }

  componentDidUpdate(prevProps) {
    const { questionnaireStatus } = this.props;
    const submitted =
      questionnaireStatus === QuestionnaireStatusConstants.Submitted;
    const { questionnaireStatus: prevStatus } = prevProps;

    if (submitted && prevStatus !== questionnaireStatus) {
      history.push('/thanks');
    }
  }

  render() {
    const { classes, showConfirmDialog, serviceConfiguration } = this.props;
    const { submitting } = this.state;
    const thankyouText = serviceConfiguration?.completedPageThankYouText;

    return (
      <TitleCard
        cardTitle="Questionnaire Complete"
        cardSubtitle="Submission Required"
        content={
          submitting ? (
            <Helix />
          ) : (
            <Fragment>
              <div className={classes.submitContainer}>
                <div className={classes.textContainer}>
                  <Typography variant="subtitle1">
                    {thankyouText
                      ? <Markdown>{thankyouText}</Markdown>
                      : `Thank you for completing the questionnaire. Once you have submitted your responses by clicking the submit button below, you will not be able to edit them again. Please take your time and only submit when you have included as much information as possible. Until then you can navigate to all sections of the questionnaire via the menu on the left.
    
                                            If you are sure you are happy with your responses, go ahead and click submit now. Your responses will be processed as soon as possible by the South West Thames Regional Genetics Service`}
                  </Typography>
                </div>
              </div>
              <DialogOkCancel
                text="Are you sure you wish to submit? This action is final and you will no longer be able to edit your responses if you click OK."
                title="Confirmation Required"
                handleOK={this.handleDialogOK}
                handleCancel={this.handleDialogCancel}
                open={showConfirmDialog}
                fullScreen={false}
              />
            </Fragment>
          )
        }
        footer={
          submitting ? null : (
            <div className={classes.container}>
              <div>
                <Button
                  variant="contained"
                  className={classes.button}
                  color="primary"
                  onClick={this.onBackClicked}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  color="primary"
                  onClick={this.onSubmitClicked}
                >
                  Submit
                </Button>
              </div>
            </div>
          )
        }
      />
    );
  }
}

const mapDispatch = {
  setShowConfirmDialog,
  setQuestionnaireSubmitted,
  saveResponsesToServer,
  setQuestionnaireCompletionStatus,
  areAllSectionsComplete
};

const mapState = state => {
  const {
    questionnaire,
    questionnaireStatus,
    sectionStatuses,
    showConfirmDialog
  } = state.newQuestionnaire;

  const { service } = state.organisation;

  return {
    questionnaire,
    questionnaireStatus,
    sectionStatuses,
    showConfirmDialog,
    serviceConfiguration: service?.serviceConfiguration
  };
};

const connectedCompleted = compose(
  loader,
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(Completed);

export { connectedCompleted as Completed };
