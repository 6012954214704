import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, VerifyTwoFactorCodeResponse } from '../types/users';
import { api } from '../services/api/dataApi';
import { extractRTKQueryError } from '../services/api/apiConfig';

const storedUser = localStorage.getItem('user');
const user = storedUser ? JSON.parse(storedUser) : null;

export interface AuthenticationState {
  user?: User | undefined;
  loginErrors?: string | undefined;
  twoFactorErrors?: string | undefined;
  awaitingTwoFactor: boolean;
  loggingIn: boolean;
  loggedIn: boolean;
  unconfirmed: boolean;
}

const initialState: AuthenticationState = {
  user,
  loginErrors: undefined,
  twoFactorErrors: undefined,
  loggedIn: !!(user && user.token),
  awaitingTwoFactor: false,
  loggingIn: false,
  unconfirmed: false
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout(state) {
      localStorage.removeItem('user');
      state.user = undefined;
      state.loggedIn = false;
      state.awaitingTwoFactor = false;
      state.loggingIn = false;
      state.unconfirmed = false;
      state.loginErrors = undefined;
      state.twoFactorErrors = undefined;
    },
    setUnconfirmed(state, action: PayloadAction<boolean>) {
      state.unconfirmed = action.payload;
      state.loginErrors = undefined;
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.user = payload as User;
        if (state.user.isAdmin || state.user.isViewOnlyAdmin) {
          if (state.user.twoFactorRequired) {
            state.loggedIn = false;
            state.awaitingTwoFactor = true;
          } else {
            state.loggedIn = true;
            state.awaitingTwoFactor = false;
          }
        } else {
          state.loggedIn = true;
        }
        state.loggingIn = false;
        state.loginErrors = undefined;
      })
      .addMatcher(api.endpoints.login.matchPending, state => {
        state.user = undefined;
        state.loggedIn = false;
        state.awaitingTwoFactor = false;
        state.loggingIn = true;
        state.loginErrors = undefined;
      })
      .addMatcher(api.endpoints.login.matchRejected, (state, action) => {
        if (action.error && action.error.name !== 'ConditionError') {
          const { error, status } = extractRTKQueryError(action);
          state.loginErrors = error;
          state.user = undefined;
          state.loggedIn = false;
          state.loggingIn = false;
          console.log(`login failed with: ${status}`);
          state.awaitingTwoFactor = false;
        }
      })
      .addMatcher(
        api.endpoints.verifyTwoFactorCode.matchFulfilled,
        (state, { payload }) => {
          const response = payload as VerifyTwoFactorCodeResponse;
          if (state.user) {
            state.user.token = response.token;
          }
          state.loggingIn = false;
          state.loggedIn = true;
          state.awaitingTwoFactor = false;
          state.loginErrors = undefined;
          state.twoFactorErrors = undefined;
        }
      )
      .addMatcher(
        api.endpoints.verifyTwoFactorCode.matchRejected,
        (state, action) => {
          if (action.error && action.error.name !== 'ConditionError') {
            const { error, status } = extractRTKQueryError(action);
            if (status === 400) {
              state.twoFactorErrors = error;
            }
            console.log(`VerifyTwoFactorCode failed with: ${status}`);
          }
        }
      );
  }
});

export const { logout, setUnconfirmed } = authenticationSlice.actions;
export default authenticationSlice.reducer;
