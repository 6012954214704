import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Styles from './styles';
import Markdown from '../../Markdown/Markdown';

const styles = theme => Styles(theme);

const Question = props => {
  const { classes, questionText, hasMarkdown } = props;
  const isHtml = /<[a-z][\s\S]*>/i.test(questionText);
  const hasQuestion = questionText && questionText.length > 0;

  return (
    <div className={classes.container}>
      {hasQuestion && hasMarkdown ? (
        <Typography variant="subtitle1">
          <Markdown>{questionText}</Markdown>
        </Typography>
      ) : hasQuestion && isHtml ? (
        <div
          dangerouslySetInnerHTML={{ __html: questionText }}
          className={classes.subtitle1}
        />
      ) : hasQuestion ? (
        <Typography variant="subtitle1">{questionText}</Typography>
      ) : null}
    </div>
  );
};

Question.propTypes = {
  classes: PropTypes.object.isRequired,
  hasMarkdown: PropTypes.bool.isRequired,
  questionText: PropTypes.string
};

export default withStyles(styles)(Question);
