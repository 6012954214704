const styles = () => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  cookieBanner: {
    position: 'fixed !important',
    bottom: '0 !important',
    left: '0 !important',
    width: '100% !important',
    zIndex: '9999 !important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    height: 57,
    backgroundColor: 'rgba(52, 64, 81, 0.7) !important',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',

    '& .cookie-message': {
      lineHeight: '15px !important',
      fontSize: 10,
      fontWeight: 300,
      display: 'block',
      width: 'calc(100vw - 100px)'
    }
  }
});

export default styles;
