export const removeWhiteSpaceAndTitleCase = (input: string): string => {
  let result = titleCase(input);
  result = result.replace(/\s/g, '');
  return result;
};

export const titleCase = (input: string): string =>
  input.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const capitalizeFirstLetter = (input: string): string =>
  input.charAt(0).toUpperCase() + input.slice(1);

export const addSpacesAndSentenceCase = (input: string): string =>
  sentenceCase(
    input
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase()
  );

export const sentenceCase = (input: string): string =>
  input.replace(/\.\s+([a-z])[^.]|^(\s*[a-z])[^.]/g, s =>
    s.replace(/([a-z])/, s => s.toUpperCase())
  );
