import { createAsyncThunk } from '@reduxjs/toolkit';
import { FieldInfo } from '../../types/questionnaire';
import { AppDispatch, RootState } from '../../types/stores';
import { getFieldName } from './unrefactored/fields';

export const aliasedResponse = createAsyncThunk<
  string | null,
  { alias: string; fieldInfo: FieldInfo },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'newQuestionnaire/aliasedResponse',
  async ({ alias, fieldInfo }, thunkAPI) => {
    const { getState } = thunkAPI;
    const questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;
    const responses = questionnaireState.responses;

    const aliasedQuestion = questionnaire?.questions.find(
      x => x.alias === alias
    );

    if (!aliasedQuestion) {
      console.log(`Alias ${alias} not found`);
      return null;
    }

    const aliasedFieldName = getFieldName(
      aliasedQuestion.id,
      fieldInfo.sectionId,
      fieldInfo.sectionInstanceId,
      fieldInfo.sectionIndex,
      fieldInfo.sectionDepth
    );
    const aliasedResponse = responses?.get(aliasedFieldName) || null;

    if (aliasedResponse == null) {
      console.log(`No response found for alias ${alias}`);
      return null;
    }

    return aliasedResponse.value || null;
  }
);
