import { run } from '../../../validation/ruleRunner';
import { fieldValidations } from './validation';
import { isEmpty } from '../../../helpers/types';
import { history } from '../../../helpers/history';
import React, { useEffect, useState } from 'react';
import TitleCard from '../../Cards/TitleCard';
import Helix from '../../Helix';
import Typography from '@material-ui/core/Typography';
import pedigreesIso from '../../../images/pedigrees-iso.png';
import TextField from '../../StyledTextField';
import Button from '@material-ui/core/Button';
import { useLazyGetServiceCodeQuery } from '../../../services/api/dataApi';
import { Link } from 'react-router-dom';
import Styles from './styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Cookie from '../../../helpers/cookies';
import { adminLogout } from '../../../features/admin-slice';
import { logout as authLogout } from '../../../features/authentication-slice';
import { logout as questionnaireLogout } from '../../../features/questionnaire/questionnaire-slice';
import { useAppSelector, useAppDispatch } from '../../../stores/hooks';
import querystring from 'query-string';

const useStyles = makeStyles((theme: Theme) => Styles(theme));

interface ValidationErrors {
  serviceCode?: string | undefined;
}

export const Welcome: React.FC = () => {
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrors | undefined
  >(undefined);
  const [isMounted, setIsMounted] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [serviceCode, setServiceCode] = useState<string | undefined>(undefined);
  const [trigger, result] = useLazyGetServiceCodeQuery();
  const { user, loggedIn } = useAppSelector(state => state.authentication);
  const dispatch = useAppDispatch();

  const { error, isLoading, isSuccess, isError, data } = result;
  const classes = useStyles();

  const qsServiceCode = querystring.parse(location.search);

  useEffect(() => {
    if (qsServiceCode['sc'] != null) {
      setServiceCode(qsServiceCode['sc'].toString());
      runValidation(qsServiceCode['sc'].toString());
      if(!validationErrors) {        
        trigger(qsServiceCode['sc'].toString());
      }

    } else {
      const cookieCode = Cookie.get('serviceCode');

      if (cookieCode && cookieCode.length === 6) {
        setServiceCode(cookieCode);
        runValidation(cookieCode);
      }
    }
  }, []);

  useEffect(() => {
    if (isMounted && isSuccess && data) {
      Cookie.set('serviceCode', serviceCode);
      history.push('/introduction');
      setIsMounted(false);

      return () => {
        setIsMounted(false);
      };
    }
  }, [isMounted, isSuccess, isSuccess, isError, data]);

  const handleSubmit = () => {
    if (validationErrors || !serviceCode) {
      setShowErrors(true);
      return;
    }

    if (loggedIn && user && user.serviceCode !== serviceCode) {
      dispatch(adminLogout());
      dispatch(questionnaireLogout());
      dispatch(authLogout());
    }

    trigger(serviceCode.toUpperCase().trim());
  };

  const handleKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }

    setShowErrors(false);
  };

  const runValidation = (value: string) => {
    let newValidationErrors = run(
      { validationErrors, ...{ serviceCode: value } },
      fieldValidations
    );

    if (isEmpty(newValidationErrors)) {
      newValidationErrors = undefined;
    }

    setServiceCode(value);
    setValidationErrors(newValidationErrors);
  };

  const handleInputChange = (value: string) => {
    runValidation(value);
  };

  const errorFor = (field: string): string | undefined => {
    return validationErrors && validationErrors[field];
  };

  return (
    <TitleCard
      cardTitle="Welcome"
      cardSubtitle="Welcome to the FHQS service"
      content={
        isLoading ? (
          <Helix />
        ) : (
          <div>
            <Typography variant="subtitle1">
              Welcome to the online Family History Questionnaire Service (FHQS).
              If you have used this service before please{' '}
              <Link to="/signin">sign in</Link> to continue.
              <br />
              <br />
            </Typography>
            <div className={classes.isoPedigree}>
              <img src={pedigreesIso} alt="Example pedigrees" />
            </div>
            <Typography variant="subtitle1" paragraph>
              If you are new to this service, please enter your service code in
              the box below and click submit to continue. 
              Your service code can be found on the letter or patient information leaflet you were sent/given to request that you access this online questionnaire.
            </Typography>
            <div className={classes.inputWrapper}>
              <div className={classes.centredInput}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="serviceCode"
                  value={serviceCode || ''}
                  label="Service Registration Code"
                  placeholder="Enter Code Here"
                  margin="dense"
                  onKeyUp={event => handleKeyPressed(event)}
                  onChange={event => handleInputChange(event.target.value)}
                  error={showErrors && !!errorFor('serviceCode')}
                  helperText={showErrors ? errorFor('serviceCode') : null}
                />
                {error && (
                  <Typography
                    variant="subtitle1"
                    paragraph
                    className={classes.errorText}
                  >
                    That service registration code was not found. Please check
                    it and try again.
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )
      }
      footer={
        !isLoading && <Button onClick={() => handleSubmit()}>Submit</Button>
      }
    />
  );
};
