import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Styles from './styles';
import _ from 'lodash';
import { api } from '../../../services/api/dataApi';
import {
  setSelectedServiceCode,
  setServiceCodeActionStatus
} from '../../../features/admin-slice';
import DropDownSelect from '../../DropDownSelect/DropDownSelect';
import { Card } from '@material-ui/core';

const styles = theme => Styles(theme);

class ServiceCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceName: ''
    };
  }

  downloadSubmissions = async event => {
    const {
      allServiceCodes,
      setSelectedServiceCode,
      setServiceCodeActionStatus,
      getSubmissionsByServiceCode,
      isSubmissionsByServiceCodeLoading
    } = this.props;

    const selectedServiceRow = allServiceCodes
      ? allServiceCodes.filter(x => x.service.name === event)[0]
      : '';

    if (!isSubmissionsByServiceCodeLoading) {
      setSelectedServiceCode(selectedServiceRow);
      setServiceCodeActionStatus({ key: serviceCode, status: 'pending' });

      const serviceCode = selectedServiceRow
        ? selectedServiceRow?.serviceCode
        : '';

      try {
        await getSubmissionsByServiceCode(serviceCode, {
          subscribe: false,
          forceRefetch: true
        });
        setServiceCodeActionStatus({ key: serviceCode, status: 'none' });
      } catch (e) {
        setServiceCodeActionStatus({ key: serviceCode, status: 'error' });
      }
    }
  };

  handleServiceNameChanged = field => {
    return e => {
      this.downloadSubmissions(e);
      this.setState({ [field]: e });
    };
  };

  render() {
    const { allServiceCodes, serviceCodeActionStatus, classes } = this.props;

    const data =
      allServiceCodes &&
      allServiceCodes.map(serviceCode => {
        return {
          op: _.get(serviceCodeActionStatus, serviceCode.serviceCode) || 'none',
          ...serviceCode
        };
      });

    if (!data || data.length === 0) return null;

    return (
      data &&
      data.length > 0 && (
        <>
          <div className={classes.scrollOverflow}>
            <Card className={classes.filterCard}>
              <DropDownSelect
                id="serviceName"
                inputProps={{ 'data-lpignore': 'true', autoComplete: 'off' }}
                currentPlaceholder={'Select service name to view submissions'}
                currentValue={this.state.serviceName}
                currentKey={this.state.serviceName}
                currentFieldName="serviceName"
                items={data.map(x => {
                  return {
                    value: x.service.name,
                    key: x.service.name
                  };
                })}
                onFieldChanged={this.handleServiceNameChanged('serviceName')}
                margin="dense"
                fullWidth
              />
            </Card>
          </div>
        </>
      )
    );
  }
}

const mapDispatch = {
  getSubmissionsByServiceCode:
    api.endpoints.submissionDetailsByServiceCode.initiate,
  setSelectedServiceCode,
  setServiceCodeActionStatus
};

const mapState = state => {
  const {
    selectedServiceCode,
    serviceCodeActionStatus,
    isSubmissionsByServiceCodeLoading
  } = state.admin;

  const { allServiceCodes } = state.organisation;

  return {
    allServiceCodes,
    selectedServiceCode,
    serviceCodeActionStatus,
    isSubmissionsByServiceCodeLoading
  };
};

const composedServiceCodes = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(ServiceCodes);

export default composedServiceCodes;
