export const handleResponse = response => {
  return new Promise((resolve, reject) => {
    const contentType = response.headers.get('content-type');
    const isJson = contentType && contentType.includes('json');
    const isBlob =
      contentType &&
      (contentType.includes('pdf') || contentType.includes('text/plain'));

    if (response.ok) {
      if (isJson) {
        response.json().then(json => resolve(json));
      } else if (isBlob) {
        response.blob().then(blob => resolve(blob));
      } else {
        response.text().then(text => resolve(text));
      }
    } else {
      if (isJson) {
        response.json().then(json => {
          reject(json);
        });
      } else {
        response.text().then(text => {
          reject(text);
        });
      }
    }
  });
};

export const handleError = error => {
  return Promise.reject(error && error.message);
};
