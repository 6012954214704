import { QuestionnaireStatusConstants } from '../../../helpers/constants';
import Swirly from '../../Swirly';
import moment from 'moment';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Styles from './styles';
import SadCloud from '../../SadCloud/SadCloud';

const styles = theme => Styles(theme);

class InterestingByServiceCode extends React.Component {
  statusName = status => {
    switch (status) {
      case QuestionnaireStatusConstants.Started:
        return 'Started';
      case QuestionnaireStatusConstants.Complete:
        return 'Complete';
      case QuestionnaireStatusConstants.Submitted:
        return 'Submitted';
      case QuestionnaireStatusConstants.Processed:
        return 'Processed';
      case QuestionnaireStatusConstants.NotStarted:
      default:
        return 'Registered';
    }
  };

  render() {
    const { submissionsByServiceCode, classes, hasApiName } = this.props;

    const data = submissionsByServiceCode.reduce((acc, submission) => {
      const cloned = { ...submission };

      // Late submission
      if (cloned.completed || cloned.submitted || cloned.processed) {
        const completed = cloned.completed ? moment(cloned.completed) : null;
        const submitted = cloned.submitted ? moment(cloned.submitted) : null;
        const processed = cloned.processed ? moment(cloned.processed) : null;
        const downloaded = cloned.downloaded ? moment(cloned.downloaded) : null;
        const now = moment();

        if (
          cloned.status === QuestionnaireStatusConstants.Complete &&
          completed != null &&
          submitted == null &&
          now.diff(completed, 'days') > 3
        ) {
          acc.push(cloned);
        }

        if (
          cloned.status === QuestionnaireStatusConstants.Submitted &&
          submitted != null &&
          ((hasApiName && processed == null) ||
            (!hasApiName && downloaded == null)) &&
          now.diff(submitted, 'days') > 3
        ) {
          acc.push(cloned);
        }
      }

      // Late processed
      return acc;
    }, []);

    const limitWidth = (width, breakWords) => ({
      maxWidth: width,
      whiteSpace: 'pre-wrap',
      ...(breakWords ? { wordBreak: 'break-all' } : {})
    });

    const standardCellStyle = {
      fontSize: 13
    };

    const columns = [
      {
        title: 'Action',
        field: 'thing',
        sorting: false,
        cellStyle: {
          padding: 0,
          textAlign: 'center'
        },
        headerStyle: {
          textAlign: 'center',
          ...standardCellStyle
        },
        render: row => {
          const result =
            row.op === 'pending' ? (
              <Swirly />
            ) : row.op === 'error' ? (
              <SadCloud label="Server Error" />
            ) : null;

          if (result != null) return result;

          return null;
        }
      },
      {
        title: 'NhsNo',
        field: 'nhsNo',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Name',
        field: 'name',
        cellStyle: { ...limitWidth(160, false), ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Email',
        field: 'email',
        cellStyle: { ...limitWidth(240, true), ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Status',
        field: 'status',
        cellStyle: { ...standardCellStyle },
        render: row => this.statusName(row.status),
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Progress',
        field: 'sectionStatus',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Registered',
        field: 'registered',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.registered ? moment(row.registered).format('DD/MM/YY') : null
      },
      {
        title: 'Started',
        field: 'started',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.started ? moment(row.started).format('DD/MM/YY') : null
      },
      {
        title: 'Completed',
        field: 'completed',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.completed ? moment(row.completed).format('DD/MM/YY') : null
      },
      {
        title: 'Submitted',
        field: 'submitted',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.submitted ? moment(row.submitted).format('DD/MM/YY') : null
      },
      ...(hasApiName
        ? [
            {
              title: 'Processed',
              field: 'processed',
              cellStyle: { ...standardCellStyle },
              headerStyle: { ...standardCellStyle },
              render: row =>
                row.processed ? moment(row.processed).format('DD/MM/YY') : null
            }
          ]
        : [
            {
              title: 'Downloaded',
              field: 'downloaded',
              cellStyle: { ...standardCellStyle },
              headerStyle: { ...standardCellStyle },
              render: row =>
                row.downloaded
                  ? moment(row.downloaded).format('DD/MM/YY')
                  : null
            }
          ])
    ];

    return (
      data.length > 0 && (
        <>
          <div className={classes.scrollOverflow}>
            <MaterialTable
              title={
                <Typography color="inherit" variant="subtitle1">
                  Potential problem submissions
                </Typography>
              }
              columns={columns}
              data={data}
              onRowClick={this.downloadSummary}
              options={{
                rowStyle: row => ({
                  cursor: row.submitted
                    ? 'pointer !important'
                    : 'default !important',
                  color: 'red',
                  fontSize: '6pt !important'
                }),
                filtering: false,
                sorting: false,
                paging: false,
                search: false
              }}
              style={{
                borderCollapse: 'initial'
              }}
            />
          </div>
          <br />
          <br />
        </>
      )
    );
  }
}

const mapState = state => {
  const { submissionsByServiceCode, actionStatus, selectedServiceCode } =
    state.admin;

  const hasApiName = !!selectedServiceCode?.apiName;

  return {
    actionStatus,
    submissionsByServiceCode,
    hasApiName
  };
};

const composedInterestingByServiceCode = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState)
)(InterestingByServiceCode);

export default composedInterestingByServiceCode;
