import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

const OrganisationRoute = ({ ...props }) => (
  <Route
    {...props}
    render={renderProps =>
      props.organisation ? (
        props.Component ? (
          props.Component
        ) : (
          props.render()
        )
      ) : (
        <Redirect
          to={{ pathname: '/', state: { from: renderProps.location } }}
        />
      )
    }
  />
);

const mapStateToProps = state => {
  const { organisation } = state.organisation;

  return {
    organisation
  };
};

const composedOrganisationRoute = compose(connect(mapStateToProps))(
  OrganisationRoute
);

export { composedOrganisationRoute as OrganisationRoute };
