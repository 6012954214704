import { store } from '../../../stores/store';
import { expressionParser } from './expressionParser';
import {
  getContextKey,
  emptyObject,
  getFieldInfo,
  getFieldInfoFromName
} from './fields';
import { isNumeric } from '../../../helpers/types';
import { isNavConstant, NavigationConstants } from '../../../helpers/constants';

const getNextQuestion = (currentFieldInfo, testResponses = null) => {
  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const currentQuestion = questionnaire.questions.find(
    x => x.id === currentFieldInfo.questionId
  );

  // Do post navigation expression if necessary first
  let nextFieldInfo = null,
    prevFieldInfo = null;

  if (currentQuestion.postNavigationExpression != null) {
    nextFieldInfo = processNavigationExpression(
      currentQuestion.postNavigationExpression,
      currentFieldInfo,
      testResponses
    );
    // Otherwise find following question
  } else {
    nextFieldInfo = getFollowingQuestion(currentFieldInfo);
  }

  if (isNavConstant(nextFieldInfo)) {
    return Promise.resolve(processNavConstants(null, nextFieldInfo));
  }

  // Keep looping through till we have don't have a preNavigationExpression
  let nextQuestion = questionnaire.questions.find(
    x => x.id === nextFieldInfo.questionId
  );

  while (
    nextFieldInfo !== prevFieldInfo &&
    nextQuestion.preNavigationExpression != null
  ) {
    prevFieldInfo = nextFieldInfo;
    nextFieldInfo = processNavigationExpression(
      nextQuestion.preNavigationExpression,
      nextFieldInfo,
      testResponses
    );

    if (isNavConstant(nextFieldInfo)) {
      return Promise.resolve(processNavConstants(null, nextFieldInfo));
    }

    // eslint-disable-next-line
    nextQuestion = questionnaire.questions.find(
      x => x.id === nextFieldInfo.questionId
    );
  }

  return Promise.resolve(nextFieldInfo);
};

const processNavigationExpression = (
  navigationExpression,
  currentFieldInfo,
  testResponses = null
) => {
  const result = expressionParser.evaluateExpression(
    navigationExpression,
    currentFieldInfo,
    testResponses
  );

  // Got a nav constant back
  if (isNavConstant(result)) {
    return processNavConstants(currentFieldInfo, result);

    // Got a repeatable section field
  } else if (result.sectionInstanceId != null) {
    return result;

    // Got a question id
  } else if (isNumeric(result)) {
    const questionnaireState = store.getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;
    const nextQuestion = questionnaire.questions.find(x => x.id === result);

    const nextFieldInfo = getFieldInfo(
      nextQuestion.id,
      nextQuestion.section.id,
      currentFieldInfo.sectionInstanceId,
      currentFieldInfo.sectionIndex,
      currentFieldInfo.sectionDepth
    );
    return nextFieldInfo;
  } else {
    // REALLY should not get here
    // eslint-disable-next-line no-debugger
    debugger;
  }
};

const processNavConstants = (currentFieldInfo, navConstant) => {
  switch (navConstant) {
    // Do nothing (stay on current question)
    case NavigationConstants.CURRENT_QUESTION:
      return currentFieldInfo;

    // Get following question
    case NavigationConstants.NEXT_QUESTION:
      return getFollowingQuestion(currentFieldInfo);

    // Go to next section
    case NavigationConstants.NEXT_SECTION:
      return gotoNextSection(currentFieldInfo);

    // Go to next loop
    case NavigationConstants.NEXT_LOOP:
      return gotoNextLoopSection(currentFieldInfo);

    // End questionnaire
    case NavigationConstants.END_QUESTIONNAIRE:
      return navConstant;

    default:
      // Shouldn't ever get here!!!!!!!
      // eslint-disable-next-line no-debugger
      debugger;
      return;
  }
};

const gotoNextSection = currentFieldInfo => {
  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const questions = questionnaire.questions;
  const sections = questionnaire.sections.filter(x => x.repeatable === false);

  const currentSectionIndex = sections.findIndex(
    x => x.id === currentFieldInfo.sectionId
  );
  const nextSection = sections[currentSectionIndex + 1];

  if (nextSection != null) {
    const sectionQuestions = questions.filter(
      x => x.section.id === nextSection.id
    );
    const nextQuestion = sectionQuestions[0];

    return getFieldInfo(nextQuestion.id, nextQuestion.section.id);
  } else {
    // End of questionnaire
    return NavigationConstants.END_QUESTIONNAIRE;
  }
};

const gotoNextLoopSection = currentFieldInfo => {
  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const contexts = questionnaireState.contexts;

  const currentQuestion = questionnaire.questions.find(
    x => x.id === currentFieldInfo.questionId
  );
  const contextKey = getContextKey(
    currentFieldInfo.sectionId,
    currentFieldInfo.sectionInstanceId
  );
  const sectionContext = contexts.get(contextKey, emptyObject);
  const sectionSourceField = sectionContext.get('SectionSourceField', '');
  const sectionLoopCount = sectionContext.get('LoopCount', 0);
  const sectionQuestions = questionnaire.questions
    .filter(x => x.section.id === currentQuestion.section.id)
    .sort((x, y) => {
      return x.sequence - y.sequence;
    });

  let nextQuestion = null,
    nextSectionIndex = currentFieldInfo.sectionIndex,
    nextSectionInstanceId = currentFieldInfo.sectionInstanceId,
    nextSectionDepth = currentFieldInfo.sectionDepth;

  // Check if we're on last loop
  if (currentFieldInfo.sectionIndex === sectionLoopCount) {
    // We're dropping out the loop so find our previous field before this repeating section
    const prevFieldInfo = getFieldInfoFromName(sectionSourceField);
    const followingFieldInfo = getFollowingQuestion(prevFieldInfo);

    if (isNavConstant(followingFieldInfo)) {
      return processNavConstants(null, followingFieldInfo);
    }

    nextQuestion = questionnaire.questions.find(
      x => x.id === followingFieldInfo.questionId
    );
    nextSectionIndex = followingFieldInfo.sectionIndex;
    nextSectionInstanceId = followingFieldInfo.sectionInstanceId;
    nextSectionDepth = followingFieldInfo.sectionDepth;

    // Otherwise increment section index and keep the rest of the section info
    // the same
  } else {
    nextQuestion = sectionQuestions[0];
    nextSectionIndex = currentFieldInfo.sectionIndex + 1;
    nextSectionInstanceId = currentFieldInfo.sectionInstanceId;
    nextSectionDepth = currentFieldInfo.sectionDepth;
  }

  const nextFieldInfo = getFieldInfo(
    nextQuestion.id,
    nextQuestion.section.id,
    nextSectionInstanceId,
    nextSectionIndex,
    nextSectionDepth
  );

  return nextFieldInfo;
};

const getFollowingQuestion = currentFieldInfo => {
  // NOTE - This just gets the question in line after the provided field info
  // it DOES NOT evaluate navigation formulae. They must be evaluated before this.

  const questionnaireState = store.getState().newQuestionnaire;
  const questionnaire = questionnaireState.questionnaire;
  const contexts = questionnaireState.contexts;
  let resultFieldInfo = {},
    currentIndex = -1,
    nextQuestion = null;

  //Are we in a repeating section
  if (currentFieldInfo.sectionInstanceId != null) {
    const contextKey = getContextKey(
      currentFieldInfo.sectionId,
      currentFieldInfo.sectionInstanceId
    );
    const sectionContext = contexts.get(contextKey, emptyObject);
    const sectionLoopCount = sectionContext.get('LoopCount', 0);
    const sectionSourceField = sectionContext.get('SectionSourceField', '');
    const sectionQuestions = questionnaire.questions
      .filter(x => x.section.id === currentFieldInfo.sectionId)
      .sort((x, y) => {
        return x.sequence - y.sequence;
      });

    //Are we at end of section
    if (
      sectionQuestions[sectionQuestions.length - 1].id ===
      currentFieldInfo.questionId
    ) {
      // Can we increment sectionIndex?
      if (currentFieldInfo.sectionIndex !== sectionLoopCount) {
        // Yes - just increment sectionIndex get first question in section and return
        const firstQuestion = sectionQuestions[0];
        resultFieldInfo = getFieldInfo(
          firstQuestion.id,
          currentFieldInfo.sectionId,
          currentFieldInfo.sectionInstanceId,
          currentFieldInfo.sectionIndex + 1,
          currentFieldInfo.sectionDepth
        );
        return resultFieldInfo;
      } else {
        // No - So we need to drop back to SectionSourceField (parent) - and call this recursively
        return getFollowingQuestion(getFieldInfoFromName(sectionSourceField));
      }
    } else {
      // No - so just next question in section
      currentIndex = sectionQuestions.findIndex(
        x => x.id === currentFieldInfo.questionId
      );
      nextQuestion = sectionQuestions[currentIndex + 1];

      if (nextQuestion != null) {
        return getFieldInfo(
          nextQuestion.id,
          nextQuestion.section.id,
          currentFieldInfo.sectionInstanceId,
          currentFieldInfo.sectionIndex,
          currentFieldInfo.sectionDepth
        );
      } else {
        // REALLY - end of questionnaire???
        // eslint-disable-next-line no-debugger
        debugger;
        return NavigationConstants.END_QUESTIONNAIRE;
      }
    }
  } else {
    //Otherwise just grab next non repeating indexed question
    const nonRepeatingQuestions = questionnaire.questions.filter(
      x => x.section.repeatable === false
    );
    currentIndex = nonRepeatingQuestions.findIndex(
      x => x.id === currentFieldInfo.questionId
    );

    if (currentIndex + 1 > nonRepeatingQuestions.length - 1) {
      // REALLY - end of questionnaire???
      // eslint-disable-next-line no-debugger
      debugger;
      return NavigationConstants.END_QUESTIONNAIRE;
    } else {
      nextQuestion = nonRepeatingQuestions[currentIndex + 1];
    }

    if (nextQuestion != null) {
      return getFieldInfo(nextQuestion.id, nextQuestion.section.id);
    } else {
      // REALLY - end of questionnaire???
      // eslint-disable-next-line no-debugger
      debugger;
      return NavigationConstants.END_QUESTIONNAIRE;
    }
  }
};

const getPrevQuestion = currentField => {
  const questionnaireState = store.getState().newQuestionnaire;
  const responses = questionnaireState.responses;
  const responseKeys = responses.keySeq();
  let responseIndex = responseKeys.findIndex(k => k === currentField.fieldName);
  let prevResponseIndex = -1;
  let prevField;

  // If current question isn't found we haven't submitted and we're at the end
  // of our responses - so just take the last key in the list
  if (responseIndex === -1) {
    prevField = responseKeys.last();
  } else {
    // Otherwise go back one index if possible
    prevResponseIndex = responseIndex > 0 ? responseIndex - 1 : responseIndex;
    prevField = responses
      .slice(prevResponseIndex, prevResponseIndex + 1)
      .keySeq()
      .first();
  }

  const prevFieldInfo =
    prevField != null ? getFieldInfoFromName(prevField) : currentField;
  return Promise.resolve(prevFieldInfo);
};

const previewNextQuestion = (currentFieldInfo, responseEdits) => {
  const responses = applyResponseEdits(responseEdits);
  return getNextQuestion(currentFieldInfo, responses);
};

const applyResponseEdits = responseEdits => {
  const questionnaireState = store.getState().newQuestionnaire;
  let newResponses = questionnaireState.responses;

  responseEdits.forEach(x => {
    const fieldName = x.fieldName;
    newResponses = newResponses.set(fieldName, x);
  });

  return newResponses;
};

export const navigation = {
  getNextQuestion,
  getPrevQuestion,
  previewNextQuestion
};
