import React from 'react';
import { Provider } from 'react-redux';
import { App } from './app';
import { store } from '../stores/store';
import Theme from '../components/Theme/Theme';

const Startup = () => {
  return (
    <Provider store={store}>
      <Theme>
        <App />
      </Theme>
    </Provider>
  );
};

export default Startup;
