const styles = theme => ({
  container: {
    marginTop: 16
  },
  boldSubtitle: {
    fontWeight: 500
  },
  compactBullet: {
    fontSize: '12px',
    paddingTop: 0,
    paddingBottom: 0
  },
  bulletList: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16
  },

  pedigreeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  isoPedigree: {
    width: '100%',
    textAlign: 'center',
    flexBasis: '100%',
    flexGrow: 0,
    flexShrink: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  }
});

export default styles;
