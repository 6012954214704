const drawerWidth = 230;

const styles = theme => ({
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    backgroundColor: 'white'
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  toolbarBackground: {
    position: 'absolute',
    left: 0,
    top: 0,
    [theme.breakpoints.down('xs')]: {
      height: 56
    },
    overflow: 'hidden'
  },
  navIconHide: {
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  rightLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  rightLogo: {
    display: 'flex',
    marginRight: theme.spacing(1),
    flexGrow: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  accountIcon: {
    flexGrow: 0,
    color: theme.palette.primary.dark
  },
  noOutline: {
    outline: 0,
    '&:focus': {
      outline: 0
    }
  },
  georgesLogo: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  fhqsLogo: {
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  dnaBackground: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  dnaBackgroundSmall: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

export default styles;
