import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TitleCard from '../../Cards/TitleCard';
import Helix from '../../Helix';
import Styles from './styles';
import { history } from '../../../helpers/history';
import Submissions from './Submissions';
import Interesting from './Interesting';
import { api } from '../../../services/api/dataApi';
import loader from '../../Loader/Loader';
import { ErrorBoundary } from '../../ErrorBoundary';

const styles = theme => Styles(theme);

class Admin extends React.Component {
  async componentDidMount() {
    const { loggedIn, isSubmissionsLoading, getSubmissionDetails } = this.props;
    if (!loggedIn) history.push('/signin');

    if (loggedIn && !isSubmissionsLoading) {
      const { refetch } = getSubmissionDetails({
        subscribe: false,
        forceRefetch: false
      });

      refetch();
    }
  }

  renderContent = () => {
    const { isSubmissionsLoading, submissions, serviceCode } = this.props;
    if (isSubmissionsLoading) return <Helix />;
    if (submissions) {
      return (
        <>
          {serviceCode && serviceCode.showLateSubmission && (
            <Interesting />
          )}
          <Submissions />
        </>
      );
    }
  };

  render() {
    const { submissionsError } = this.props;

    return submissionsError ? (
      <ErrorBoundary error={submissionsError} />
    ) : (
      <TitleCard
        cardTitle="Admin"
        cardSubtitle={`Current submissions as of ${moment().format(
          'DD/MM/YYYY'
        )}`}
        content={this.renderContent()}
        footer={null}
        fullWidth
      />
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatch = {
  getSubmissionDetails: api.endpoints.submissionDetails.initiate
};

const mapState = state => {
  const { submissions, isSubmissionsLoading, submissionErrors } = state.admin;
  const { loggedIn } = state.authentication;
  const { serviceCode } = state.organisation;

  return {
    loggedIn,
    isSubmissionsLoading,
    submissionErrors,
    submissions,
    serviceCode
  };
};

const composedAdmin = compose(
  loader,
  connect(mapState, mapDispatch),
  withStyles(styles, { withTheme: true })
)(Admin, false);

export { composedAdmin as Admin };
