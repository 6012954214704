import React from 'react';
import swirlySvg from './swirly.svg';
import { withStyles } from '@material-ui/core';
import Styles from './styles';

const styles = theme => Styles(theme);

const Swirly = props => (
  <div className={props.classes.container}>
    <img alt="downloading" src={swirlySvg} />
  </div>
);

export default withStyles(styles)(Swirly);
