import React from 'react';
import { connect } from 'react-redux';
import { api } from '../../services/api/dataApi';

const Loader = ComposedClass => {
  return class extends React.Component {
    displayName() {
      return ComposedClass.displayName();
    }

    async componentDidMount() {
      const {
        loggedIn,
        serviceCode,
        isLoaded,
        user,
        getServiceCode,
        getQuestionnaire
      } = this.props;

      if (loggedIn) {
        if (!serviceCode && user?.serviceCode) {
          console.log('Loader loading serviceCode');
          await getServiceCode(user.serviceCode);
        }

        if (!isLoaded && serviceCode) {
          console.log('Loader loading questionnaire');
          await getQuestionnaire(serviceCode.questionnaireId);
        }
      }
    }

    render() {
      return (
        <div>
          <ComposedClass {...this.props} />
        </div>
      );
    }
  };
};

const mapDispatch = {
  getQuestionnaire: api.endpoints.getQuestionnaire.initiate,
  getServiceCode: api.endpoints.getServiceCode.initiate
};

const mapState = state => {
  const { isLoading, isLoaded } = state.newQuestionnaire;

  const { loggedIn, user } = state.authentication;

  const { questionnaireId, serviceCode } = state.organisation;

  return {
    user,
    isLoading,
    isLoaded,
    questionnaireId,
    serviceCode,
    loggedIn
  };
};

export default ComposedClass =>
  connect(mapState, mapDispatch)(Loader(ComposedClass));
