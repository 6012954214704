import { handleError, handleResponse } from '../../helpers/api';
import { authentication } from '../../helpers/authentication';
import { config } from '../../helpers/config';

export const getPedigree = (
  questionnaireId: number,
  userId: string,
  fileType: string,
  downloadType: string
): Promise<Record<string, unknown> | undefined> => {
  const data = {
    includeProcessed: true,
    includeComplete: true,
    questionnaireId: questionnaireId,
    userId: userId,
    outputType: fileType,
    fileDownloadType: downloadType
  };

  const headers = authentication();
  headers['Content-Type'] = 'application/json';

  const postOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  } as RequestInit;

  return fetch(config.apiUrl + '/results/drawpedigree', postOptions).then(
    handleResponse,
    handleError
  );
};

export const getSummary = (
  questionnaireId: number,
  nhsNo: string,
  fileType: string,
  downloadType: string,
  serviceCode: string,
  markAsDownloaded: boolean
): Promise<Record<string, unknown> | undefined> => {
  const data = {
    includeProcessed: true,
    includeComplete: true,
    questionnaireId,
    nhsNo,
    outputType: fileType,
    fileDownloadType: downloadType,
    serviceCode,
    markAsDownloaded
  };

  const headers = authentication();
  headers['Content-Type'] = 'application/json';

  const postOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  } as RequestInit;

  return fetch(config.apiUrl + '/results/websummary', postOptions).then(
    handleResponse,
    handleError
  );
};

export const getCanRisk = (
  questionnaireId: number,
  nhsNo: string,
  serviceCode: string
): Promise<Record<string, unknown> | undefined> => {
  const data = {
    includeProcessed: true,
    includeComplete: true,
    questionnaireId,
    nhsNo,
    serviceCode
  };

  const headers = authentication();
  headers['Content-Type'] = 'application/json';

  const postOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  } as RequestInit;

  return fetch(config.apiUrl + '/canrisk/canriskinputfile', postOptions).then(
    handleResponse,
    handleError
  );
};

export const getAuthenticatorSetupPdfLetter = (
  email: string
): Promise<Record<string, unknown> | undefined> => {
  const data = {
    email: email
  };

  const headers = authentication();
  headers['Content-Type'] = 'application/json';

  const postOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  } as RequestInit;

  return fetch(
    config.apiUrl + '/accounts/getauthenticatorsetuppdfletter',
    postOptions
  ).then(handleResponse, handleError);
};

export const getResetPasswordLinkPdf = (
  email: string,
  newPasswordUrl: string
): Promise<Record<string, unknown> | undefined> => {
  const data = {
    email: email,
    newPasswordUrl: newPasswordUrl
  };

  const headers = authentication();
  headers['Content-Type'] = 'application/json';

  const postOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  } as RequestInit;

  return fetch(
    config.apiUrl + '/accounts/getresetpasswordlink',
    postOptions
  ).then(handleResponse, handleError);
};
