import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import classNames from 'classnames';

const styles = theme => Styles(theme);

const TitleCard = props => {
  const {
    classes,
    cardTitle,
    cardSubtitle,
    content,
    footer,
    titleColour,
    fullWidth
  } = props;
  const { cardHeaderLeft, cardHeaderPrimary, cardHeaderSecondary } = classes;

  const titleClass = classNames(cardHeaderLeft, {
    [cardHeaderPrimary]: titleColour === 'primary',
    [cardHeaderSecondary]: titleColour === 'secondary'
  });

  return (
    <div>
      <div className={classes.container}>
        <Card
          className={
            fullWidth ? classNames(classes.card, 'fullWidth') : classes.card
          }
        >
          <div className={classes.cardHeaderContainer}>
            <div className={titleClass}>
              <Typography variant="h5" className={classes.headerTitle}>
                {cardTitle}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {cardSubtitle}
              </Typography>
            </div>
            <div className={classes.cardHeaderRight}></div>
          </div>
          <CardContent className={classes.cardContent}>
            <div className={classes.contentContainer}>{content}</div>
          </CardContent>
          {footer ? (
            <CardActions className={classes.cardActions}>{footer}</CardActions>
          ) : null}
        </Card>
      </div>
    </div>
  );
};

TitleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  titleColour: PropTypes.oneOf(['primary', 'secondary']),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node
};

export default withStyles(styles, { withTheme: true })(TitleCard);
