import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';

const StyledTextField = withStyles(theme => ({
  root: {
    color: theme.palette.error.main,
    '& label.Mui-focused': {
      color: theme.palette.primary.dark
    },
    '& label.Mui-focused.Mui-error': {
      color: `${theme.palette.error.main} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.dark
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: `${theme.palette.error.main} !important`
    }
  }
}))(TextField);

export default StyledTextField;
