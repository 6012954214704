import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  root: {
    flexGrow: 0,
    '& label.Mui-focused': {
      color: theme.palette.primary.dark
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.dark
    },
    '& .MuiInput-input': {
      display: 'flex'
    }
  },
  input: {
    display: 'flex',
    '@media (max-width:1100px) and (min-width:960px) ': {
      maxWidth: 230
    }
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '& input': {
      fontFamily: theme.typography.fontFamily
    },
    maxHeight: '36px',
    overflow: 'visible',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 37px)'
    },
    '@media (max-width:1100px) and (min-width:960px) ': {
      width: 'calc(100% - 37px)'
    }
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16,
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'inline-block',
    overflow: 'hidden',
    left: 0,
    right: 0
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      width: 350
    }
  },
  menuList: {
    maxHeight: 288,
    overflowY: 'auto',
    position: 'relative',
    webkitOverflowScrolling: 'touch',
    boxSizing: 'border-box'
  },
  divider: {
    height: theme.spacing(2)
  }
});

export default styles;
