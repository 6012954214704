import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import { config } from '../../../helpers/config';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import ListItemText from '@material-ui/core/ListItemText';
import { List } from '@material-ui/core';
import { connect } from 'react-redux';
import FAQItem from './FAQItem';

const styles = theme => Styles(theme);

class Faq extends React.Component {
  state = {
    expanded: null
  };

  componentDidMount() {
    const { location } = this.props;

    if (location.hash) {
      const hash = location.hash.slice(1, location.hash.length);
      this.setState({ expanded: hash });
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.hash !== location.hash) {
      if (location.hash) {
        const hash = location.hash.slice(1, location.hash.length);
        this.setState({ expanded: hash });
      } else {
        this.setState({ expanded: false });
      }
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  renderMarkdownFAQ(faqJson) {
    const { expanded } = this.state;

    if (!faqJson || faqJson.FAQs?.length === 0) return;

    return faqJson.FAQs.map((faqItem, index) => (
      <FAQItem
        key={index}
        index={index}
        faqItem={faqItem}
        expanded={expanded === faqItem.faqTag}
        onChange={this.handleChange(faqItem.faqTag)}
      />
    ));
  }

  renderSGHSWTFAQ() {
    const { classes } = this.props;
    const { expanded } = this.state;
    const contactEmail = this.props.service?.serviceConfiguration?.contactEmail;
    const serviceCode = this.props.serviceCode?.toString().toLowerCase();

    return (
      <>
        <ExpansionPanel
          expanded={expanded === 'cancer-or-tumours'}
          onChange={this.handleChange('cancer-or-tumours')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="subtitle1"
              className={
                expanded === 'cancer-or-tumours' ? classes.heading : null
              }
            >
              <div id="cancer-or-tumours">
                {`What do you mean by "cancer" or "tumours"?`}
              </div>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              {`A tumour is a growth somewhere in the body. A tumour can be
                  cancerous or non-cancerous (benign). Cancer is when the growth
                  spreads and damages the body. A non-cancerous (benign) tumour
                  can cause health issues by pressing on nearby parts of the
                  body, or by producing hormones which can cause other problems.
                  Examples of non-cancerous tumours would include some brain
                  tumours (meningiomas for example), and hormone secreting
                  tumours (parathyroid adenomas, pituitary adenomas,
                  phaeochromocytomas). Don’t worry if you haven’t heard of
                  these. They are very rare but we have included them because
                  some people will have been diagnosed with one of these rare
                  non-cancerous tumours and sometimes these go with certain gene
                  changes.`}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'polyps'}
          onChange={this.handleChange('polyps')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="polyps">
              <Typography
                variant="subtitle1"
                className={expanded === 'polyps' ? classes.heading : null}
              >
                Do polyps count as cancer or a tumour?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1" className={classes.withBreaks}>
              {`Polyps are abnormal growths of tissue that are most often found in the bowel but can also be found in other parts of the body. Most commonly we are asked to give advice about bowel polyps.

                                    We will look at the number and type of polyps somebody had to advise on possible genetic testing or screening. This information is usually on colonoscopy reports, which you can email to us, or sign the consent form (`}
              <a
                href={
                  serviceCode
                    ? process.env.PUBLIC_URL +
                      `/docs/${serviceCode}/self-consent.pdf`
                    : process.env.PUBLIC_URL + `/docs/self-consent.pdf`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                download here
              </a>
              ) and email it back to us so that we can request this.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'high-calcium-levels'}
          onChange={this.handleChange('high-calcium-levels')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="high-calcium-levels">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'high-calcium-levels' ? classes.heading : null
                }
              >
                Why are you asking about high calcium levels?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1" className={classes.withBreaks}>
              Having high calcium levels does not mean you have cancer. Whilst
              our unit usually deals with people who develop cancer or other
              non-cancerous tumours, we work with the endocrinologists (hormone
              doctors) to assess people with high calcium levels. This is
              because some people with high calcium levels have a hormone
              secreting tumour of the parathyroid glands – called a parathyroid
              adenoma. However, some people with high calcium levels have a
              different genetic condition which is not associated with tumours.
              Taking a family history from someone with high calcium levels can
              help us check which would be the right genetic test to do make
              sure we are looking for the right condition.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'relative-information'}
          onChange={this.handleChange('relative-information')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="relative-information">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'relative-information' ? classes.heading : null
                }
              >
                What should I do if I don’t know information about my relatives?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1" className={classes.withBreaks}>
              {`We know that you may not be aware of all the information about all of your relatives. We will work with whatever information is available, but the more correct information we have, the better our advice will be. The form will save your information, so you have time to contact any relatives you think may be able to help you. If you don’t know a piece of information, you can provide an approximate date – such as a date of birth or date of death, or say “don’t know”.

                                    If you do not know how many relatives you have (for example how many aunts or uncles) just include any that you do know about.

                                    If you do not have information about your parents or grandparents, you can choose “skip” for these sections.

                                    You should include information on all relatives even if they have not had cancer.`}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'relatives-without-cancer'}
          onChange={this.handleChange('relatives-without-cancer')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="relatives-without-cancer">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'relatives-without-cancer'
                    ? classes.heading
                    : null
                }
              >
                Do you want me to fill out information on relatives without
                cancer?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              We would like you to include all of your blood relatives,
              including those who have not had a cancer or tumour diagnosis.
              This is so we can see if there is more cancer in your family than
              we would expect by chance.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'relative-diagnosis'}
          onChange={this.handleChange('relative-diagnosis')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="relative-diagnosis">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'relative-diagnosis' ? classes.heading : null
                }
              >
                What should I do if I don’t know the exact type of tumour or
                cancer my relative had?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panelRows}>
            <Typography variant="subtitle1" className={classes.withBreaks}>
              It is very important that we try to get information about the type
              of cancer or tumour your relative had. This is because different
              cancer types have different chances of having a genetic cause. For
              example, cervical cancer is not usually associated with genetic
              causes, whereas ovarian cancer is more likely to be associated
              with a genetic cause. If you are sure about the type of cancer
              without checking then that is fine. If you are not certain of the
              exact type of cancer, we can check with the cancer registry for
              anybody who was diagnosed with cancer from the 1970’s. If the
              diagnosis was before then, death certificates or medical letters
              are likely to contain the most accurate information. You can order
              a copy of a death certificate for your relative online (although
              this does have a cost per certificate) using the link in the
              Documents section.
              <br />
              <br />
            </Typography>
            <Typography variant="subtitle1" paragraph>
              Otherwise, we are often able to get this information for you if
              you send us a signed consent form for your own information, or for
              a deceased relative if you are their next of kin. If your relative
              is willing to sign a consent form for us to check their medical
              records, you can also email this to us.
            </Typography>
            <Typography variant="subtitle1" paragraph>
              All consent forms can be found in the documents section.
            </Typography>
            <Typography variant="subtitle1" paragraph>
              We will not contact your relatives without your consent.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'adopted-step-relatives'}
          onChange={this.handleChange('adopted-step-relatives')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="adopted-step-relatives">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'adopted-step-relatives' ? classes.heading : null
                }
              >
                Should I include information about adopted relatives or step
                relatives?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              We know that adopted relatives and step relatives (relatives
              related through marriage but not biologically related) are just as
              important members of a family as biologically related relatives.
              However, when we are looking at genetic risks, only biological
              relatives are likely to share genetic factors which may increase
              the chance of cancer in a family. For this reason, we would ask
              that you do not include adopted relatives or step relatives in the
              questionnaire, but we would like you to tell us about them in
              &quot;Other Information&quot; section.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'adopted'}
          onChange={this.handleChange('adopted')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="adopted">
              <Typography
                variant="subtitle1"
                className={expanded === 'adopted' ? classes.heading : null}
              >
                What if I am adopted?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              If you are adopted, please choose “skip” for the sections for both
              your mother and father.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'ethnicity'}
          onChange={this.handleChange('ethnicity')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="ethnicity">
              <Typography
                variant="subtitle1"
                className={expanded === 'ethnicity' ? classes.heading : null}
              >
                Why do you need my ethnicity?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              We know that some genetic changes which can increase the risk of
              cancer are more common in certain ethnic groups. For example,
              people of Ashkenazi Jewish heritage or Polish heritage are more
              likely to have specific changes in the BRCA genes than people of
              British descent. Because of this we sometimes offer genetic
              testing to people from certain ethnic populations when we might
              not offer it to others. Information about your ethnicity helps us
              to decide what genetic testing you may be offered.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'sex-assigned-at-birth'}
          onChange={this.handleChange('sex-assigned-at-birth')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="sex-assigned-at-birth">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'sex-assigned-at-birth' ? classes.heading : null
                }
              >
                Why do we ask about sex assigned at birth?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              The sex someone is assigned at birth does not always match their
              gender identity. Both are important to us when we make cancer
              family history assessments. When we look at a family history of
              cancer we look for clues of possible inherited causes. Some clues
              can vary depending on a person’s sex. For example, it is rarer to
              see breast cancer in someone who’s sex assigned at birth is male.
              This means it is more likely that their cancer was caused by a
              genetic factor, than if their sex was female.
              <br />
              <br />
              It also important to us to be inclusive for our patients. We have
              included a list of gender identity options for patients filling
              out the questionnaire who do not identify as male or female. This
              list is not available for relatives, but if you would like to tell
              us more about someone’s gender identity please include this in the
              &apos;any other information&apos; section at the end of the
              questionnaire. We are still working on getting this right for as
              many patients as possible, so if you would like to give us
              feedback please contact us at{' '}
              <a
                className={classes.noWrap}
                href={`mailto:${contactEmail ? contactEmail : config.email}`}
              >
                {contactEmail ? contactEmail : config.email}
              </a>
              .
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'relative-service'}
          onChange={this.handleChange('relative-service')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="relative-service">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'relative-service' ? classes.heading : null
                }
              >
                What should I do if I don’t know which genetics service my
                relative was seen in?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              If you can tell us the town/county/postcode that your relative was
              living in at the time of their assessment, we may be able to find
              this out for you. Please note this in the &quot;Other
              Information&quot; section.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'stuck'}
          onChange={this.handleChange('stuck')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="stuck">
              <Typography
                variant="subtitle1"
                className={expanded === 'stuck' ? classes.heading : null}
              >
                I am stuck. What should I do?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1" className={classes.withBreaks}>
              {`If you are stuck, it may be helpful to review your progress through the questionnaire in the navigator bar.

                                    On a desktop/tablet these are found to the left of the screen under “start”

                                    On a mobile phone you may have to press the 3 parallel lines at the top left of the questionnaire (next to the logo) to access the navigator bar.

                                    The navigator bar shows you the sections of the question numbered from 1 (About You) to 8 (Other Information). In order to complete the questionnaire, all sections must be completed. You can see if a section is complete in the navigator bar if it has a green circle with a tick inside it. Any sections without a tick will need to be completed before you can submit the questionnaire. You can press/click on a section to be taken to the questions in that section.

                                    If you are still stuck, please contact us with your query on `}
              <a
                className={classes.noWrap}
                href={`mailto:${contactEmail ? contactEmail : config.email}`}
              >
                {contactEmail ? contactEmail : config.email}
              </a>{' '}
              and we will try to help you. Your information will be saved. You
              do not need to complete all the questions at once.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'consent-and-privacy'}
          onChange={this.handleChange('consent-and-privacy')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="consent-and-privacy">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'consent-and-privacy' ? classes.heading : null
                }
              >
                Where can I find more information about consent and data
                privacy?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              The data privacy and consent associated with this application can
              be downloaded{' '}
              <a
                href={process.env.PUBLIC_URL + '/docs/consent-and-privacy.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'share-with-relatives'}
          onChange={this.handleChange('share-with-relatives')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="share-with-relatives">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'share-with-relatives' ? classes.heading : null
                }
              >
                Will you share my information with my relatives?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              We will never contact your relatives without your consent.
              However, sometimes it may be helpful to share the information you
              have given us with other genetics centres or healthcare
              professionals to help other members of your family. For example,
              if we find a gene change which can increase cancer risk in you, a
              copy of the report might be needed by another genetics centre so
              that they can test your parents, siblings or children to give them
              advice. Or if you need extra screening due to the family history,
              other family members might also need extra screening. If we can
              share the advice we have given based on your information to their
              doctor this can help your relatives get screening more quickly and
              easily. We only share the information with your consent, and only
              if it is important to help in the medical care of your relatives.
              We can remove your name and personal details from reports and
              letters if you would prefer.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'triple-negative-breast-cancer'}
          onChange={this.handleChange('triple-negative-breast-cancer')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="triple-negative-breast-cancer">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'triple-negative-breast-cancer'
                    ? classes.heading
                    : null
                }
              >
                What is triple negative breast cancer?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              <a
                href="https://www.cancerresearchuk.org/about-cancer/breast-cancer/stages-types-grades/types/triple-negative-breast-cancer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Triple negative breast cancer
              </a>{' '}
              is an uncommon type of breast cancer where the cancer cells do NOT
              express oestrogen, progesterone or Herceptin receptors. If you are
              uncertain if you or a relative had a triple negative breast cancer
              you should answer the questions as “no” to having triple negative
              breast cancer unless you can find out otherwise. This is because
              triple negative breast cancer is much less common than other types
              of breast cancer.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'two-or-more-cancers'}
          onChange={this.handleChange('two-or-more-cancers')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="two-or-more-cancers">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'two-or-more-cancers' ? classes.heading : null
                }
              >
                What does it mean to have two or more cancer diagnoses in the
                same person?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              Cancer often spreads to other parts of the body (known as
              “metastases”) and this does not mean that a person has had more
              than one cancer. Cancer can also come back (a recurrence) up to
              many years after the cancer was first diagnosed. The same cancer
              coming back does not mean that a person has had more than one
              cancer. When we ask about having two or more cancer diagnoses, we
              mean two or more different and independent primary cancer
              diagnoses. For example, having breast cancer AND bowel cancer, or
              having skin cancer AND prostate cancer.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'related-cancers'}
          onChange={this.handleChange('related-cancers')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="related-cancers">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'related-cancers' ? classes.heading : null
                }
              >
                What is a related cancer?
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panelRows}>
            <Typography variant="subtitle1" paragraph>
              Up to 1 in 2 people will get cancer in their lifetime. Most
              cancers happen when people get older, and are not due to anything
              running in the family. However, sometimes we see more cancer in a
              family than we would expect by chance. Conditions which run in a
              family are usually caused by genetic changes. There are different
              ways by which genetic changes can increase cancer risk in a
              family.
            </Typography>
            <List className={classes.bulletList}>
              <ListItem className={classes.compactBullet}>
                <ListItemIcon>
                  <DotIcon color={'inherit'} fontSize={'inherit'} />
                </ListItemIcon>
                <ListItemText
                  primary="Multifactorial inheritance"
                  className={classes.boldSubtitle}
                />
              </ListItem>
            </List>
            <Typography variant="subtitle1" paragraph>
              Relatives share sections of their genetic information. This means
              that they can share multiple genetic changes which on their own
              only increase the risk of cancer a small amount, but when added
              all together can cause a higher lifetime risk of certain cancers.
              This is the most common reason for more than one person in a
              family developing the same type of cancer.{' '}
            </Typography>
            <List className={classes.bulletList}>
              <ListItem className={classes.compactBullet}>
                <ListItemIcon>
                  <DotIcon color={'inherit'} fontSize={'inherit'} />
                </ListItemIcon>
                <ListItemText
                  primary="High risk cancer genes"
                  className={classes.boldSubtitle}
                />
              </ListItem>
            </List>
            <Typography variant="subtitle1" paragraph>
              In a small number of cases a single genetic change in a high risk
              gene has increased the cancer risk in the family. In this case we
              often see people developing cancer at young ages, people having
              cancer more than once, and many close relatives having the same or
              related types of cancer. In families who carry a high risk cancer
              gene, we see patterns of cancer which can go together as they can
              be caused by the same genetic change. This is why we ask about
              certain patterns of cancers which go together.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'invitation-leaflet-generic'}
          onChange={this.handleChange('invitation-leaflet-generic')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="invitation-leaflet-generic">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'invitation-leaflet-generic'
                    ? classes.heading
                    : null
                }
              >
                Patient information leaflet for patients referred to the breast
                unit
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              <a
                href={
                  process.env.PUBLIC_URL +
                  '/docs/FHQS_Invitation leaflet_Generic.pdf'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                This information leaflet
              </a>{' '}
              is for patients who have an appointment with the breast unit at St
              George&#39;s Hospital, who are being offered the opportunity to
              complete the online questionnaire.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'invitation-leaflet-canrisk'}
          onChange={this.handleChange('invitation-leaflet-canrisk')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div id="invitation-leaflet-canrisk">
              <Typography
                variant="subtitle1"
                className={
                  expanded === 'invitation-leaflet-canrisk'
                    ? classes.heading
                    : null
                }
              >
                Participant Information Sheet CanRisk ClinGen Study
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="subtitle1">
              This{' '}
              <a
                href={
                  process.env.PUBLIC_URL +
                  '/docs/CanRisk ClinGen - Participant information sheet.pdf'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>participant information leaflet</strong>
              </a>{' '}
              is for patients who have been offered the opportunity to
              participate in the CanRisk ClinGen Study. Research is entirely
              voluntary and not participating will not affect your clinical
              care.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const faq = this.props.service?.serviceConfiguration?.faq;
    const faqJson = faq ? JSON.parse(faq) : null;

    return (
      <TitleCard
        cardTitle="FAQ"
        cardSubtitle="Frequently Asked Questions"
        content={
          <div className={classes.root}>
            {faqJson ? this.renderMarkdownFAQ(faqJson) : this.renderSGHSWTFAQ()}
          </div>
        }
      />
    );
  }
}

const mapState = state => {
  const {
    organisation,
    service,
    code: serviceCode,
    error
  } = state.organisation;

  const { loggedIn } = state.authentication;

  return {
    loggedIn,
    organisation,
    service,
    serviceCode,
    error
  };
};

Faq.propTypes = {
  classes: PropTypes.object.isRequired
};

const composedFaq = compose(
  connect(mapState),
  withStyles(styles, { withTheme: true }),
  withRouter
)(Faq);

export { composedFaq as Faq };
