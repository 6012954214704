import { AdminActionConstants } from '../../../helpers/constants';
import { isNumeric } from '../../../helpers/types';
import Swirly from '../../Swirly';
import SadCloud from '../../SadCloud/SadCloud';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Cookie from '../../../helpers/cookies';
import saveAs from 'file-saver';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Styles from './styles';
import _ from 'lodash';
import { api } from '../../../services/api/dataApi';
import {
  getWebAuthenticatorSetup,
  setActionStatus
} from '../../../features/admin-slice';
import {
  AttachmentSharp,
  CloudDownloadSharp,
  EmailSharp
} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => Styles(theme);

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
  }

  onChangePage = page => {
    Cookie.set('currentPage', page, { path: '/', days: 365 });
  };

  onChangeRowsPerPage = rowsPerPage => {
    let rowCount = rowsPerPage;
    if (rowsPerPage !== 10 && rowsPerPage !== 50) {
      rowCount = 'max';
    }
    Cookie.set('rowsPerPage', rowCount, { path: '/', days: 365 });
  };

  onOrderChange = (column, direction) => {
    Cookie.set('sortColumn', column, { path: '/', days: 365 });
    Cookie.set('sortDirection', direction, { path: '/', days: 365 });
  };

  onSearchChange = searchTerm => {
    console.log(`Search changed: ${searchTerm}`);
  };

  downloadSetupLetter = async (event, row) => {
    event.stopPropagation();
    const { email } = row;

    const { getWebAuthenticatorSetup, setActionStatus } = this.props;

    const setupLetterRequest = {
      email
    };

    try {
      setActionStatus({ key: email, status: 'pending' });
      const blob = await getWebAuthenticatorSetup(setupLetterRequest);
      saveAs(blob.payload, `FHQS Authenticator Setup Letter - ${email}.pdf`);
      setActionStatus({ key: email, status: 'none' });
    } catch (error) {
      setActionStatus({ key: email, status: 'error' });
    }
  };

  sendMFASetupEmail = async (event, row) => {
    event.stopPropagation();
    const { email } = row;

    const { sendSetupEmail, setActionStatus } = this.props;

    const setupLetterRequest = {
      email
    };

    try {
      setActionStatus({ key: email, status: 'pending' });
      await sendSetupEmail(setupLetterRequest);
      setActionStatus({ key: email, status: 'none' });
    } catch (error) {
      setActionStatus({ key: email, status: 'error' });
    }
  };

  sendMFASetupEmailwithAttachment = async (event, row) => {
    event.stopPropagation();
    const { email } = row;

    const { sendSetupEmailwithAttachment, setActionStatus } = this.props;

    const setupLetterRequest = {
      email
    };

    try {
      setActionStatus({ key: email, status: 'pending' });
      await sendSetupEmailwithAttachment(setupLetterRequest);
      setActionStatus({ key: email, status: 'none' });
    } catch (error) {
      setActionStatus({ key: email, status: 'error' });
    }
  };

  getCookieSettings = data => {
    const dataLength = data && data.length ? data.length : 0;
    const cookiePageSize = Cookie.get('rowsPerPage');
    const pageSize =
      cookiePageSize != null &&
      (cookiePageSize === 'max' || isNumeric(cookiePageSize))
        ? cookiePageSize === 'max'
          ? dataLength
          : parseInt(cookiePageSize)
        : 10;

    const cookieCurrentPage =
      Cookie.get('currentPage') &&
      isNumeric(Cookie.get('currentPage')) &&
      parseInt(Cookie.get('currentPage')) >= 0
        ? parseInt(Cookie.get('currentPage'))
        : 0;
    const initialPage =
      cookieCurrentPage >= 0
        ? dataLength > 0 && cookieCurrentPage <= dataLength / pageSize
          ? cookieCurrentPage
          : 0
        : 0;

    const cookieSortColumn = Cookie.get('sortColumn');
    const sortColumn =
      cookieSortColumn &&
      isNumeric(cookieSortColumn) &&
      parseInt(cookieSortColumn) >= 0
        ? parseInt(cookieSortColumn)
        : null;

    const sortDirection = sortColumn ? Cookie.get('sortDirection') : null;

    return {
      pageSize,
      initialPage,
      sortColumn,
      sortDirection
    };
  };

  render() {
    const { user, adminUsers, actionStatuses, classes } = this.props;

    const data = adminUsers.map(user => {
      const addOp = {
        op: _.get(actionStatuses, user.email) || AdminActionConstants.None,
        ...user
      };
      return { ...addOp };
    });

    const settings = this.getCookieSettings(data);

    const limitWidth = (width, breakWords) => ({
      maxWidth: width,
      whiteSpace: 'pre-wrap',
      ...(breakWords ? { wordBreak: 'break-all' } : {})
    });

    const standardCellStyle = {
      fontSize: 13
    };

    const columns = [
      {
        title: 'Action',
        field: 'thing',
        sorting: false,
        cellStyle: {
          padding: 0,
          textAlign: 'center'
        },
        headerStyle: {
          textAlign: 'center',
          ...standardCellStyle
        },
        render: row => {
          const result =
            row.op === 'pending' ? (
              <Swirly />
            ) : row.op === 'error' ? (
              <SadCloud label="Server Error" />
            ) : null;

          if (result != null) return result;

          return null;
        }
      },
      {
        title: 'Authenticator Setup',
        field: 'setupletter',
        sorting: false,
        cellStyle: {
          ...standardCellStyle,
          textAlign: 'center'
        },
        headerStyle: { ...standardCellStyle, textAlign: 'center' },
        render: row => {
          return (
            <>
              <IconButton
                color="primary"
                aria-label="send setup email button"
                component="span"
                data-operation="sendMFASetupEmail"
                onClick={event => this.sendMFASetupEmail(event, row)}
                disabled={!user.isSystemAdmin}
              >
                <EmailSharp />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="send setup email with attachment letter button"
                component="span"
                data-operation="sendMFASetupEmailwithAttachment"
                onClick={event =>
                  this.sendMFASetupEmailwithAttachment(event, row)
                }
                disabled={!user.isSystemAdmin}
              >
                <AttachmentSharp />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="download setup letter button"
                component="span"
                data-operation="downloadSetupLetter"
                onClick={event => this.downloadSetupLetter(event, row)}
                disabled={!user.isSystemAdmin}
              >
                <CloudDownloadSharp />
              </IconButton>
            </>
          );
        }
      },
      {
        title: 'Email',
        field: 'email',
        cellStyle: { ...limitWidth(240, true), ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'MFA Enabled?',
        field: 'twoFactorEnabled',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row => {
          return row.twoFactorEnabled ? 'Yes' : 'No';
        }
      },
      {
        title: 'Roles',
        field: 'roles',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Service Code',
        field: 'serviceCode',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Service Name',
        field: 'serviceName',
        cellStyle: { ...limitWidth(240, true), ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      }
    ];

    if (settings.sortColumn != null && columns.length > settings.sortColumn) {
      columns[settings.sortColumn].defaultSort = settings.sortDirection;
    }

    return (
      <>
        <div className={classes.scrollOverflow}>
          <MaterialTable
            title={
              <Typography color="inherit" variant="subtitle1">
                Admin User List
              </Typography>
            }
            columns={columns}
            data={data}
            options={{
              ...settings,
              pageSizeOptions: [
                ...(data && data.length > 10
                  ? [10]
                  : data && data.length <= 10
                  ? [data.length]
                  : []),
                ...(data && data.length > 50
                  ? [50]
                  : data && data.length > 10 && data.length <= 50
                  ? [data.length]
                  : []),
                ...(data && data.length > 50 ? [data.length] : [])
              ]
            }}
            onChangePage={this.onChangePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
            onOrderChange={this.onOrderChange}
            onSearchChange={this.onSearchChange}
          />
        </div>
      </>
    );
  }
}

const mapDispatch = {
  getWebAuthenticatorSetup,
  sendSetupEmail: api.endpoints.sendAuthenticatorSetupEmail.initiate,
  sendSetupEmailwithAttachment:
    api.endpoints.sendAuthenticatorSetupEmailWithPdf.initiate,
  setActionStatus
};

const mapState = state => {
  const { user } = state.authentication;
  const { adminUsers, isAdminUsersLoading, actionStatuses } = state.admin;

  return {
    user,
    adminUsers,
    isAdminUsersLoading,
    actionStatuses
  };
};

const composedAdminUsers = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(AdminUsers);

export default composedAdminUsers;
