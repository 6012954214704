import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../Logo';
import LinkListItem from './ListItemLink';
import { SectionMenu } from '../../Questionnaire/SectionMenu/SectionMenu';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PhonelinkSetupSharp from '@material-ui/icons/PhonelinkSetupSharp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DraftsIcon from '@material-ui/icons/Drafts';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SecurityIcon from '@material-ui/icons/Security';
import DocumentIcon from '@material-ui/icons/FolderSharp';

import Styles from './styles';
import { QuestionnaireStatusConstants } from '../../../helpers/constants';
import { Typography } from '@material-ui/core';
import { EmojiPeople } from '@material-ui/icons';

const styles = theme => Styles(theme);

class SideBar extends React.Component {
  handleDrawerToggle = () => {
    const { onDrawerToggle } = this.props;

    if (onDrawerToggle != null) {
      onDrawerToggle();
    }
  };

  render() {
    const {
      classes,
      theme,
      loggedIn,
      questionnaireStatus,
      user,
      organisation
    } = this.props;
    const submitted =
      questionnaireStatus === QuestionnaireStatusConstants.Submitted ||
      questionnaireStatus === QuestionnaireStatusConstants.Processed;
    // eslint-disable-next-line no-undef
    const appName = process.env.REACT_APP_NAME.toUpperCase();
    const hasOrganisation = !!organisation;
    const isTest = window.location.host.toLocaleLowerCase().includes('test');
    // eslint-disable-next-line no-undef
    const reactAppVersion = process.env.REACT_APP_VERSION;

    const drawer = (
      <>
        <Toolbar className={classes.toolbarLogo}>
          <Logo width={186.077} height={28.365} />
        </Toolbar>
        <List className={classes.menuList}>
          <Divider />
          <LinkListItem
            icon={<EmojiPeople />}
            primary="Welcome"
            to="/"
            activeClassName={classes.selected}
            exact
            onClick={this.handleDrawerToggle}
          />
          {hasOrganisation && (
            <LinkListItem
              icon={<RoomServiceIcon />}
              primary="Introduction"
              to="/introduction"
              activeClassName={classes.selected}
              exact
              onClick={this.handleDrawerToggle}
            />
          )}

          {!loggedIn ? (
            <Fragment>
              {hasOrganisation && (
                <LinkListItem
                  icon={<RateReviewIcon />}
                  primary="Register"
                  to="/register"
                  activeClassName={classes.selected}
                  onClick={this.handleDrawerToggle}
                />
              )}
              <LinkListItem
                icon={<AccountCircleIcon />}
                primary="Sign In"
                to="/signin"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
              />
            </Fragment>
          ) : null}
          {loggedIn ? (
            <SectionMenu onDrawerToggle={this.handleDrawerToggle} />
          ) : null}
          {loggedIn ? (
            <Fragment>
              {/*<LinkListItem
                                    icon={<ReplayIcon />}
                                    primary="Restart"
                                    to="/restart"
                                    activeClassName={classes.selected}
                                    onClick={this.handleDrawerToggle}
                                    private
                                />*/}
              {submitted && (user.isViewOnlyAdmin || user.isAdmin || user.isSuperAdmin) ? (
                <LinkListItem
                  icon={<LockOpenIcon />}
                  primary="Unsubmit"
                  to="/unsubmit"
                  activeClassName={classes.selected}
                  onClick={this.handleDrawerToggle}
                  private
                />
              ) : null}
              <LinkListItem
                icon={<ExitToAppIcon />}
                primary="Sign out"
                to="/signout"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
                private
              />
            </Fragment>
          ) : null}
          <Divider />
          {hasOrganisation ? (
            <Fragment>
              <LinkListItem
                icon={<DocumentIcon />}
                primary="Documents"
                to="/documents"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
              />
              <LinkListItem
                icon={<SecurityIcon />}
                primary="Data Security"
                to="/security"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
              />
              <LinkListItem
                icon={<QuestionAnswerIcon />}
                primary="FAQ"
                to="/faq"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
              />
              <LinkListItem
                icon={<DraftsIcon />}
                primary="Contact Us"
                to="/contact"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
              />
            </Fragment>
          ) : (
            <Fragment>
              <LinkListItem
                icon={<QuestionAnswerIcon />}
                primary="FAQ"
                to="/faq"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
              />
            </Fragment>
          )}
          {loggedIn && user && (user.isViewOnlyAdmin || user.isAdmin) ? (
            <Fragment>
              <LinkListItem
                icon={<SupervisorAccountIcon />}
                primary="Admin"
                to="/admin"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
                private
              />
              <Divider />
            </Fragment>
          ) : null}
          {loggedIn && user && user.isSuperAdmin ? (
            <Fragment>
              <LinkListItem
                icon={<SupervisedUserCircleIcon />}
                primary="Super Admin"
                to="/superadmin"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
                private
              />
              <Divider />
            </Fragment>
          ) : null}
          {loggedIn && user && user.isSystemAdmin ? (
            <Fragment>
              <LinkListItem
                icon={<PhonelinkSetupSharp />}
                primary="System Admin"
                to="/systemadmin"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
                private
              />
              <Divider />
            </Fragment>
          ) : null}
          {loggedIn && user && user.isSystemAdmin ? (
            <Fragment>
              <LinkListItem
                icon={<LockIcon />}
                primary="User Password"
                to="/resetuserpassword"
                activeClassName={classes.selected}
                onClick={this.handleDrawerToggle}
                private
              />
              <Divider />
            </Fragment>
          ) : null}
        </List>
        <div className={classes.version}>
          <Typography
            variant="caption"
            align="center"
          >{`${appName} v${reactAppVersion}`}</Typography>
          {isTest && (
            <Typography
              variant="caption"
              align="center"
              className={classes.heavy}
            >
              TEST ENVIRONMENT
            </Typography>
          )}
          <Typography variant="caption" align="center">
            &copy; {new Date().getFullYear()} All Rights Reserved
          </Typography>
        </div>
      </>
    );

    return (
      <div className={classes.drawerHidden}>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.props.drawerOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div className={classes.drawerHiddenSmallDown}>
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
              docked: classes.drawerDocked
            }}
          >
            {drawer}
          </Drawer>
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired
};

const mapState = state => {
  const { loggedIn, user } = state.authentication;
  const { questionnaireStatus } = state.newQuestionnaire;
  const { organisation } = state.organisation;

  return {
    loggedIn,
    user,
    questionnaireStatus,
    organisation
  };
};

const connectedSideBar = compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapState)
)(SideBar);

export { connectedSideBar as SideBar };
