import { configureStore } from '@reduxjs/toolkit';
import { api } from '../services/api/dataApi';
import organisationReducer from '../features/organisation-slice';
import authenticationReducer from '../features/authentication-slice';
import newPasswordReducer from '../features/newpassword-slice';
import registrationReducer from '../features/registration-slice';
import alertsReducer from '../features/alerts-slice';
import adminReducer from '../features/admin-slice';
import newQuestionnaireReducer from '../features/questionnaire/questionnaire-slice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    organisation: organisationReducer,
    authentication: authenticationReducer,
    newPassword: newPasswordReducer,
    registration: registrationReducer,
    alerts: alertsReducer,
    admin: adminReducer,
    newQuestionnaire: newQuestionnaireReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: {
        ignoredPaths: ['questionnaires', 'newQuestionnaire']
      }
    }).concat(api.middleware)
});
