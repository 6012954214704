const styles = theme => ({
  container: {
    paddingBottom: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: 24,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '3px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
    overflow: 'visible',
    width: 960,
    [theme.breakpoints.down('lg')]: {
      width: 800
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 600
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  cardActions: {
    margin: 0,
    paddingTop: '10px',
    borderTop: '1px solid #eeeeee',
    height: 'auto',
    justifyContent: 'flex-end',
    display: 'flex'
  },
  cardHeaderContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  cardHeaderLeft: {
    background: `linear-gradient(${
      theme?.palette?.primary?.dark ?? '#007ac1'
    }, transparent), linear-gradient(to top left, ${
      theme?.palette?.primary?.light ?? '#67daff'
    }, transparent), linear-gradient(to top right, ${
      theme?.palette?.titleCardHeaderLeft?.topRight ?? 'blue'
    }, transparent)`,
    backgroundBlendMode: 'screen',
    marginTop: -24,
    borderRadius: 3,
    padding: 24,
    marginRight: 24,
    marginLeft: 24,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
      marginRight: 16,
      marginLeft: 16
    },
    boxShadow:
      '0 10px 30px -30px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  cardHeaderRight: {
    flexGrow: 1
  },
  cardHeaderContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerTitle: {
    alignSelf: 'flex-start'
  },
  cardContent: {
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      padding: 16
    }
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  questionContainer: {
    flex: '1 0 62%',
    flexBasis: 'initial',
    margin: `${theme.spacing(1)}px 0`,

    [theme.breakpoints.up('md')]: {
      flexBasis: '62%',
      marginRight: `${2 * theme.spacing(1)}px`
    }
  },
  questionContainerFullWidth: {
    flex: '0 0 100%',
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
  },
  answerContainer: {
    flex: '0 0 32%',
    flexBasis: 'initial',

    [theme.breakpoints.up('md')]: {
      flexBasis: '32%',
      maxWidth: '32%'
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      maxWidth: '100%'
    },

    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
  },
  helpButton: {
    margin: 0
  },
  prevButton: {
    margin: theme.spacing(1)
  },
  nextButton: {
    margin: theme.spacing(1)
  }
});

export default styles;
