import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/api/dataApi';
import {
  Organisation,
  Service,
  ServiceCode,
  ServiceCodeResponse
} from '../types/organisation';
import { extractRTKQueryError } from '../services/api/apiConfig';

interface OrganisationState {
  organisation?: Organisation;
  service?: Service;
  serviceCode?: ServiceCode;
  questionnaireId?: number;
  preQuestionnaireId?: number;
  code?: string;
  theme?: string;
  serviceCodeErrors?: string;
  introduction?: string;
  allServiceCodes?: Array<ServiceCodeResponse>;
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: OrganisationState = {
  isLoading: false,
  isLoaded: false
};

const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        api.endpoints.getServiceCode.matchFulfilled,
        (state: OrganisationState, { payload }) => {
          state.organisation = payload.organisation;
          state.service = payload.service;
          state.code = payload.serviceCode;
          state.questionnaireId = payload.questionnaireId;
          state.preQuestionnaireId = payload.preQuestionnaireId;
          state.serviceCodeErrors = undefined;

          const serviceCode =
            payload.service?.serviceCodes?.length === 1
              ? payload.service?.serviceCodes[0]
              : undefined;

          const serviceConfig = payload.service.serviceConfiguration;

          state.serviceCode = serviceCode;
          state.theme = serviceConfig?.theme
            ? JSON.parse(serviceConfig?.theme)
            : undefined;

          state.introduction = serviceConfig?.intro;

          state.isLoaded = true;
          state.isLoading = false;
        }
      )
      .addMatcher(
        api.endpoints.getServiceCode.matchPending,
        (state: OrganisationState) => {
          state.isLoaded = false;
          state.isLoading = true;
          state.serviceCodeErrors = undefined;
        }
      )
      .addMatcher(
        api.endpoints.getServiceCode.matchRejected,
        (state: OrganisationState, action) => {
          if (action.error && action.error.name !== 'ConditionError') {
            const { error, status } = extractRTKQueryError(action);
            state.serviceCodeErrors = error;
            state.isLoaded = false;
            state.isLoading = false;
            console.log(`getServiceCode failed with: ${status}`);
          }
        }
      )
      .addMatcher(
        api.endpoints.getAllServiceCodes.matchFulfilled,
        (state, { payload }) => {
          state.allServiceCodes = payload;
        }
      );
  }
});

export const { logout } = organisationSlice.actions;

export default organisationSlice.reducer;
