import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../StyledTextField';
import withStyles from '@material-ui/core/styles/withStyles';
import DropDownSelect from '../../DropDownSelect/DropDownSelect';
import AutoCompleteSelect from './AutoCompleteSelect/AutoCompleteSelect';
import { DisplayTypeConstants } from '../../../helpers/constants';
import Styles from './styles';
import { Typography } from '@material-ui/core';

const styles = theme => Styles(theme);

class Answer extends React.Component {
  errorFor = field => {
    return this.props.validationResults[field] || '';
  };

  renderControl() {
    const {
      classes,
      currentQuestion,
      currentFieldInfo,
      currentValue,
      onFieldChanged,
      showErrors,
      items,
      onKeyPress
    } = this.props;

    const fieldName = currentFieldInfo.fieldName;

    switch (currentQuestion.answerType.displayType) {
      case DisplayTypeConstants.FreeText:
      case DisplayTypeConstants.EmailText:
      case DisplayTypeConstants.DateYearText:
        return (
          <TextField
            id={currentQuestion.id.toString()}
            inputProps={{ 'data-lpignore': 'true', autoComplete: 'off' }}
            label={currentQuestion.answerPlaceholder}
            className={classes.textField}
            value={currentValue.value}
            onChange={e => onFieldChanged(e.target.value)}
            onKeyUp={onKeyPress}
            error={this.errorFor(fieldName) !== '' && showErrors}
            helperText={showErrors ? this.errorFor(fieldName) : null}
            autoFocus
          />
        );
      case DisplayTypeConstants.FreeTextMultiline:
        return (
          <Fragment>
            <TextField
              id={currentQuestion.id.toString()}
              inputProps={{ 'data-lpignore': 'true', autoComplete: 'off' }}
              label={currentQuestion.answerPlaceholder}
              multiline
              rows="5"
              value={currentValue.value}
              className={classes.textField}
              onChange={e => onFieldChanged(e.target.value)}
              onKeyUp={onKeyPress}
              error={this.errorFor(fieldName) !== '' && showErrors}
              helperText={showErrors ? this.errorFor(fieldName) : null}
              autoFocus
            />
            <Typography variant="overline" className={classes.desktopOnly}>
              CTRL + ENTER FOR NEW LINE
            </Typography>
          </Fragment>
        );
      case DisplayTypeConstants.NumericText:
        return (
          <TextField
            id={currentQuestion.id.toString()}
            inputProps={{ 'data-lpignore': 'true', autoComplete: 'off' }}
            label={currentQuestion.answerPlaceholder}
            className={classes.textField}
            value={currentValue.value}
            type="number"
            onChange={e => onFieldChanged(e.target.value)}
            onKeyUp={onKeyPress}
            error={this.errorFor(fieldName) !== '' && showErrors}
            helperText={showErrors ? this.errorFor(fieldName) : null}
            autoFocus
          />
        );
      case DisplayTypeConstants.DropDownSelect:
        return (
          <DropDownSelect
            inputProps={{ 'data-lpignore': 'true', autoComplete: 'off' }}
            currentPlaceholder={currentQuestion.answerPlaceholder}
            currentValue={currentValue.value}
            currentKey={currentValue.key}
            currentFieldName={fieldName}
            items={items}
            onFieldChanged={onFieldChanged}
            onKeyPress={onKeyPress}
            error={this.errorFor(fieldName) !== '' && showErrors}
            helperText={showErrors ? this.errorFor(fieldName) : null}
            autoFocus
          />
        );
      case DisplayTypeConstants.DropDownTypeAhead:
        return (
          <AutoCompleteSelect
            placeholder={currentQuestion.answerPlaceholder}
            inputProps={{
              'data-lpignore': 'true',
              autoComplete: 'off',
              shrink: true
            }}
            currentQuestion={currentQuestion}
            currentValue={currentValue.value}
            currentKey={currentValue.key}
            items={items}
            onFieldChanged={onFieldChanged}
            onKeyPress={onKeyPress}
            error={this.errorFor(fieldName) !== '' && showErrors}
            helperText={showErrors ? this.errorFor(fieldName) : null}
            autoFocus
          />
        );
      case DisplayTypeConstants.NoAnswer:
      default:
        return null;
    }
  }

  render() {
    const { classes } = this.props;

    return <div className={classes.container}>{this.renderControl()}</div>;
  }
}

Answer.propTypes = {
  classes: PropTypes.object.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  currentQuestion: PropTypes.object.isRequired,
  currentFieldInfo: PropTypes.object.isRequired,
  currentValue: PropTypes.any.isRequired,
  showErrors: PropTypes.bool,
  items: PropTypes.array,
  onKeyPress: PropTypes.func
};

Answer.defaultProps = {
  validationResults: {},
  showErrors: false
};

export default withStyles(styles)(Answer);
