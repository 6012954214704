import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { config } from '../../helpers/config';
import Typography from '@material-ui/core/Typography';
import TitleCard from '../Cards/TitleCard';
import Styles from './styles';
import { ReactComponent as CrashSvg } from '../../images/crash2.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';

const styles = theme => Styles(theme);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
      info: null
    };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({
      error: error,
      info: info
    });
  }

  reloadPage = () => {
    window.location.reload(true);
  };

  renderError() {
    const { classes, error: propError } = this.props;
    const { error: stateError } = this.state;

    const error = stateError || propError;

    return (
      <div className={classes.container}>
        <TitleCard
          cardTitle="Sorry for the inconvenience"
          cardSubtitle="Something went wrong"
          content={
            <div className={classes.root}>
              <Typography
                className={classes.centeredText}
                variant={'subtitle1'}
              >
                An error occurred. Contact support if the problem persists on:{' '}
                <a
                  href={`mailto:${config.email}`}
                  className={classes.noDecorationLink}
                >
                  {config.email}
                </a>
              </Typography>
              <div className={classes.crashContainer}>
                <CrashSvg className={classes.crash} />
              </div>
              <ExpansionPanel className={classes.expansionPanel}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div className={classes.summaryContainer}>
                    <Typography
                      className={classes.centeredText}
                      variant="subtitle1"
                    >
                      Click here for details{' '}
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.panelDetails}>
                  <Typography paragraph>Message: {error.message}</Typography>
                  <Typography paragraph className={classes.stackDetails}>
                    Stack: {error.stack}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          }
          footer={
            <Button
              variant="contained"
              color="primary"
              onClick={this.reloadPage}
            >
              Reload Page
            </Button>
          }
        />
      </div>
    );
  }

  render() {
    const { error } = this.state;

    return error != null ? this.renderError() : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired
};

const styledErrorBoundary = withStyles(styles)(ErrorBoundary);
export { styledErrorBoundary as ErrorBoundary };
