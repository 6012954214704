const styles = theme => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

export default styles;
