import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TitleCard from '../../Cards/TitleCard';
import TextField from '../../StyledTextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Helix from '../../Helix';
import Styles from './styles';
import { history } from '../../../helpers/history';
import AdminUsers from './AdminUsers';
import { api } from '../../../services/api/dataApi';
import loader from '../../Loader/Loader';
import { ErrorBoundary } from '../../ErrorBoundary';
import { run } from '../../../validation/ruleRunner';
import { fieldValidations } from './validation';
import Markdown from '../../Markdown/Markdown';
import { Card } from '@material-ui/core';
import Swirly from '../../Swirly';

const styles = theme => Styles(theme);

class SystemAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      confirmClicked: false,
      showErrors: false,
      validationErrors: []
    };
  }

  handleInputChange = field => {
    return e => {
      this.setState({ [field]: e.target.value }, () => {
        const validationErrors = run(this.state, fieldValidations);
        this.setState({ validationErrors });
      });
    };
  };

  handleKeyPressed = () => {
    return e => {
      if (e.key === 'Enter') {
        e.preventDefault();

        this.handleGrantAdminAccess();
      }
    };
  };

  handleGrantAdminAccess = e => {
    const { addUserToRole } = this.props;
    const { email } = this.state;

    e && e.preventDefault();
    this.setState({ showErrors: true });
    if (Object.getOwnPropertyNames(this.state.validationErrors).length !== 0)
      return null;

    addUserToRole({ email: email, roleName: 'Admin User' });
    this.setState({ confirmClicked: true, showErrors: false });

    this.refershAdminUsers();
  };

  errorFor = field => {
    return this.state.validationErrors[field] || '';
  };

  refershAdminUsers = () => {
    const { getAdminUsers, isAdminUsersLoading, userRoleErrors } = this.props;
    if (!isAdminUsersLoading && !userRoleErrors) {
      const { refetch } = getAdminUsers({
        subscribe: false,
        forceRefetch: false
      });

      refetch();
    }
  };

  async componentDidMount() {
    const { loggedIn, getAdminUsers, isAdminUsersLoading } = this.props;
    if (!loggedIn) history.push('/signin');

    if (loggedIn && !isAdminUsersLoading) {
      const { refetch } = getAdminUsers({
        subscribe: false,
        forceRefetch: false
      });

      refetch();
    }
  }

  renderContent = () => {
    const {
      isAdminUsersLoading,
      adminUsers,
      isAddingRole,
      userRoleErrors,
      classes
    } = this.props;
    if (isAdminUsersLoading) return <Helix />;
    return (
      <>
        <Card name="addRoleForm" className={classes.addRoleForm}>
          <div className={classes.rowContainer}>
            <Fragment>
              <Typography variant="subtitle1">
                Grant user to admin access:
              </Typography>
              <TextField
                id="email"
                label="Enter user&#39;s account email"
                placeholder="Enter user&#39;s account email"
                value={this.state.email}
                onKeyUp={this.handleKeyPressed(event)}
                onChange={this.handleInputChange('email')}
                fullWidth
                error={this.errorFor('email') !== '' && this.state.showErrors}
                helperText={
                  this.state.showErrors ? this.errorFor('email') : null
                }
              />
            </Fragment>
            <div className={classes.buttonContainer}>
              {isAddingRole && <Swirly />}
              <Button
                type="submit"
                onClick={this.handleGrantAdminAccess}
                disabled={this.state.email === ''}
                variant="contained"
                color={classes.buttonColour}
              >
                Grant Admin Access
              </Button>
              {}
            </div>
            {userRoleErrors && (
              <Fragment>
                <Typography variant="subtitle1" className={classes.submitError}>
                  <Markdown>{userRoleErrors}</Markdown>
                </Typography>
              </Fragment>
            )}
          </div>
        </Card>
        {adminUsers && <AdminUsers></AdminUsers>}
      </>
    );
  };

  render() {
    const { adminUserErrors } = this.props;

    return adminUserErrors ? (
      <ErrorBoundary error={adminUserErrors} />
    ) : (
      <TitleCard
        cardTitle="System Admin"
        cardSubtitle="Manage Admin Users"
        content={this.renderContent()}
        footer={null}
        fullWidth
      />
    );
  }
}

SystemAdmin.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatch = {
  getAdminUsers: api.endpoints.getAdminUsers.initiate,
  addUserToRole: api.endpoints.addUserToRole.initiate
};

const mapState = state => {
  const { adminUsers, isAdminUsersLoading, adminUserErrors, userRoleErrors } =
    state.admin;
  const { loggedIn } = state.authentication;

  return {
    loggedIn,
    adminUsers,
    isAdminUsersLoading,
    adminUserErrors,
    userRoleErrors
  };
};

const composedSystemAdmin = compose(
  loader,
  connect(mapState, mapDispatch),
  withStyles(styles, { withTheme: true })
)(SystemAdmin, false);

export { composedSystemAdmin as SystemAdmin };
