const styles = () => ({
  container: {
    marginTop: 25
  },
  boldSubtitle: {
    fontWeight: 500
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  selectedLight: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  sectionNavHeader: {
    padding: '12px 24px 4px 24px',
    display: 'flex'
  },
  sectionNavHeaderText: {
    '& span': {
      textAlign: 'center'
    }
  },
  sectionWrapper: {}
});

export default styles;
