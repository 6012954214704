const drawerWidth = 230;

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    minHeight: '100vh',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  drawerHiddenSmallDown: {
    minHeight: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  drawerDocked: {
    minHeight: '100%'
  },
  toolbarLogo: {
    margin: '0px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  menuList: {
    padding: 0
  },
  version: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  heavy: {
    fontWeight: 500
  }
});

export default styles;
