import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';

const styles = theme => Styles(theme);

class DropDownSelect extends React.Component {
  render() {
    let { currentKey } = this.props;

    const {
      classes,
      currentPlaceholder,
      currentFieldName,
      currentValue,
      onFieldChanged,
      items,
      onKeyPress,
      autoFocus,
      error,
      helperText,
      inputProps
    } = this.props;

    // TODO This is only temporarily here to add keys from previously saved
    //      versions that didn't use keys - remove for production
    if (
      currentValue != null &&
      currentValue !== '' &&
      (currentKey == null || currentKey === '')
    ) {
      currentKey = items.find(x => x.value === currentValue).key;
    } else if (currentValue == null || currentValue === '') {
      currentKey = '';
    }

    return items && items.length > 0 ? (
      <FormControl className={classes.formControl} error={error}>
        <InputLabel htmlFor={currentFieldName}>{currentPlaceholder}</InputLabel>
        <Select
          id={currentFieldName}
          value={currentKey.toString()}
          onChange={e => {
            const value = items.find(x => x.key === e.target.value).value;
            onFieldChanged(value, e.target.value);
          }}
          inputProps={{
            name: 'value',
            id: 'key',
            ...inputProps
          }}
          classes={{
            select: classes.select
          }}
          onKeyUp={onKeyPress}
          autoFocus={autoFocus}
        >
          {items.map(x => (
            <MenuItem value={x.key} key={x.key}>
              {x.value}
            </MenuItem>
          ))}
        </Select>
        {error ? <FormHelperText>{helperText}</FormHelperText> : ''}
      </FormControl>
    ) : null;
  }
}

export default withStyles(styles)(DropDownSelect);

