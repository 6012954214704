import { createStyles, StyleRules } from '@material-ui/core';

const styles = (): StyleRules =>
  createStyles({
    heading: {
      fontWeight: 500,
      flexBasis: '90%',
      flexShrink: 0
    }
  });

export default styles;
