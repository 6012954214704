import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  coerceTypes,
  fieldStart,
  getFieldInfo,
  getFieldInfoFromName,
  getNonRepeatableSectionId
} from './unrefactored/fields';
import { isEmpty, isNumeric } from '../../helpers/types';
import { defaultPayload } from '../../helpers/redux';
import { aliasedResponse } from './aliased-response-thunk';
import {
  setContext,
  setCurrentDisplaySection,
  setCurrentFieldInfo,
  setQuestionItems,
  setQuestionnaireStatus,
  setResponse,
  setSectionStatus
} from './questionnaire-slice';
import {
  NavigationConstants,
  QuestionnaireStatusConstants,
  SectionStatusConstants
} from '../../helpers/constants';
import { history } from '../../helpers/history';
import { addAutoHidingAlert } from '../alerts-slice';
import { getQuestionItems } from './supporting-data-thunks';
import { setParsedQuestionText } from './questionnaire-typed-thunks';
import moment from 'moment';
import { api } from '../../services/api/dataApi';
import { navigation } from './unrefactored/navigation';
import { removeOrphanedSectionStatus } from './route-building-thunks';

export const setCoercedResponse = createAsyncThunk(
  'newQuestionnaire/setResponse',
  /** @param input {{ fieldInfo: FieldInfo, value: string, key: string, questionText: string, displaySectionId: number }}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (
    { fieldInfo, value, key, questionText, displaySectionId },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;

    const response = {
      question: questionText,
      value: coerceTypes(value),
      key: coerceTypes(key),
      displaySectionId: displaySectionId,
      ...fieldInfo
    };

    await dispatch(
      setResponse({
        fieldName: fieldInfo.fieldName,
        response
      })
    );
  }
);

export const setQuestionContext = createAsyncThunk(
  'newQuestionnaire/setQuestionContext',
  /** @param currentFieldInfo {FieldInfo}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (currentFieldInfo, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaire = getState().newQuestionnaire.questionnaire;
    const currentQuestion = questionnaire.questions.find(
      x => x.id === currentFieldInfo.questionId
    );
    const filteredContext = currentQuestion.questionSupport.filter(
      x => x.groupId == null && x.forNextSection === false
    );

    if (filteredContext.length > 0) {
      const parsedContext = {};

      for (const x of filteredContext) {
        console.log(x);

        let value = x.value == null ? '' : x.value;

        if (value.indexOf(fieldStart) !== -1) {
          const alias = x.value.substring(2, x.value.length - 2);
          value = defaultPayload(
            await dispatch(
              aliasedResponse({ alias, fieldInfo: currentFieldInfo })
            )
          );
        }

        if (isNumeric(value)) {
          value = !isNumeric(value) ? value : parseFloat(value);
        } else {
          value =
            value.indexOf('[') !== -1 && value.indexOf(']') !== -1
              ? JSON.parse(value)
              : value;
        }

        parsedContext[x.key] = coerceTypes(value);
      }

      dispatch(setContext({ key: null, value: parsedContext }));
    }
  }
);

export const setDisplaySection = createAsyncThunk(
  'newQuestionnaire/setDisplaySection',
  /** @param input {{ currentFieldInfo: FieldInfo, previousFieldInfo: FieldInfo }}
   @param thunkAPI {GetThunkAPI<{}>} */
  async ({ currentFieldInfo, previousFieldInfo }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaire = getState().newQuestionnaire.questionnaire;

    if (questionnaire && questionnaire.questions && currentFieldInfo != null) {
      const currentSectionId = getNonRepeatableSectionId(currentFieldInfo);

      // Only update section info if changed
      if (previousFieldInfo != null) {
        const prevSectionId = getNonRepeatableSectionId(previousFieldInfo);

        if (currentSectionId !== prevSectionId) {
          const currentSection = questionnaire.sections.find(
            x => x.id === currentSectionId
          );

          dispatch(setCurrentDisplaySection(currentSection));
        }
      } else {
        const currentSection = questionnaire.sections.find(
          x => x.id === currentSectionId
        );
        dispatch(setCurrentDisplaySection(currentSection));
      }
    }
  }
);

export const setSectionCompletionStatus = createAsyncThunk(
  'newQuestionnaire/setSectionCompletionStatus',
  /** @param input {{ currentFieldInfo: FieldInfo, nextFieldInfo: FieldInfo, fieldWasInserted: boolean }}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (
    { currentFieldInfo, nextFieldInfo, fieldWasInserted = false },
    thunkAPI
  ) => {
    const { getState, dispatch } = thunkAPI;

    let questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;
    const sections = questionnaire.sections;

    const currentSectionId = getNonRepeatableSectionId(currentFieldInfo);
    const currentSection = sections.find(x => x.id === currentSectionId);

    // Check whether we're at end of questionnaire
    if (nextFieldInfo !== NavigationConstants.END_QUESTIONNAIRE) {
      // If our field was inserted (rather than stuck on the end), we know its an edit and we will need to
      // mark section as not complete
      if (fieldWasInserted) {
        dispatch(
          setSectionStatus({
            sectionId: currentSectionId.toString(),
            status: SectionStatusConstants.Started
          })
        );
      }

      // However we can still be completing so check that too
      const nextSectionId = getNonRepeatableSectionId(nextFieldInfo);
      const nextSection = sections.find(x => x.id === nextSectionId);

      if (
        currentSectionId !== nextSectionId &&
        nextSection.sequence > currentSection.sequence
      ) {
        dispatch(
          setSectionStatus({
            sectionId: currentSectionId.toString(),
            status: SectionStatusConstants.Complete
          })
        );
      }
    } else {
      // Mark section as complete
      dispatch(
        setSectionStatus({
          sectionId: currentSectionId.toString(),
          status: SectionStatusConstants.Complete
        })
      );

      const allComplete = defaultPayload(
        await dispatch(areAllSectionsComplete())
      );

      // If so tell the user
      if (allComplete) {
        dispatch(removeOrphanedSectionStatus());
        dispatch(setQuestionnaireCompletionStatus());
        history.push('/completed');
      } else {
        dispatch(
          addAutoHidingAlert({
            message: 'Before submitting all sections must be complete.',
            variant: 'warning',
            autoDismiss: true
          })
        );
      }
    }
  }
);

export const setQuestionnaireCompletionStatus = createAsyncThunk(
  'newQuestionnaire/setQuestionnaireCompletionStatus',
  /** @param input {any}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (input, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;
    const responses = questionnaireState.responses;
    const allComplete = defaultPayload(
      await dispatch(areAllSectionsComplete())
    );

    const submitted = questionnaireState.questionnaireSubmitted;

    const status = submitted
      ? QuestionnaireStatusConstants.Submitted
      : allComplete
      ? QuestionnaireStatusConstants.Complete
      : responses.size > 1
      ? QuestionnaireStatusConstants.Started
      : QuestionnaireStatusConstants.NotStarted;

    dispatch(setQuestionnaireStatus(status));
  }
);

export const setQuestionAnswerItems = createAsyncThunk(
  'newQuestionnaire/setQuestionAnswerItems',
  /** @param currentFieldInfo {FieldInfo}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (currentFieldInfo, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const result = defaultPayload(
      await dispatch(getQuestionItems(currentFieldInfo))
    );

    await dispatch(setQuestionItems(result));
  }
);

export const sectionNavigate = createAsyncThunk(
  'newQuestionnaire/sectionNavigate',
  /** @param sectionId {number}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (sectionId, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const saveLocation = api.endpoints.saveLocation.initiate;
    const questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;
    const questionnaireId = questionnaire.id;
    const currentFieldInfo = questionnaireState.currentFieldInfo;

    const sectionQuestions = questionnaire.questions
      .filter(x => x.section.id === sectionId)
      .sort((x, y) => {
        return x.sequence - y.sequence;
      });

    const question = sectionQuestions[0];
    const nextFieldInfo = getFieldInfo(question.id, sectionId);

    await dispatch(setCurrentFieldInfo(nextFieldInfo));
    await dispatch(setParsedQuestionText(nextFieldInfo));
    await dispatch(
      setDisplaySection({
        currentFieldInfo: nextFieldInfo,
        previousFieldInfo: currentFieldInfo
      })
    );
    await dispatch(setQuestionAnswerItems(nextFieldInfo));

    await dispatch(
      saveLocation({
        questionnaireId,
        currentFieldInfo: JSON.stringify(nextFieldInfo)
      })
    );

    if (history.location.pathname !== '/start') {
      history.push('/start');
    }
  }
);

export const questionNavigate = createAsyncThunk(
  'newQuestionnaire/questionNavigate',
  /** @param fieldName {string}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (fieldName, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    const saveLocation = api.endpoints.saveLocation.initiate;
    const nextFieldInfo = getFieldInfoFromName(fieldName);
    const questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;
    const questionnaireId = questionnaire.id;
    const currentFieldInfo = questionnaireState.currentFieldInfo;

    await dispatch(setCurrentFieldInfo(nextFieldInfo));
    await dispatch(setParsedQuestionText(nextFieldInfo));
    await dispatch(
      setDisplaySection({
        currentFieldInfo: nextFieldInfo,
        previousFieldInfo: currentFieldInfo
      })
    );
    await dispatch(setQuestionAnswerItems(nextFieldInfo));

    await dispatch(
      saveLocation({
        questionnaireId,
        currentFieldInfo: JSON.stringify(nextFieldInfo)
      })
    );

    if (history.location.pathname !== '/start') {
      history.push('/start');
    }
  }
);

export const saveResponsesToServer = createAsyncThunk(
  'newQuestionnaire/saveResponsesToServer',
  /** @param input {any}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (input, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const saveResponses = api.endpoints.saveResponses.initiate;
    const questionnaireState = getState().newQuestionnaire;

    const {
      responses,
      contexts,
      sectionStatuses,
      questionnaireStatus,
      questionnaireId,
      consentAccepted,
      eulaAccepted
    } = questionnaireState;

    const requestResponses =
      isEmpty(responses) || responses.size === 0
        ? null
        : JSON.stringify(responses);
    const requestContexts =
      isEmpty(contexts) || contexts.size === 0
        ? null
        : JSON.stringify(contexts);
    const requestSectionStatuses =
      isEmpty(sectionStatuses) || sectionStatuses.size === 0
        ? null
        : JSON.stringify(sectionStatuses);

    const request = {
      questionnaireId,
      responses: requestResponses,
      contexts: requestContexts,
      sectionStatuses: requestSectionStatuses,
      consentAccepted,
      eulaAccepted,
      status: questionnaireStatus,
      userAgent: navigator.userAgent,
      timeStamp: moment().utc().format()
    };

    await dispatch(saveResponses(request));
  }
);

export const areAllSectionsComplete = createAsyncThunk(
  'newQuestionnaire/sectionNavigate',
  /** @param input  {any}
   * @param thunkAPI {GetThunkAPI<{}>}
   * @return Promise<boolean>
   */
  async (input, thunkAPI) => {
    const { getState } = thunkAPI;
    const questionnaireState = getState().newQuestionnaire;

    const currentFieldInfo = questionnaireState.currentFieldInfo;
    const sections = questionnaireState.questionnaire.sections;
    const sectionStatuses = questionnaireState.sectionStatuses;

    const nextFieldInfo = await navigation.getNextQuestion(currentFieldInfo);

    // Check whether all sections are complete
    // This depends whether we have reached the end of screening
    const endOfQuestionnaire =
      nextFieldInfo === NavigationConstants.END_QUESTIONNAIRE;

    const screeningSectionIds = sections
      .filter(section => section.isScreening)
      .map(section => section.id);
    const isScreening = screeningSectionIds.includes(
      currentFieldInfo.sectionId
    );

    let allSectionsComplete = false;

    if (endOfQuestionnaire && isScreening) {
      allSectionsComplete =
        sectionStatuses
          .filter(
            sectionStatus =>
              screeningSectionIds.includes(sectionStatus.key) &&
              sectionStatus.value !== SectionStatusConstants.Complete
          )
          .count() === 0;
    } else {
      allSectionsComplete =
        sectionStatuses
          .valueSeq()
          .filter(x => x !== SectionStatusConstants.Complete)
          .count() === 0;
    }

    return allSectionsComplete;
  }
);
