const styles = theme => ({
  innerContainer: {
    padding: 20
  },
  root: {
    position: 'relative',
    width: '100%',
    marginTop: '56px',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      marginTop: '48px'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px'
    },
    boxSizing: 'border-box',
    overflowY: 'visible'
  }
});

export default styles;
