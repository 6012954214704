import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Cookie from '../../../helpers/cookies';
import { logout as authLogout } from '../../../features/authentication-slice';
import { logout as organisationLogout } from '../../../features/organisation-slice';
import { logout as questionnaireLogout } from '../../../features/questionnaire/questionnaire-slice';
import { history } from '../../../helpers/history';
import { adminLogout } from '../../../features/admin-slice';

type Props = PropsFromRedux;
type PropsFromRedux = ConnectedProps<typeof connector>;

const Logout: React.FC<Props> = ({
  authLogout,
  questionnaireLogout,
  organisationLogout,
  adminLogout
}) => {
  useEffect(() => {
    authLogout();
    questionnaireLogout();
    organisationLogout();
    adminLogout();

    Cookie.delete('serviceCode');
    history.push('/signin');
  }, []);

  return null;
};

const mapDispatch = {
  authLogout,
  questionnaireLogout,
  organisationLogout,
  adminLogout
};

const connector = connect(null, mapDispatch);

const connectedLogout = connector(Logout);
export { connectedLogout as Logout };
