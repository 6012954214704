import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '../../../StyledTextField';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Styles from './styles';

const styles = theme => Styles(theme);

const NoOptionsMessage = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = props => {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          // className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      InputLabelProps={{ shrink: true }}
      value={props.selectProps.value.label}
      label={props.selectProps.placeholder}
      error={props.selectProps.error}
      helperText={props.selectProps.helperText}
      onKeyUp={props.selectProps.onKeyPress}
      onFocus={props.selectProps.onFocus}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      className={props.selectProps.classes.options}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const SingleValue = props => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const ValueContainer = props => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};

const MultiValue = props => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={event => {
        props.removeProps.onClick();
        props.removeProps.onMouseDown(event);
      }}
    />
  );
};

const Menu = props => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

const MenuList = props => {
  return (
    <div className={props.selectProps.classes.menuList} {...props.innerProps}>
      {props.children}
    </div>
  );
};

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  Menu,
  MenuList
};

class AutoCompleteSelect extends React.Component {
  componentDidUpdate() {
    // TODO Remove this for production - updating keys when they have changed but value hasn't!!!!
    // TODO Shouldn't normally happen
    // let { currentKey, currentValue } = this.props;
    // const { items, onFieldChanged } = this.props;
    //
    // if (
    //   currentValue != null &&
    //   currentValue !== '' &&
    //   items &&
    //   items.find(x => x.key === currentKey) == null
    // ) {
    //   const valObj = items.find(x => x.value === currentValue);
    //   currentKey = valObj != null ? valObj.key : null;
    //   currentValue = valObj != null ? currentValue : null;
    //
    //   onFieldChanged(currentValue, currentKey);
    // }
  }

  render() {
    let { currentKey, currentValue } = this.props;

    const {
      classes,
      theme,
      items,
      placeholder,
      helperText,
      onFieldChanged,
      onKeyPress,
      error
    } = this.props;

    if (
      currentValue != null &&
      currentValue !== '' &&
      (currentKey == null || currentKey === '')
    ) {
      currentKey = items.find(x => x.value === currentValue).key;
    } else if (currentValue == null) {
      currentValue = '';
      currentKey = '';
    }

    const value = { value: currentKey, label: currentValue };

    const options =
      items && items.length > 0
        ? items.map(x => ({
            value: x.key,
            label: x.value
          }))
        : [];

    const inOptions = !!(
      currentKey &&
      options &&
      options.find(item => item.value === currentKey)
    );

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    return (
      <div className={classes.root}>
        <Select
          error={error}
          styles={selectStyles}
          classes={classes}
          options={options}
          components={components}
          value={inOptions && value}
          getOptionValue={opt => opt.value}
          getOptionLabel={opt => opt.label}
          onChange={e => onFieldChanged(e.label, e.value)}
          placeholder={placeholder}
          helperText={helperText}
          onKeyPress={onKeyPress}
          onFocus={this.onFocus}
          autoFocus
        />
      </div>
    );
  }
}

AutoCompleteSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(AutoCompleteSelect);
