const styles = () => ({
  container: {
    marginTop: 20,
    whiteSpace: 'pre-line'
  },
  subtitle1: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.75',
    letterSpacing: '0'
  }
});

export default styles;
