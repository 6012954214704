const styles = theme => ({
    container: {
        marginTop: 16,
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    centredInput: {
        width: 210,

        '& input': {
           textTransform: 'uppercase',
            textAlign: 'center'
        }
    },
    bulletList: {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 16
    },

    pedigreeWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
        isoPedigree: {
        width: '100%',
        textAlign: 'center',
        flexBasis: '100%',
        flexGrow: 0,
        flexShrink: 1,
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
          maxHeight: '100%',
        },
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        }
    },
    errorText: {
        color: theme.palette.error.main,
        fontSize: 12
    },
    heavy: {
        fontWeight: 500
    }
});

export default styles;
