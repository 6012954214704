import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { TweenMax, Circ } from 'gsap';
import Styles from './styles';

const styles = theme => Styles(theme);

class Helix extends React.Component {
  startAnimation = () => {
    TweenMax.staggerFrom(
      '.nucleobase',
      1.2,
      {
        opacity: 0.0,
        transform: 'translateY(20vh) scale(0)',
        delay: 0.333,
        transformOrigin: '50% 50%',
        ease: Circ.easeOut,
        force3D: true
      },
      0.06
    );
  };

  componentDidMount() {
    this.startAnimation();
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.container}>
          <div className={classes.dna}>
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
            <div className={classes.nucleobase} />
          </div>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.loadingText}>
            <span className={classes.dotthree}>. </span>
            <span className={classes.dottwo}>. </span>
            <span className={classes.dotone}>. </span>
            LOADING
            <span className={classes.dotone}> .</span>
            <span className={classes.dottwo}> .</span>
            <span className={classes.dotthree}> .</span>
          </Typography>
        </div>
      </Fragment>
    );
  }
}

Helix.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Helix);
