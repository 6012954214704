import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNumeric } from '../../helpers/types';
import {
  coerceTypes,
  emptyResponse,
  getContextKey,
  getFieldInfo,
  getFieldInfoFromName,
  getNextResponseFieldInfo,
  getNonRepeatableSectionId,
  getRootResponseFieldInfo,
  getSectionInfoFromKey
} from './unrefactored/fields';
import {
  deleteDraftResponse,
  deleteResponse,
  deleteSectionStatus,
  setResponses
} from './questionnaire-slice';
import { navigation } from './unrefactored/navigation';
import { insertAfter } from '../../helpers/immutable';
import { NavigationConstants } from '../../helpers/constants';

export const checkLoopSections = createAsyncThunk(
  'newQuestionnaire/checkLoopSections',
  /**  @param input {{fieldInfo: FieldInfo, draftResponse: DraftResponse}}
   @param thunkAPI {GetThunkAPI<{}>} */
  async ({ fieldInfo, draftResponse }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;
    const responses = questionnaireState.responses;
    const contexts = questionnaireState.contexts;
    const currentQuestion = questionnaire.questions.find(
      x => x.id === fieldInfo.questionId
    );
    const postNavExpression = currentQuestion.postNavigationExpression;
    const prevResponse = responses.get(fieldInfo.fieldName, null);

    if (isNumeric(draftResponse.value)) {
      const responseValue = parseInt(draftResponse.value, 10);

      if (
        postNavExpression.includes('startRepeatableSection') &&
        responseValue < prevResponse.value
      ) {
        // Need to look at our context sectionSourceField to work out our section details
        const sectionContext = contexts.filter(
          x => x.get('SectionSourceField') === fieldInfo.fieldName
        );
        const contextKey = sectionContext.keySeq().first();
        dispatch(
          removeLoopSections({ upperLoopCount: responseValue, contextKey })
        );
      }
    }
  }
);

export const removeResponsesBetween = createAsyncThunk(
  'newQuestionnaire/removeResponsesBetween',
  /**  @param input {{startFieldInfo: FieldInfo, newNextFieldInfo: FieldInfo | null, oldNextFieldInfo: FieldInfo | null}}
   @param thunkAPI {GetThunkAPI<{}>} */
  async ({ startFieldInfo, newNextFieldInfo, oldNextFieldInfo }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;
    const responses = questionnaireState.responses;

    // Get our responses after the start field
    const responseKeys = responses.keySeq();
    const startFieldIndex = responseKeys.findIndex(
      x => x === startFieldInfo.fieldName
    );

    // If we haven't got a newNext we're removing everything up to (and including, hence + 1) the end
    let newNextFieldIndex = null;
    if (newNextFieldInfo == null) {
      newNextFieldIndex =
        responseKeys.findIndex(x => x === responseKeys.last()) + 1;
    } else {
      newNextFieldIndex = responseKeys.findIndex(
        x => x === newNextFieldInfo.fieldName
      );
    }

    // Drop out without an oldNextFieldInfo
    if (oldNextFieldInfo == null) return;

    const oldNextFieldIndex = responseKeys.findIndex(
      x => x === oldNextFieldInfo.fieldName
    );

    // If newNextFieldInfo response isn't present we're inserting a new response so can't do anything here
    if (newNextFieldIndex === -1 && oldNextFieldIndex !== -1) return;

    const size = newNextFieldIndex - (startFieldIndex + 1);

    if (size > 0) {
      const removeResponseKeys = responseKeys.slice(
        startFieldIndex + 1,
        newNextFieldIndex
      );

      removeResponseKeys.forEach(x => {
        dispatch(deleteResponse(x));
        dispatch(deleteDraftResponse(x));
      });
    }
  }
);

export const removeOrphanedResponses = createAsyncThunk(
  'newQuestionnaire/removeOrphanedResponses',
  /**  @param input {{nextFieldInfo: FieldInfo, currentFieldInfo: FieldInfo | null}}
   @param thunkAPI {GetThunkAPI<{}>} */
  async ({ nextFieldInfo, currentFieldInfo = null }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;

    const questionnaire = questionnaireState.questionnaire;
    const responses = questionnaireState.responses;

    // Right this is all a bit barking but essentially we are trying to figure out if the following field is in the
    // correct location - otherwise a field insert can result in orphaned responses pushed ahead of the correct set
    // So we look at our new current nextField (that has just been inserted) and checking that the field after it
    // is correctly in sequence - simple (ahem)

    // First check we're not doing and end questionnaire trim
    // nextFieldInfo will be END_QUESTIONNAIRE and we should get a currentFieldInfo
    if (
      nextFieldInfo === NavigationConstants.END_QUESTIONNAIRE &&
      currentFieldInfo != null
    ) {
      // Remove all fields between current and end of set
      // Get last field
      const lastField = responses.keySeq().last();
      const lastFieldInfo = getFieldInfoFromName(lastField);

      dispatch(
        removeResponsesBetween({
          startFieldInfo: currentFieldInfo,
          newNextFieldInfo: null,
          oldNextFieldInfo: lastFieldInfo
        })
      );
    }

    // Start by getting following field of our just inserted next field
    let followingFieldInfo = getNextResponseFieldInfo(nextFieldInfo, responses);

    if (followingFieldInfo != null) {
      const questions = questionnaire.questions;

      let nextFieldIndex = questions.findIndex(
        x =>
          x.section.id === nextFieldInfo.sectionId &&
          x.id === nextFieldInfo.questionId
      );
      let followingFieldIndex = questions.findIndex(
        x =>
          x.section.id === followingFieldInfo.sectionId &&
          x.id === followingFieldInfo.questionId
      );

      // Case 1 - Following field is in a repeatable loop but next field isn't
      // Find the root field that spawned the following field and compare index
      if (
        followingFieldInfo.sectionInstanceId != null &&
        nextFieldInfo.sectionInstanceId == null
      ) {
        const followingRootFieldInfo =
          getRootResponseFieldInfo(followingFieldInfo);
        followingFieldIndex = questions.findIndex(
          x =>
            x.section.id === followingRootFieldInfo.sectionId &&
            x.id === followingRootFieldInfo.questionId
        );

        // Case 2 - Next fields is in a repeatable loop but following isn't
        // Find the root field of next and compare
      } else if (
        followingFieldInfo.sectionInstanceId == null &&
        nextFieldInfo.sectionInstanceId != null
      ) {
        const nextRootFieldInfo = getRootResponseFieldInfo(nextFieldInfo);
        nextFieldIndex = questions.findIndex(
          x =>
            x.section.id === nextRootFieldInfo.sectionId &&
            x.id === nextRootFieldInfo.questionId
        );

        // Case 3 - Both repeatable but in different section
        // Find the roots of both and compare
      } else if (
        followingFieldInfo.sectionId !== nextFieldInfo.sectionId ||
        followingFieldInfo.sectionInstanceId !== nextFieldInfo.sectionInstanceId
      ) {
        const followingRootFieldInfo =
          getRootResponseFieldInfo(followingFieldInfo);
        followingFieldIndex = questions.findIndex(
          x =>
            x.section.id === followingRootFieldInfo.sectionId &&
            x.id === followingRootFieldInfo.questionId
        );
        const nextRootFieldInfo = getRootResponseFieldInfo(nextFieldInfo);
        nextFieldIndex = questions.findIndex(
          x =>
            x.section.id === nextRootFieldInfo.sectionId &&
            x.id === nextRootFieldInfo.questionId
        );

        // Case 4 - Same repeatable section, but following has a lower sectionIndex
        // Definitely orphaned so just force followingFieldIndex to be lower
      } else if (
        followingFieldInfo.sectionId === nextFieldInfo.sectionId &&
        followingFieldInfo.sectionInstanceId ===
          nextFieldInfo.sectionInstanceId &&
        followingFieldInfo.sectionIndex < nextFieldInfo.sectionIndex
      ) {
        followingFieldIndex = -1;

        // Case 5 - Same repeatable section, but following has a higher sectionIndex
        // This is definitely fine so force nextFieldIndex to be lower
      } else if (
        followingFieldInfo.sectionId === nextFieldInfo.sectionId &&
        followingFieldInfo.sectionInstanceId ===
          nextFieldInfo.sectionInstanceId &&
        followingFieldInfo.sectionIndex > nextFieldInfo.sectionIndex
      ) {
        nextFieldIndex = -1;
      }

      if (followingFieldIndex < nextFieldIndex) {
        // ORPHAN FOUND???
        // eslint-disable-next-line no-debugger
        // debugger;

        //  Need to drop out when we hit next non-repeatable section at the very least
        const rootFieldInfo = getRootResponseFieldInfo(followingFieldInfo);
        const rootSectionId = rootFieldInfo.sectionId;

        while (followingFieldInfo != null) {
          const newNextFieldInfo = getNextResponseFieldInfo(
            followingFieldInfo,
            responses
          );

          // Remove any fields that were created from this one
          dispatch(removeRelatedFields(followingFieldInfo));

          dispatch(deleteResponse(followingFieldInfo.fieldName));
          dispatch(deleteDraftResponse(followingFieldInfo.fieldName));

          // Drop out if different root field
          if (newNextFieldInfo != null) {
            const nextRootFieldInfo =
              getRootResponseFieldInfo(newNextFieldInfo);

            if (nextRootFieldInfo.sectionId !== rootSectionId) {
              break;
            }
          }

          followingFieldInfo = newNextFieldInfo;
        }
      }
    }
  }
);

export const removeRelatedFields = createAsyncThunk(
  'newQuestionnaire/removeRelatedFields',
  /** @param currentFieldInfo {FieldInfo}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (currentFieldInfo, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;
    const contexts = questionnaireState.contexts;
    const responses = questionnaireState.responses;

    const sectionContext = contexts.filter(
      x => x.get('SectionSourceField') === currentFieldInfo.fieldName
    );

    if (sectionContext != null) {
      sectionContext.keySeq().forEach(x => {
        const sectionInfo = getSectionInfoFromKey(x);
        const sectionResponses = responses.filter(
          x =>
            x.sectionId === sectionInfo.sectionId &&
            x.sectionInstanceId === sectionInfo.sectionInstanceId
        );

        if (sectionResponses != null) {
          sectionResponses.forEach(x => {
            dispatch(removeRelatedFields(x));

            dispatch(deleteResponse(x.fieldName));
            dispatch(deleteDraftResponse(x.fieldName));
          });
        }
      });
    }
  }
);

export const removeLoopSections = createAsyncThunk(
  'newQuestionnaire/removeLoopSections',
  /** @param input {{ upperLoopCount: number, contextKey: string }}
   @param thunkAPI {GetThunkAPI<{}>} */
  async ({ upperLoopCount, contextKey }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;

    const responses = questionnaireState.responses;
    const sectionInfo = getSectionInfoFromKey(contextKey);
    const context = questionnaireState.contexts.get(contextKey, null);

    const prevLoopCount = context.get('LoopCount', 0);

    // Loop through appropriate section indexes and remove obsolete fields
    for (
      let sectionIndex = upperLoopCount + 1;
      sectionIndex <= prevLoopCount;
      sectionIndex++
    ) {
      const removeFields = responses.filter(
        x =>
          x.sectionId === sectionInfo.sectionId &&
          x.sectionInstanceId === sectionInfo.sectionInstanceId &&
          x.sectionIndex === sectionIndex
      );

      if (removeFields.size > 0) {
        removeFields.forEach(removeField => {
          // Remove any fields that were created from this one
          dispatch(removeRelatedFields(removeField));

          dispatch(deleteResponse(removeField.fieldName));
          dispatch(deleteDraftResponse(removeField.fieldName));
        });
      }
    }
  }
);

export const checkBranchChanges = createAsyncThunk(
  'newQuestionnaire/checkBranchChanges',
  /** @param input {{ currentFieldInfo: FieldInfo, response: DraftResponse }}
   @param thunkAPI {GetThunkAPI<{}>} */
  async ({ currentFieldInfo, response }, thunkAPI) => {
    const { getState } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;

    const questionnaire = questionnaireState.questionnaire;
    const questions = questionnaire.questions;
    const sections = questionnaire.sections;
    const responses = questionnaireState.responses;
    const contexts = questionnaireState.contexts;
    const currentQuestion = questionnaire.questions.find(
      x => x.id === currentFieldInfo.questionId
    );
    const postNavExpression = currentQuestion.postNavigationExpression;
    const currentSectionId = getNonRepeatableSectionId(currentFieldInfo);

    // Coerce response for accurate comparisons
    const responseValue = coerceTypes(response.value);
    const responseKey = coerceTypes(response.key);

    // Drop out if:
    // There is no existing response
    // OR it has not changed
    // OR there is no postNavigationExpression
    const prevResponse = responses.get(currentFieldInfo.fieldName, null);

    if (
      prevResponse == null ||
      responseValue === prevResponse.value ||
      postNavExpression == null
    ) {
      return false;
    }

    // THE PROCESS
    // IF  we're reducing a loopCount for a section
    // OR if the changed response results in different result from the postNavigationExpression
    // (with some exceptions) THEN data will be lost and we will need confirmation

    // First do the easy one
    // Now check we're not reducing the value of loop counter
    const reducingLoopCount =
      isNumeric(responseValue) &&
      postNavExpression.includes('startRepeatableSection') &&
      parseInt(responseValue, 10) < prevResponse.value;

    if (reducingLoopCount) {
      return Promise.resolve(true);
    }

    // Otherwise find the field in responses after this one (if we can)
    const nextFieldInfo = getNextResponseFieldInfo(currentFieldInfo, responses);

    // No next field found so nothing to do
    if (nextFieldInfo == null) {
      return false;
    }

    const nextResponse = responses.get(nextFieldInfo.fieldName, null);
    const nextSectionId = getNonRepeatableSectionId(nextFieldInfo);

    // Now use navigation to see if we get a different field with current response
    if (nextResponse != null && currentSectionId === nextSectionId) {
      const newResponse = getFieldInfo(
        currentFieldInfo.questionId,
        currentFieldInfo.sectionId,
        currentFieldInfo.sectionInstanceId,
        currentFieldInfo.sectionIndex,
        currentFieldInfo.sectionDepth
      );
      newResponse.value = responseValue;
      newResponse.key = responseKey;

      return navigation
        .previewNextQuestion(currentFieldInfo, [newResponse])
        .then(previewNextFieldInfo => {
          // Need to work out if the preview next field is a genuinely different route
          // Blaaaaaady complicated

          // Case 0: preview next field is end of questionnaire (and wasn't previously)
          if (
            previewNextFieldInfo === NavigationConstants.END_QUESTIONNAIRE &&
            nextFieldInfo !== NavigationConstants.END_QUESTIONNAIRE
          ) {
            return true;
          }

          // Case 1: If preview root field precedes nextFieldInfo in sequence then we don't need confirmation
          // This applies to skippable sections
          const previewRootFieldInfo =
            getRootResponseFieldInfo(previewNextFieldInfo);
          const previewRootIndex = questions.findIndex(
            x =>
              x.id === previewRootFieldInfo.questionId &&
              x.section.id === previewRootFieldInfo.sectionId
          );
          const nextIndex = questions.findIndex(
            x =>
              x.id === nextFieldInfo.questionId &&
              x.section.id === nextFieldInfo.sectionId
          );

          if (nextIndex - 1 === previewRootIndex) {
            return false;
          }

          // Case 2: If the preview field's parent field (field it was spawned from) precedes nextFieldInfo in sequence we also don't need confirmation
          // Only applies if we're in repeatable
          const previewSectionId = previewNextFieldInfo.sectionId;
          const previewRepeatable = sections.find(
            x => x.id === previewSectionId
          ).repeatable;

          if (previewRepeatable) {
            const previewContext = contexts.get(
              getContextKey(
                previewNextFieldInfo.sectionId,
                previewNextFieldInfo.sectionInstanceId
              ),
              null
            );

            if (previewContext == null) {
              return false;
            }

            const previewParentField = getFieldInfoFromName(
              previewContext.get('SectionSourceField', null)
            );
            const previewParentIndex = questions.findIndex(
              x =>
                x.id === previewParentField.questionId &&
                x.section.id === previewParentField.sectionId
            );

            if (nextIndex - 1 === previewParentIndex) {
              return false;
            }
          }

          // Otherwise check whether we will go to a new field (or at end of questionnaire)
          let showConfirm =
            (previewNextFieldInfo === NavigationConstants.END_QUESTIONNAIRE &&
              nextFieldInfo !== NavigationConstants.END_QUESTIONNAIRE) ||
            previewNextFieldInfo.fieldName !== nextFieldInfo.fieldName;

          return showConfirm;
        });
    } else {
      return false;
    }
  }
);

export const synchroniseMappingOrder = createAsyncThunk(
  'newQuestionnaire/synchroniseMappingOrder',
  /** @param input {{ currentFieldInfo: FieldInfo, nextFieldInfo: FieldInfo }}
   @param thunkAPI {GetThunkAPI<{}>} */
  async ({ currentFieldInfo, nextFieldInfo }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;

    const questionnaire = questionnaireState.questionnaire;
    const sections = questionnaire.sections;

    // Re-order responses so flow remains intact
    let responses = questionnaireState.responses;
    let nextResponseExists = false,
      fieldWasInserted = false;
    const nextField = nextFieldInfo.fieldName;
    const nextSectionId = getNonRepeatableSectionId(nextFieldInfo);

    // Grab response if we have one or create empty one
    let nextResponse = responses.get(nextField, null);

    if (nextResponse == null) {
      nextResponse = emptyResponse(nextFieldInfo, nextSectionId);
    } else {
      nextResponseExists = true;
    }

    // Re-arrange responses so our next field comes after the current
    responses = insertAfter(
      responses,
      currentFieldInfo.fieldName,
      nextFieldInfo.fieldName,
      nextResponse
    );

    dispatch(setResponses(responses));

    // We did an insert if next response is not last
    // OR we're on the last section and the nextFieldInfo is not -1000 (navConstants.END_QUESTIONNAIRE)
    if (
      (responses.keySeq().last() !== nextFieldInfo.fieldName &&
        !nextResponseExists) ||
      (nextFieldInfo !== NavigationConstants.END_QUESTIONNAIRE &&
        nextFieldInfo.sectionId === sections[sections.length - 1].id)
    ) {
      fieldWasInserted = true;
    }

    return fieldWasInserted;
  }
);

export const removeOrphanedSectionStatus = createAsyncThunk(
  'newQuestionnaire/removeOrphanedSectionStatus',
  /** @param input any
   @param thunkAPI {GetThunkAPI<{}>} */
  async (input, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;

    const questionnaireState = getState().newQuestionnaire;
    const responses = questionnaireState.responses;
    const sectionStatuses = questionnaireState.sectionStatuses;

    const validSectionIds = responses.reduce((reduction, value) => {
      const sectionId = value.displaySectionId.toString();
      if (!reduction?.includes(sectionId)) {
        reduction.push(sectionId);
      }
      return reduction;
    }, []);

    sectionStatuses
      .keySeq()
      .toArray()
      .map(key => {
        if (!validSectionIds.includes(key)) {
          dispatch(deleteSectionStatus(key));
        }
      });
  }
);
