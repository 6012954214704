import {
  AdminActionConstants,
  QuestionnaireStatusConstants,
  ScreeningResult
} from '../../../helpers/constants';
import { isNumeric } from '../../../helpers/types';
import Swirly from '../../Swirly';
import SadCloud from '../../SadCloud/SadCloud';
import moment from 'moment';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Cookie from '../../../helpers/cookies';
import saveAs from 'file-saver';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Styles from './styles';
import _ from 'lodash';
import {
  getWebSummary,
  getWebCanRisk,
  setActionStatus
} from '../../../features/admin-slice';
import { Save } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { addSpacesAndSentenceCase } from '../../../helpers/strings';
import DropDownSelect from '../../DropDownSelect/DropDownSelect';
import { Card } from '@material-ui/core';

const styles = theme => Styles(theme);

class Submissions extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;

    this.state = {
      referringTrust:
        user && !user.isSuperAdmin ? user?.referringTrust : 'All Trusts'
    };
  }

  onChangePage = page => {
    Cookie.set('currentPage', page, { path: '/', days: 365 });
  };

  onChangeRowsPerPage = rowsPerPage => {
    let rowCount = rowsPerPage;
    if (rowsPerPage !== 10 && rowsPerPage !== 50) {
      rowCount = 'max';
    }
    Cookie.set('rowsPerPage', rowCount, { path: '/', days: 365 });
  };

  onOrderChange = (column, direction) => {
    Cookie.set('sortColumn', column, { path: '/', days: 365 });
    Cookie.set('sortDirection', direction, { path: '/', days: 365 });
  };

  onSearchChange = searchTerm => {
    console.log(`Search changed: ${searchTerm}`);
  };

  downloadSummary = async row => {
    const { nhsNo, status, questionnaireId, serviceCode } = row;

    if (status <= 1) return;

    const { getWebSummary, setActionStatus } = this.props;

    const webSummaryRequest = {
      questionnaireId,
      nhsNo,
      fileType: 'pdf',
      downloadType: 'attachment',
      serviceCode: serviceCode,
      markAsDownloaded: true
    };

    try {
      setActionStatus({ key: nhsNo, status: 'pending' });
      const blob = await getWebSummary(webSummaryRequest);
      saveAs(blob.payload, `${nhsNo}-summary.pdf`);
      setActionStatus({ key: nhsNo, status: 'none' });
    } catch (error) {
      setActionStatus({ key: nhsNo, status: 'error' });
    }
  };

  downloadCanRisk = async (event, row) => {
    event.stopPropagation();
    const { nhsNo, status, questionnaireId, serviceCode } = row;

    if (status <= 1) return;

    const { getWebCanRisk, setActionStatus } = this.props;

    const webCanRiskRequest = {
      questionnaireId,
      nhsNo,
      serviceCode
    };

    try {
      setActionStatus({ key: nhsNo, status: 'pending' });
      const blob = await getWebCanRisk(webCanRiskRequest);
      saveAs(blob.payload, `${nhsNo}-canrisk.txt`);
      setActionStatus({ key: nhsNo, status: 'none' });
    } catch (error) {
      setActionStatus({ key: nhsNo, status: 'error' });
    }
  };

  downloadFiles = async (event, row) => {
    event.stopPropagation();
    this.downloadSummary(row);
  };

  statusName = status => {
    switch (status) {
      case QuestionnaireStatusConstants.Started:
        return 'Started';
      case QuestionnaireStatusConstants.Complete:
        return 'Complete';
      case QuestionnaireStatusConstants.Submitted:
        return 'Submitted';
      case QuestionnaireStatusConstants.Processed:
        return 'Processed';
      case QuestionnaireStatusConstants.NotStarted:
      default:
        return 'Registered';
    }
  };

  getCookieSettings = data => {
    const dataLength = data && data.length ? data.length : 0;
    const cookiePageSize = Cookie.get('rowsPerPage');
    const pageSize =
      cookiePageSize != null &&
      (cookiePageSize === 'max' || isNumeric(cookiePageSize))
        ? cookiePageSize === 'max'
          ? dataLength
          : parseInt(cookiePageSize)
        : 10;

    const cookieCurrentPage =
      Cookie.get('currentPage') &&
      isNumeric(Cookie.get('currentPage')) &&
      parseInt(Cookie.get('currentPage')) >= 0
        ? parseInt(Cookie.get('currentPage'))
        : 0;
    const initialPage =
      cookieCurrentPage >= 0
        ? dataLength > 0 && cookieCurrentPage <= dataLength / pageSize
          ? cookieCurrentPage
          : 0
        : 0;

    const cookieSortColumn = Cookie.get('sortColumn');
    const sortColumn =
      cookieSortColumn &&
      isNumeric(cookieSortColumn) &&
      parseInt(cookieSortColumn) >= 0
        ? parseInt(cookieSortColumn)
        : null;

    const sortDirection = sortColumn ? Cookie.get('sortDirection') : null;

    return {
      pageSize,
      initialPage,
      sortColumn,
      sortDirection
    };
  };

  handleReferringTrustChanged = field => {
    return e => {
      this.setState({ [field]: e });
    };
  };

  renderReferringTrusts() {
    const { service, classes } = this.props;

    const referringTrusts = service?.referringTrusts;

    if (!referringTrusts || referringTrusts.length === 0) return null;

    const allTrusts = {
      id: -1,
      referringTrustName: 'All Trusts',
      service,
      serviceId: service.id,
      isActive: true
    };

    const data = referringTrusts
      .map(referringTrust => {
        return { ...referringTrust };
      })
      .sort((a, b) => {
        if (a.referringTrustName > b.referringTrustName) {
          return 1;
        }
        if (a.referringTrustName < b.referringTrustName) {
          return -1;
        }
        return 0;
      });

    data.splice(0, 0, allTrusts);

    return (
      <div className={classes.scrollOverflow}>
        <Card className={classes.filterCard}>
          <DropDownSelect
            id="referringTrust"
            inputProps={{ 'data-lpignore': 'true', autoComplete: 'off' }}
            currentPlaceholder={'Filtered by Referring Trust'}
            currentValue={this.state.referringTrust}
            currentKey={this.state.referringTrust}
            currentFieldName="referringTrust"
            items={data.map(x => {
              return {
                value: x.referringTrustName,
                key: x.referringTrustName
              };
            })}
            onFieldChanged={this.handleReferringTrustChanged('referringTrust')}
            margin="dense"
            fullWidth
          />
        </Card>
      </div>
    );
  }

  render() {
    const {
      user,
      service,
      serviceCode,
      submissions,
      actionStatuses,
      classes,
      hasScreening,
      hasApiName
    } = this.props;
    const referringTrusts = service?.referringTrusts;
    const idTypeName = serviceCode?.idType ? serviceCode.idType : 'NHS Number';

    const data = submissions.map(submission => {
      const addOp = {
        op:
          _.get(actionStatuses, submission.nhsNo) || AdminActionConstants.None,
        ...submission
      };
      return { ...addOp };
    });

    const submittedCount =
      this.state.referringTrust && this.state.referringTrust !== 'All Trusts'
        ? data.filter(
            submission =>
              submission.referringTrust === this.state.referringTrust &&
              submission.status === QuestionnaireStatusConstants.Submitted
          ).length
        : data.filter(
            submission =>
              submission.status === QuestionnaireStatusConstants.Submitted
          ).length;

    const processedCount =
      this.state.referringTrust && this.state.referringTrust !== 'All Trusts'
        ? data.filter(
            submission =>
              submission.referringTrust === this.state.referringTrust &&
              submission.status === QuestionnaireStatusConstants.Processed
          ).length
        : data.filter(
            submission =>
              submission.status === QuestionnaireStatusConstants.Processed
          ).length;

    const settings = this.getCookieSettings(data);

    const limitWidth = (width, breakWords) => ({
      maxWidth: width,
      whiteSpace: 'pre-wrap',
      ...(breakWords ? { wordBreak: 'break-all' } : {})
    });

    const standardCellStyle = {
      fontSize: 13
    };

    const columns = [
      {
        title: 'Action',
        field: 'thing',
        sorting: false,
        cellStyle: {
          padding: 0,
          textAlign: 'center'
        },
        headerStyle: {
          textAlign: 'center',
          ...standardCellStyle
        },
        render: row => {
          const result =
            row.op === 'pending' ? (
              <Swirly />
            ) : row.op === 'error' ? (
              <SadCloud label="Server Error" />
            ) : null;

          if (result != null) return result;

          return null;
        }
      },
      {
        title: 'CanRisk',
        field: 'canRisk',
        sorting: false,
        cellStyle: {
          ...standardCellStyle,
          textAlign: 'center'
        },
        headerStyle: { ...standardCellStyle, textAlign: 'center' },
        render: row => {
          return (
            <IconButton
              color="primary"
              aria-label="download CanRisk button"
              component="span"
              data-operation="downloadCanRisk"
              onClick={event => this.downloadCanRisk(event, row)}
              disabled={
                user.isViewOnlyAdmin ||
                row.status === QuestionnaireStatusConstants.NotStarted ||
                row.status === QuestionnaireStatusConstants.Started ||
                row.status === QuestionnaireStatusConstants.Complete ||
                (row.screeningResult != null &&
                  row.screeningResult !== ScreeningResult.FullHistoryRequested)
              }
            >
              <Save />
            </IconButton>
          );
        }
      },
      {
        title: idTypeName,
        field: 'nhsNo',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Name',
        field: 'name',
        cellStyle: { ...limitWidth(160, false), ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Email',
        field: 'email',
        cellStyle: { ...limitWidth(240, true), ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Status',
        field: 'status',
        cellStyle: { ...standardCellStyle },
        render: row => this.statusName(row.status),
        headerStyle: { ...standardCellStyle }
      },
      ...(hasScreening
        ? [
            {
              title: 'Screening Result',
              field: 'screeningResult',
              sorting: false,
              cellStyle: {
                ...standardCellStyle,
                textAlign: 'center'
              },
              headerStyle: { ...standardCellStyle, textAlign: 'center' },
              render: row => {
                const notFinished =
                  row.status === QuestionnaireStatusConstants.NotStarted ||
                  row.status === QuestionnaireStatusConstants.Started;

                const screeningResult = row.screeningResult;

                if (notFinished || screeningResult === undefined) return '';

                const screeningResultName = Object.keys(ScreeningResult).find(
                  key => ScreeningResult[key] === row.screeningResult
                );

                return screeningResultName
                  ? addSpacesAndSentenceCase(screeningResultName)
                  : '';
              }
            },
            {
              title: "Don't know's",
              field: 'screeningDontKnowCount',
              sorting: true,
              cellStyle: {
                ...standardCellStyle,
                textAlign: 'center'
              },
              headerStyle: { ...standardCellStyle, textAlign: 'center' },
              render: row => {
                const notFinished =
                  row.status === QuestionnaireStatusConstants.NotStarted ||
                  row.status === QuestionnaireStatusConstants.Started;

                if (notFinished) return '';

                return row.screeningDontKnowCount;
              }
            }
          ]
        : []),
      {
        title: 'Progress',
        field: 'sectionStatus',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle }
      },
      {
        title: 'Registered',
        field: 'registered',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.registered ? moment(row.registered).format('DD/MM/YY') : null
      },
      {
        title: 'Started',
        field: 'started',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.started ? moment(row.started).format('DD/MM/YY') : null
      },
      {
        title: 'Completed',
        field: 'completed',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.completed ? moment(row.completed).format('DD/MM/YY') : null
      },
      {
        title: 'Submitted',
        field: 'submitted',
        cellStyle: { ...standardCellStyle },
        headerStyle: { ...standardCellStyle },
        render: row =>
          row.submitted ? moment(row.submitted).format('DD/MM/YY') : null
      },
      ...(hasApiName
        ? [
            {
              title: 'Processed',
              field: 'processed',
              cellStyle: { ...standardCellStyle },
              headerStyle: { ...standardCellStyle },
              render: row =>
                row.processed ? moment(row.processed).format('DD/MM/YY') : null
            }
          ]
        : [
            {
              title: 'Downloaded',
              field: 'downloaded',
              cellStyle: { ...standardCellStyle },
              headerStyle: { ...standardCellStyle },
              render: row =>
                row.downloaded
                  ? moment(row.downloaded).format('DD/MM/YY')
                  : null
            }
          ]),
      ...(referringTrusts &&
      referringTrusts.length > 1 &&
      user &&
      user.isSuperAdmin
        ? [
            {
              title: 'Referring Trust',
              field: 'referringTrust',
              cellStyle: { ...standardCellStyle },
              headerStyle: { ...standardCellStyle }
            }
          ]
        : [])
    ];

    if (settings.sortColumn != null && columns.length > settings.sortColumn) {
      columns[settings.sortColumn].defaultSort = settings.sortDirection;
    }

    return (
      <>
        {referringTrusts &&
          referringTrusts.length > 1 &&
          user &&
          user.isSuperAdmin &&
          this.renderReferringTrusts()}
        <div className={classes.scrollOverflow}>
          <MaterialTable
            title={
              <Typography color="inherit" variant="subtitle1">
                All submissions: {submittedCount} questionnaires submitted and{' '}
                {processedCount} processed
              </Typography>
            }
            columns={columns}
            data={
              this.state.referringTrust &&
              this.state.referringTrust !== 'All Trusts'
                ? data.filter(
                    x => x.referringTrust === this.state.referringTrust
                  )
                : data
            }
            onRowClick={(event, row) => {
              const canDownload = !(
                row.status === QuestionnaireStatusConstants.NotStarted ||
                row.status === QuestionnaireStatusConstants.Started ||
                row.status === QuestionnaireStatusConstants.Complete
              );
              user &&
                !user.isViewOnlyAdmin &&
                (user.isAdmin || user.isSuperAdmin) &&
                canDownload &&
                this.downloadFiles(event, row);
            }}
            options={{
              ...settings,
              pageSizeOptions: [
                ...(data && data.length > 10
                  ? [10]
                  : data && data.length <= 10
                  ? [data.length]
                  : []),
                ...(data && data.length > 50
                  ? [50]
                  : data && data.length > 10 && data.length <= 50
                  ? [data.length]
                  : []),
                ...(data && data.length > 50 ? [data.length] : [])
              ]
            }}
            onChangePage={this.onChangePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
            onOrderChange={this.onOrderChange}
            onSearchChange={this.onSearchChange}
          />
        </div>
      </>
    );
  }
}

const mapDispatch = {
  getWebSummary,
  getWebCanRisk,
  setActionStatus
};

const mapState = state => {
  const { user } = state.authentication;
  const { submissions, actionStatuses, isSubmissionsLoading } = state.admin;

  const { organisation, service, serviceCode } = state.organisation;

  const hasScreening = !!serviceCode?.screeningQuestionnaireId;

  const hasApiName = !!serviceCode?.apiName;

  return {
    user,
    isSubmissionsLoading,
    actionStatuses,
    submissions,
    organisation,
    service,
    serviceCode,
    hasScreening,
    hasApiName
  };
};

const composedSubmissions = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(Submissions);

export default composedSubmissions;
