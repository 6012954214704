import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Swirly from '../../Swirly';
import SadCloud from '../../SadCloud/SadCloud';
import loader from '../../Loader/Loader';
import TitleCard from '../../Cards/TitleCard/index';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import TextField from '../../StyledTextField';
import { connect } from 'react-redux';
import { run } from '../../../validation/ruleRunner';
import { fieldValidations } from './validation';
import { history } from '../../../helpers/history';
import saveAs from 'file-saver';
import {
  getResetPasswordLink,
  setActionStatus
} from '../../../features/admin-slice';
const styles = theme => Styles(theme);

class ResetUserPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      resetClicked: false,
      newPasswordUrl: '',
      showErrors: false,
      validationErrors: [],
      downloadError: ''
    };
  }

  handleInputChange = field => {
    return e => {
      this.setState({ [field]: e.target.value }, () => {
        const validationErrors = run(this.state, fieldValidations);
        this.setState({ validationErrors });
      });
    };
  };

  handleReset = e => {
    const { email, newPasswordUrl } = this.state;

    e.preventDefault();
    this.setState({ showErrors: true });
    if (Object.getOwnPropertyNames(this.state.validationErrors).length !== 0)
      return null;

    this.downloadResetPasswordPdf(email, newPasswordUrl);

    this.setState({ resetClicked: true, showErrors: false, downloadError: '' });    
  };

  downloadResetPasswordPdf = async (email, newPasswordUrl) => {
    const { getResetPasswordLink } = this.props;

    try {      
      const blob = await getResetPasswordLink({ email, newPasswordUrl });
      saveAs(blob.payload, `Reset Password - ${email}.pdf`);      
      this.setState({ resetClicked: false });
    } catch (error) {
      this.setState({ resetClicked: false, downloadError: 'error' });
    }
  };
  errorFor = field => {
    return this.state.validationErrors[field] || '';
  };

  componentDidMount() {
    const { loggedIn, user } = this.props;
    if (!loggedIn || !user.isSystemAdmin) {
      history.push('/signout');
    }

    // Grab location info for new password
    if (typeof window !== 'undefined') {
      const newPasswordUrl =
        window.location.protocol + '//' + window.location.host + '/newpassword';
      this.setState({ newPasswordUrl });
    }

    // Run validations on initial state
    this.setState({ validationErrors: run(this.state, fieldValidations) });
  }

  render() {
    const { classes } = this.props;
    const { resetClicked, downloadError } = this.state;

    return (
      <form name="resetForm">
        <TitleCard
          cardTitle="Reset User Password"
          cardSubtitle="Use this page to reset user password"
          content={
            <Fragment>
              <Typography variant="subtitle1">
                Enter user&#39;s account email to downlaod reset password link
              </Typography>
              <TextField
                className={classes.input}
                id="email"
                label="User Email"
                placeholder="Enter user&#39;s account email"
                margin="dense"
                onChange={this.handleInputChange('email')}
                error={this.errorFor('email') !== '' && this.state.showErrors}
                helperText={
                  this.state.showErrors ? this.errorFor('email') : null
                }
              />
            </Fragment>
          }
          footer={
            <div>
              <Button type="submit" onClick={this.handleReset} disabled={resetClicked}>
                Get Reset Password Link
              </Button>
              {resetClicked && <Swirly />}
              {downloadError && <SadCloud label="Download Error" />}
            </div>
          }
        />
      </form>
    );
  }
}

ResetUserPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatch = {
  getResetPasswordLink,
  setActionStatus
};

const mapState = state => {
  const { loggedIn, user } = state.authentication;
  
  return {
    loggedIn,
    user
  };
};

const composedResetUserPassword = compose(
  loader,
  connect(mapState, mapDispatch),
  withStyles(styles, { withTheme: true })
)(ResetUserPassword, false);

export { composedResetUserPassword as ResetUserPassword };
