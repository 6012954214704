const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  submitContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    marginTop: 25,
    whiteSpace: 'pre-line'
  },
  pedigreeTitle: {
    marginTop: 25,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    cursor: 'pointer'
  },
  boldSubtitle: {
    fontWeight: 500
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',
    margin: '60px auto'
  },
  svgContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  svg: {
    width: '100%',
    height: '100%'
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center'
    }
  }
});

export default styles;
