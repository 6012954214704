const styles = theme => ({
  input: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

export default styles;
