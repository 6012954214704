import React from 'react';
import { connect } from 'react-redux';
import { restart } from '../../../features/questionnaire/questionnaire-slice';

class Restart extends React.Component {
  async componentDidMount() {
    // const { restart, questionnaireId } = this.props;
    // const currentField = null;
    //
    // // Reset all data and go back to intro
    // await restart();
    //
    // // TODO FIX SAVE
    // // dispatch(actions.saveResponsesToServer({ questionnaireId }, dispatch)).then(
    // //   () => {
    // //     dispatch(
    // //       actions.saveCurrentLocationToServer({ questionnaireId, currentField })
    // //     );
    // //   }
    // // );
    //
    // history.push('/');
  }

  render() {
    return null;
  }
}

const mapDispatch = {
  restart
};

const mapState = state => {
  const { questionnaireId } = state.organisation;

  return {
    questionnaireId
  };
};

const connectedRestart = connect(mapState, mapDispatch)(Restart);
export { connectedRestart as Restart };
