import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TitleCard from '../../Cards/TitleCard';
import Styles from './styles';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import Markdown from '../../Markdown/Markdown';
import loader from '../../Loader/Loader';
import { connect } from 'react-redux';
import { config } from '../../../helpers/config';

const styles = theme => Styles(theme);

class Security extends React.Component {
  renderMarkdownDataSecurity(markdown) {
    return <Markdown>{markdown}</Markdown>;
  }

  renderSGHSWTDataSecurity() {
    const { classes } = this.props;
    const contactEmail = this.props.service?.serviceConfiguration?.contactEmail;
    const email = contactEmail ? contactEmail : config.email;

    return (
      <div className={classes.container}>
        <Typography variant="subtitle1" paragraph>
          The security of your data is our highest priority. This app is owned
          completely by the NHS and the personal data you enter will not be
          shared with anyone outside of the NHS.
          <br />
          <br />
          Your data will only be used for the purpose of providing you with
          healthcare advice. We may use information that does not identify you
          to undertake risk assessment through data models or for reporting or
          research to benefit patients of the future.
          <br />
          <br />
          This app is compliant with General Data Protection Regulation (GDPR).
          The information you enter on this app is protected by taking security
          measures which are compliant with NHS Digital Guidance. The data
          privacy and consent associated with this application can be downloaded{' '}
          <a
            href={process.env.PUBLIC_URL + '/docs/consent-and-privacy.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .<br />
          <br />
          The information you enter is pulled securely into your electronic
          genetics record held within the security framework of St George’s
          University Hospitals NHS Foundation Trust. For further information
          about how we protect your data, please email us:{' '}
          <a href={`mailto:${email}`}>{email}</a>
        </Typography>
      </div>
    );
  }

  render() {
    const NavigationLink = React.forwardRef((props, ref) => {
      return <Link {...props} ref={ref} />;
    });

    NavigationLink.displayName = 'NavigationLink';

    const dataSecurity = this.props.service?.serviceConfiguration?.dataSecurity;

    return (
      <TitleCard
        cardTitle="Data Security"
        cardSubtitle="Please read the following information about data security"
        content={
          dataSecurity
            ? this.renderMarkdownDataSecurity(dataSecurity)
            : this.renderSGHSWTDataSecurity()
        }
      />
    );
  }
}

const mapState = state => {
  const { organisation, service, error } = state.organisation;

  const { loggedIn } = state.authentication;

  return {
    loggedIn,
    organisation,
    service,
    error
  };
};

Security.propTypes = {
  classes: PropTypes.object.isRequired
};

const styledSecurity = compose(
  loader,
  connect(mapState),
  withStyles(styles, { withTheme: true })
)(Security);
export { styledSecurity as Security };
