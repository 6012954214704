import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/api/dataApi';
import { extractRTKQueryError } from '../services/api/apiConfig';

interface NewPasswordState {
  passwordChanged: boolean;
  passwordChanging: boolean;
  passwordChangeErrors?: string;
}

const initialState: NewPasswordState = {
  passwordChanged: false,
  passwordChanging: false
};

const newPasswordSlice = createSlice({
  name: 'newPassword',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(api.endpoints.newPassword.matchFulfilled, state => {
        state.passwordChanged = true;
        state.passwordChanging = false;
        state.passwordChangeErrors = undefined;
      })
      .addMatcher(api.endpoints.newPassword.matchPending, state => {
        state.passwordChanged = false;
        state.passwordChanging = true;
        state.passwordChangeErrors = undefined;
      })
      .addMatcher(api.endpoints.newPassword.matchRejected, (state, action) => {
        if (action.error && action.error.name !== 'ConditionError') {
          const { error, status } = extractRTKQueryError(action);
          state.passwordChangeErrors = error;
          state.passwordChanged = false;
          state.passwordChanging = false;
          console.log(`newPassword failed with: ${status}`);
        }
      });
  }
});

export default newPasswordSlice.reducer;
