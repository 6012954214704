const styles = theme => ({
  submitContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    marginTop: 25,
    whiteSpace: 'pre-line'
  },
  boldSubtitle: {
    fontWeight: 500
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',
    margin: '60px auto'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: theme.spacing(1)
  }
});

export default styles;
