import React, { Fragment } from 'react';
import Alerts from '../../Alerts/Alerts';
import { PrivateRoute } from '../../Routing/PrivateRoute';
import { Questionnaire } from '../../Questionnaire/Questionnaire';
import { Login } from '../../Users/Login/Login';
import { Logout } from '../../Users/Logout/Logout';
import { Register } from '../../Users/Register/Register';
import { Introduction } from '../../Pages/Introduction/Introduction';
import { Restart } from '../../Pages/Restart/Restart';
import { Unsubmit } from '../../Pages/Unsubmit/Unsubmit';
import { Contact } from '../../Pages/Contact';
import { Completed } from '../../Pages/Completed/Completed';
import { Submitted } from '../../Pages/Submitted/Submitted';
import { Documents } from '../../Pages/Documents';
import { Security } from '../../Pages/Security';
import { Admin } from '../../Pages/Admin/Admin';
import { SuperAdmin } from '../../Pages/SuperAdmin/SuperAdmin';
import { SystemAdmin } from '../../Pages/SystemAdmin/SystemAdmin';
import { Route, Switch, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './styles';
import { Confirmed } from '../../Pages/Confirmed';
import { PasswordReset } from '../../Users/PasswordReset/PasswordReset';
import { NewPassword } from '../../Users/NewPassword/NewPassword';
import { Faq } from '../../Pages/FAQ/FAQ';
import { Welcome } from '../../Pages/Welcome/Welcome';
import { OrganisationRoute } from '../../Routing/OrganisationRoute';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfirmAccount } from '../../Users/ConfirmAccount/ConfirmAccount';
import { ResetUserPassword } from '../../Pages/ResetUserPassword/ResetUserPassword';

const styles = theme => Styles(theme);

class Main extends React.Component {
  render() {
    const { classes, questionnaireId } = this.props;

    return (
      <Fragment>
        <div role="main" className={classes.root}>
          <div className={classes.innerContainer}>
            <Switch>
              <Route exact path="/" render={() => <Welcome />} />
              <OrganisationRoute
                exact
                path="/introduction"
                render={() => <Introduction />}
              />
              <OrganisationRoute path="/register" render={() => <Register />} />
              <Route path="/signin" render={() => <Login />} />
              <PrivateRoute
                path="/start"
                render={() => (
                  <Questionnaire questionnaireId={questionnaireId} />
                )}
              />
              <PrivateRoute path="/completed" render={() => <Completed />} />
              <PrivateRoute path="/submitted" render={() => <Submitted />} />
              <PrivateRoute path="/thanks" render={() => <Submitted />} />
              <PrivateRoute path="/unsubmit" render={() => <Unsubmit />} />
              <PrivateRoute path="/restart" render={() => <Restart />} />
              <PrivateRoute path="/admin" render={() => <Admin />} />
              <PrivateRoute path="/superadmin" render={() => <SuperAdmin />} />
              <PrivateRoute path="/systemadmin" render={() => <SystemAdmin />} />
              <PrivateRoute path="/resetuserpassword" render={() => <ResetUserPassword />} />
              <Route path="/password" render={() => <PasswordReset />} />
              <Route path="/newpassword" render={() => <NewPassword />} />
              <Route path="/signout" render={() => <Logout />} />
              <Route path="/activate" render={() => <ConfirmAccount />} />
              <Route path="/confirmed" render={() => <Confirmed />} />
              <Route path="/documents" render={() => <Documents />} />
              <Route path="/security" render={() => <Security />} />
              <Route path="/contact" render={() => <Contact />} />
              <Route path="/faq" render={() => <Faq />} />
              {
                // Default for anything else - match
              }
              <Route path="/" render={null} />
            </Switch>
          </div>
        </div>
        <Alerts />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { questionnaireId, serviceCode, preQuestionnaireId } =
    state.organisation;

  return {
    questionnaireId,
    serviceCode,
    preQuestionnaireId
  };
};

const composedMain = compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(Main);

export default composedMain;
