const styles = theme => ({
  questionContainer: {
    flexGrow: 1,
    flexBasis: '30%',
    flexWrap: 'nowrap',
    margin: theme.spacing(1)
  },
  answerContainer: {
    flexGrow: 1,
    flexBasis: '30%',
    flexDirection: 'row',
    alignItems: 'stretch',
    margin: theme.spacing(1)
  },
  serviceCodeContainer: {
    flexGrow: 1,
    flexBasis: '30%',
    flexDirection: 'row',
    alignItems: 'stretch',
    margin: theme.spacing(1),

    '& input': {
      textTransform: 'uppercase'
    }
  },
  rowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  formControl: {
    width: '100%'
  },
  textContainer: {
    marginTop: 25,
    whiteSpace: 'pre-line'
  },
  passwordMsgContainer: {
    margin: theme.spacing(1)
  },
  submitError: {
    textAlign: 'center',
    color: theme.palette.error.main,
    fontSize: 16,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  dropDownContainer: {
    display: 'flex',
    flexBasis: '30%',
    marginTop: 5
  }
});

export default styles;
