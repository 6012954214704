import { createStyles } from '@material-ui/core';

const styles = theme =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    leftContainer: {
      marginRight: theme.spacing(1),
      flexBasis: 0,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    },
    rightContainer: {
      marginLeft: theme.spacing(1),
      flexBasis: 0,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    },
    forgottenContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    forgottenLink: {
      flex: 0,
      flexBasis: 'initial',
      color: 'black',
      textDecoration: 'none',
      '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none',
        color: 'black'
      }
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: 12
    },
    input: {
      width: 400,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  });

export default styles;
