import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import TitleCard from '../../Cards/TitleCard';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import Styles from './styles';
import { history } from '../../../helpers/history';
import { config } from '../../../helpers/config';
import { QuestionnaireStatusConstants } from '../../../helpers/constants';
import { Link } from 'react-router-dom';
import loader from '../../Loader/Loader';
import saveAs from 'file-saver';
import Helix from '../../Helix';
import { getWebPedigree, getWebSummary } from '../../../features/admin-slice';
import { defaultPayload } from '../../../helpers/redux';
import Markdown from '../../Markdown/Markdown';

const styles = theme => Styles(theme);

class Submitted extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loadingSvg: false,
      loadedSvg: false,
      error: null,
      pedigreeSvg: null,
      loadingSummary: false
    };
  }

  hasCompletedFullQuestionnaire = () => {
    const { serviceCode, questionnaire, sectionStatuses } = this.props;

    const hasScreening = !!serviceCode?.screeningQuestionnaireId;
    let completedFullQuestionnaire = true;

    if (hasScreening && questionnaire && questionnaire.sections) {
      completedFullQuestionnaire = sectionStatuses
        .keySeq()
        .toArray()
        .some(key => {
          const section = questionnaire.sections.find(
            section => section.id === parseInt(key, 10)
          );
          return !section.isScreening;
        });
    }

    return completedFullQuestionnaire;
  };

  removeSVGHeightAndWidth = svg => {
    // Remove height
    let start = svg.indexOf('height');
    let end = svg.indexOf('"', start);
    end = svg.indexOf('"', end + 1);

    svg = svg.substring(0, start) + svg.substr(end + 1, svg.length - end);

    //Remove width
    start = svg.indexOf('width');
    end = svg.indexOf('"', start);
    end = svg.indexOf('"', end + 1);

    svg = svg.substring(0, start) + svg.substr(end + 1, svg.length - end);
    return svg;
  };

  savePedigree = async () => {
    const { user, questionnaireId, getWebSummary } = this.props;
    this.setState({ loadingSummary: true });

    const blob = defaultPayload(
      await getWebSummary({
        questionnaireId,
        userId: user.id,
        fileType: 'pdf',
        downloadType: 'attachment'
      })
    );

    saveAs(blob, 'summary.pdf');
    this.setState({ loadingSummary: false });
  };

  async componentDidMount() {
    this._isMounted = true;

    const completedFullQuestionnaire = this.hasCompletedFullQuestionnaire();

    const { user, questionnaireStatus, questionnaireId, getWebPedigree } =
      this.props;

    if (questionnaireStatus < QuestionnaireStatusConstants.Submitted) {
      history.push('/start');
    }

    if (!completedFullQuestionnaire) return;

    const { loadedSvg, loadingSvg } = this.state;

    !loadingSvg &&
      !loadedSvg &&
      !!user &&
      this.setState({ loadingSvg: true }, async () => {
        try {
          const result = defaultPayload(
            await getWebPedigree({
              questionnaireId,
              userId: user.id,
              fileType: 'svg',
              downloadType: 'inline'
            })
          );

          const svg = this.removeSVGHeightAndWidth(result);

          if (this._isMounted) {
            this.setState({
              pedigreeSvg: svg,
              loadingSvg: false,
              loadedSvg: true,
              error: null
            });
          }
        } catch (err) {
          this.setState({ error: err });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderPedigree = () => {
    const { classes } = this.props;
    const { loadedSvg, loadingSvg, error } = this.state;

    return loadingSvg ? (
      <Helix />
    ) : loadedSvg ? (
      <React.Fragment>
        <div className={classes.pedigreeTitle}>
          <Typography
            className={classes.boldSubtitle}
            variant="subtitle1"
            paragraph
          >
            Your Family Tree
          </Typography>
        </div>
        <Typography variant="subtitle1">
          Click on the diagram below, or click &apos;Save Summary&apos; to
          download a summary of the data you have given us about your personal
          history and affected family members, with your family tree diagram
          attached. Click{' '}
          <a
            href={process.env.PUBLIC_URL + '/docs/pedigree-explained.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{' '}
          for a guide to the diagram.
        </Typography>
        <div className={classes.svgContainer} onClick={this.savePedigree}>
          <div
            className={classes.svg}
            dangerouslySetInnerHTML={{ __html: this.state.pedigreeSvg }}
          />
        </div>
      </React.Fragment>
    ) : error ? (
      <div className={classes.errorMessage}>{error}</div>
    ) : null;
  };

  render() {
    const { classes, serviceConfiguration } = this.props;
    const completedFullQuestionnaire = this.hasCompletedFullQuestionnaire();

    const surveyLink = serviceConfiguration?.surveyLink || config.survey;
    const contactEmail = serviceConfiguration?.contactEmail || config.email;
    const thankyouText = serviceConfiguration?.submittedPageThankYouText;

    return (
      <TitleCard
        cardTitle="Questionnaire Submitted"
        cardSubtitle="Your responses have been submitted"
        content={
          thankyouText ? (
            <Fragment>
              <div className={classes.submitContainer}>
                <div className={classes.textContainer}>
                  <Typography variant="subtitle1">
                    <Markdown>{thankyouText}</Markdown>
                  </Typography>
                </div>
                {completedFullQuestionnaire && this.renderPedigree()}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes.submitContainer}>
                <div className={classes.textContainer}>
                  <Typography variant="subtitle1">
                    Thank you very much for using the Family History
                    Questionnaire Service. We are very grateful for your time.{' '}
                    {!completedFullQuestionnaire ? (
                      <>
                        <br />
                        <br />
                        <strong>
                          A copy of your responses can be downloaded by
                          selecting the &apos;Save Summary&apos; button below.
                        </strong>
                        <br />
                        <br />
                      </>
                    ) : null}
                    Please could you now complete{' '}
                    <a
                      href={surveyLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this short evaluation survey
                    </a>{' '}
                    giving feedback on your experience of using this software so
                    that we can improve it for new patients being referred to
                    our service.
                    <br />
                    <br />
                    Please contact us on{' '}
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a> if you
                    have any comments or would like to discuss anything further
                    with us.
                  </Typography>
                </div>
                {completedFullQuestionnaire && this.renderPedigree()}
              </div>
            </Fragment>
          )
        }
        footer={
          <div className={classes.container}>
            <div className={classes.buttons}>
              {completedFullQuestionnaire && (
                <>
                  <Button
                    href={
                      process.env.PUBLIC_URL + '/docs/pedigree-explained.pdf'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Guide
                  </Button>
                </>
              )}
              <Button
                onClick={this.savePedigree}
                disabled={this.state.loadingSummary}
              >
                Save Summary
              </Button>
              <Button
                component={({ ...props }) => <Link to="/signout" {...props} />}
              >
                Sign Out
              </Button>
            </div>
          </div>
        }
      />
    );
  }
}

const mapDispatch = {
  getWebSummary,
  getWebPedigree
};

const mapState = state => {
  const { user } = state.authentication;

  const { questionnaireId, serviceCode, service } = state.organisation;

  const { sectionStatuses, questionnaireStatus, questionnaire } =
    state.newQuestionnaire;

  return {
    user,
    questionnaireId,
    questionnaireStatus,
    sectionStatuses,
    serviceCode,
    serviceConfiguration: service?.serviceConfiguration,
    questionnaire
  };
};

const connectedSubmitted = compose(
  loader,
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(Submitted);

export { connectedSubmitted as Submitted };
