import { isRequired, minLength } from '../../../validation/rules';
import { ruleRunner } from '../../../validation/ruleRunner';

export const fieldValidations = [
  ruleRunner(
    'serviceCode',
    'Service registration code',
    true,
    isRequired,
    minLength(6)
  )
];
