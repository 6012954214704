import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DisplayTypeConstants,
  SourceScopeConstants,
  SpecificAnswerTypes
} from '../../helpers/constants';
import { defaultPayload } from '../../helpers/redux';
import { aliasedResponse } from './aliased-response-thunk';
import {
  coerceTypes,
  getQuestionIdFromAlias,
  getSectionIdFromName
} from './unrefactored/fields';
import { isNumeric } from '../../helpers/types';

export const getQuestionItems = createAsyncThunk(
  'newQuestionnaire/getQuestionItems',
  /**  @param currentFieldInfo {FieldInfo}
 @param thunkAPI {GetThunkAPI<{}>} */
  async (currentFieldInfo, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const questionnaireState = getState().newQuestionnaire;

    const questionnaire = questionnaireState.questionnaire;
    const globalSupport = questionnaireState.globalSupport;
    const diagnoses = questionnaireState.diagnoses;
    const question = questionnaire.questions.find(
      x => x.id === currentFieldInfo.questionId
    );

    if (
      question.answerType.displayType !== DisplayTypeConstants.DropDownSelect &&
      question.answerType.displayType !== DisplayTypeConstants.DropDownTypeAhead
    )
      return null;

    let dataset = {};

    switch (question.answerType.id) {
      case SpecificAnswerTypes.PreviouslyEnteredRelatives:
        // eslint-disable-next-line no-case-declarations
        const includeAnyOtherRelatives =
          currentFieldInfo.sectionId ===
          getSectionIdFromName('ServiceInteractions');
        // eslint-disable-next-line no-case-declarations
        const previouslyEnteredRelatives = defaultPayload(
          await dispatch(
            getPreviouslyEnteredRelatives(includeAnyOtherRelatives)
          )
        );
        return previouslyEnteredRelatives;
      case SpecificAnswerTypes.CancerType:
        return getDiagnoses(diagnoses);
      case SpecificAnswerTypes.SpecificCancerType:
        // eslint-disable-next-line no-case-declarations
        const filterValue = defaultPayload(
          await dispatch(
            aliasedResponse({
              alias: question.answerType.filter,
              fieldInfo: currentFieldInfo
            })
          )
        );

        return getDiagnoses(diagnoses, filterValue);
      case SpecificAnswerTypes.DropDownGeneTest:
        return questionnaire.questionnaireSupport.filter(
          x =>
            x.groupId === question.answerType.sourceGroupId &&
            x.filter === 'NotPanel'
        );
      default:
    }

    switch (question.answerType.sourceScopeType) {
      case SourceScopeConstants.Global:
        dataset = globalSupport.filter(
          x => x.groupId === question.answerType.sourceGroupId
        );
        break;
      case SourceScopeConstants.Questionnaire:
        dataset = questionnaire.questionnaireSupport.filter(
          x => x.groupId === question.answerType.sourceGroupId
        );
        break;
      case SourceScopeConstants.Section:
        dataset = question.section.sectionSupport.filter(
          x => x.groupId === question.answerType.sourceGroupId
        );
        break;
      case SourceScopeConstants.Page:
        dataset = question.page.pageSupport.filter(
          x => x.groupId === question.answerType.sourceGroupId
        );
        break;
      case SourceScopeConstants.Question:
        dataset = question.questionSupport.filter(
          x => x.groupId === question.answerType.sourceGroupId
        );
        break;
      default:
        return null;
    }

    if (dataset != null) {
      dataset = dataset
        .sort((x, y) => {
          return x.sequence - y.sequence;
        })
        .map(z => {
          return { key: z.key, value: z.value };
        });
    }

    return dataset;
  }
);

const getDiagnoses = (diagnoses, filterValue = null) => {
  if (filterValue == null) {
    const result = diagnoses
      .filter(x => x.isSubType === false)
      .map(x => {
        return { key: x.code, value: x.name };
      });

    return result;
  } else {
    const result = diagnoses
      .filter(x => (x.isSubType === true) & (x.filter === filterValue))
      .sort((x, y) => {
        return x.sequence - y.sequence;
      })
      .map(x => {
        return { key: x.code, value: x.name };
      });

    result.push({ key: 'Other', value: 'Other' });
    result.push({ key: 'Unknown', value: "Don't know" });

    return result;
  }
};

export const getPreviouslyEnteredRelatives = createAsyncThunk(
  'newQuestionnaire/getPreviouslyEnteredRelatives',
  /**  @param includeAnyOtherRelatives {boolean}
   @param thunkAPI {GetThunkAPI<{}>} */
  async (includeAnyOtherRelatives = false, thunkAPI) => {
    const { getState } = thunkAPI;
    const questionnaireState = getState().newQuestionnaire;
    const questionnaire = questionnaireState.questionnaire;
    const relativeNameQuestionId = getQuestionIdFromAlias('RelativeName');
    const questionIds = questionnaire.questions
      .filter(x => x.id === relativeNameQuestionId)
      .map(x => x.id);

    const responses = questionnaireState.responses.toArray();

    // We only want responses up to AnyOtherRelativesSection
    let filteredResponses = [];
    for (
      let responseIndex = 0;
      responseIndex < responses.length;
      responseIndex++
    ) {
      const response = responses[responseIndex];
      const serviceInteractionSectionId = getSectionIdFromName(
        'ServiceInteractions'
      );
      const anyOtherRelativesSectionId =
        getSectionIdFromName('AnyOtherRelatives');
      const lastSectionId = includeAnyOtherRelatives
        ? serviceInteractionSectionId
        : anyOtherRelativesSectionId;
      if (response.sectionId === lastSectionId) {
        break;
      }
      if (
        questionIds.includes(response.questionId) &&
        response.value != null &&
        response.value.length > 0
      ) {
        filteredResponses.push(response);
      }
    }

    const relatives = filteredResponses
      .sort((x, y) => x.value.localeCompare(y.value))
      .map(x => {
        return { key: x.fieldName, value: x.value };
      });

    return relatives;
  }
);

export const parseContext = input => {
  const parsedContext = {};

  for (const item of input) {
    let value = item.value == null ? '' : item.value;

    if (isNumeric(value)) {
      value = !isNumeric(value) ? value : parseFloat(value);
    } else {
      value =
        value.indexOf('[') !== -1 && value.indexOf(']') !== -1
          ? JSON.parse(value)
          : value;
    }

    parsedContext[item.key] = coerceTypes(value);
  }

  return parsedContext;
};
