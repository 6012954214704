import { isRequired, minLength, mustMatch } from '../../../validation/rules';
import { ruleRunner } from '../../../validation/ruleRunner';

export const fieldValidations = [
  ruleRunner('newPassword', 'New Password', true, isRequired, minLength(8)),
  ruleRunner(
    'confirmPassword',
    'Password Confirmation',
    true,
    isRequired,
    mustMatch('newPassword', 'Password')
  )
];
