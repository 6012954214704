const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  tableRow: {
    '&.clicky': {
      cursor: 'pointer'
    }
  },
  banana: {
    fontWeight: '700'
  },
  filterCard: {
    padding: theme.spacing(2),
    display: 'flex'
  },
  actionHeader: {
    fontSize: '14px',
    fontWeight: 400,
    padding: '8px 4px'
  },
  swirlyTableCell: {
    padding: '0 4px'
  },
  scrollOverflow: {
    overflow: 'auto',
    '& .MuiTable-root': {
      borderCollapse: 'initial'
    },
    padding: theme.spacing(1)
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default styles;
