const styles = theme => ({
  container: {
    marginTop: 25
  },
  boldSubtitle: {
    fontWeight: 500
  },
  withBreaks: {
    whiteSpace: 'pre-line'
  },
  alignTop: {
    alignItems: 'end'
  },
  noDecorationLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none'
    }
  }
});

export default styles;
