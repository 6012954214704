import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../helpers/history';
import { setQuestionnaireSubmitted } from '../../../features/questionnaire/questionnaire-slice';
import {
  saveResponsesToServer,
  setQuestionnaireCompletionStatus
} from '../../../features/questionnaire/questionnaire-thunks';
import Helix from '../../Helix';
import TitleCard from '../../Cards/TitleCard';

class Unsubmit extends React.Component {
  async componentDidMount() {
    const {
      setQuestionnaireSubmitted,
      setQuestionnaireCompletionStatus,
      saveResponsesToServer
    } = this.props;

    setQuestionnaireSubmitted(false);
    await setQuestionnaireCompletionStatus();
    await saveResponsesToServer();

    history.push('/start');
  }

  render() {
    return (
      <TitleCard
        cardTitle="Unsubmitting questionnaire"
        cardSubtitle="Unsubmitting your responses"
        content={<Helix />}
      />
    );
  }
}

const mapDispatch = {
  setQuestionnaireSubmitted,
  setQuestionnaireCompletionStatus,
  saveResponsesToServer
};

const connectedUnsubmit = connect(null, mapDispatch)(Unsubmit);
export { connectedUnsubmit as Unsubmit };
