import { getFieldName } from './fields';
import { store } from '../../../stores/store';

export const getAliasedResponse = (alias, currentFieldInfo) => {
  const questionnairesState = store.getState().newQuestionnaire;
  const responses = questionnairesState.responses;
  const questionnaire = questionnairesState.questionnaire;

  const aliasedQuestion = questionnaire.questions.find(x => x.alias === alias);

  if (!aliasedQuestion) {
    console.log(`Alias ${alias} not found`);
    return null;
  }

  const aliasedFieldName = getFieldName(
    aliasedQuestion.id,
    currentFieldInfo.sectionId,
    currentFieldInfo.sectionInstanceId,
    currentFieldInfo.sectionIndex,
    currentFieldInfo.sectionDepth
  );
  const aliasedResponse = responses.get(aliasedFieldName, null);

  if (aliasedResponse == null) {
    console.log(`No response found for alias ${alias}`);
    return null;
  }

  return aliasedResponse.value;
};
